import React, { Fragment, useState } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { Link, useNavigate } from "react-router-dom";
import Logo from '../assets/chat_smith_logo.svg';
import {
  Bars3Icon,

  XMarkIcon,
  UserCircleIcon,
} from "@heroicons/react/24/outline";
import PlanModal from "./PlanModal";
import SaveAlertModal from './SaveAlertModal';

const userNavigation = [
  { name: "Account", function: "viewAccount" },
  { name: "Sign out", function: "signOut" },
];

function classNames<T extends string>(...classes: T[]): string {
  return classes.filter(Boolean).join(" ");
}

export default function Header() {

  const navigate = useNavigate();

  const linkRef = React.useRef<any>();
  const [open, setOpen] = useState(false);
  const [showAlertModal, setShowAlertModal] = useState(false);
  const user = {
    email: localStorage.getItem('email'),
  };

  const signOut = () => {
    localStorage.setItem("email", "null");
    localStorage.setItem("token", "null");
    navigate("/sign-in");
  }

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <div className="min-h-full">
        <Disclosure as="nav" className="bg-white shadow-sm">
          {({ open }) => (
            <>
              <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <div className="flex h-16 justify-between">
                  <div className="flex">
                    <div className="flex flex-shrink-0 items-center">
                      <Link ref={linkRef} to="/" onClick={(e) => {
                        if (localStorage.getItem('bot-editing') === 'true') {
                          e.preventDefault();
                          setShowAlertModal(true);
                        }
                      }}>
                        <img
                          className="block h-14 w-auto"
                          src={Logo}
                          alt="ChatSmith"
                        />
                      </Link>
                    </div>
                  </div>
                  <div className="hidden md:flex items-center justify-center">
                    <Link to="/" className="me-2 no-underline text-gray-500">My Chatbots</Link>
                    <Link to="/pricing" className="ms-2 no-underline text-gray-500">Pricing</Link>
                  </div>
                  <div className="hidden sm:ml-32 sm:flex sm:items-center">
                    {/* Profile dropdown */}
                    <Menu as="div" className="relative ml-3">
                      <div>
                        <Menu.Button className="flex rounded-full bg-white text-sm focus:outline-none">
                          <span className="sr-only">Open user menu</span>
                          <UserCircleIcon
                            className="block h-6 w-6"
                            aria-hidden="true"
                          />
                        </Menu.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                          <Menu.Item>
                            {({ active }) => (
                              <button
                                onClick={handleOpen}
                                className={classNames(
                                  active ? "bg-gray-100 cursor-pointer" : "",
                                  "block px-4 py-2 text-sm text-gray-700 cursor-pointer w-full text-left underline"
                                )}
                              >
                                My Plan
                              </button>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }) => (
                              <button
                                onClick={signOut}
                                className={classNames(
                                  active ? "bg-gray-100 cursor-pointer" : "",
                                  "block px-4 py-2 text-sm text-gray-700 cursor-pointer w-full text-left underline"
                                )}
                              >
                                Sign Out
                              </button>
                            )}
                          </Menu.Item>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div>
                  <div className="-mr-2 flex items-center sm:hidden">
                    {/* Mobile menu button */}
                    <Disclosure.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500">
                      <span className="sr-only">Open main menu</span>
                      {open ? (
                        <XMarkIcon
                          className="block h-6 w-6"
                          aria-hidden="true"
                        />
                      ) : (
                        <Bars3Icon
                          className="block h-6 w-6"
                          aria-hidden="true"
                        />
                      )}
                    </Disclosure.Button>
                  </div>
                </div>
              </div>

              <Disclosure.Panel className="sm:hidden">
                <div className="border-t border-gray-200 pb-3 pt-4">
                  <div className="flex items-center px-4">
                    <div className="flex-shrink-0">
                      <UserCircleIcon
                        className="block h-6 w-6"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="ml-3">
                      <div className="text-sm font-medium text-gray-500">
                        {user.email}
                      </div>
                    </div>
                  </div>
                  <div className="mt-3 space-y-1">
                    <Disclosure.Button
                      className="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800 w-full text-left"
                    >
                        <Link to="/" className="text-gray-500 hover:bg-gray-100 hover:text-gray-800 w-full">
                          My Chatbots
                        </Link>
                    </Disclosure.Button>
                    <Disclosure.Button
                      className="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800 w-full text-left"
                    >
                        <Link to="/pricing" className="text-gray-500 hover:bg-gray-100 hover:text-gray-800">
                          Pricing
                        </Link>
                    </Disclosure.Button>
                    <Disclosure.Button
                      as="a"
                      onClick={handleOpen}
                      className="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800"
                    >
                      My Plan
                    </Disclosure.Button>
                    <Disclosure.Button
                      as="a"
                      onClick={signOut}
                      className="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800"
                    >
                      Sign Out
                    </Disclosure.Button>
                  </div>
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
        {
          open && <PlanModal open={open} handleOpen={handleOpen} handleClose={handleClose} />
        }
        <SaveAlertModal openModal={showAlertModal} onCancel={() => setShowAlertModal(false)} onOk={() => {
          setShowAlertModal(false);
          localStorage.setItem('bot-editing', 'false');
          linkRef.current.click();
        }} />
      </div>
    </>
  );
}
