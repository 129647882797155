import React, { useContext, ReactNode, useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import axios from "axios";
import Loader from "../components/Loader";

interface PublicRouteProps {
    children: ReactNode;
}

const PublicRoute: React.FC<PublicRouteProps> = ({ children }) => {
    const [authStatus, setAuthStatus] = useState<boolean | null>(null);

    useEffect(() => {
        const path = process.env.REACT_APP_BASE_URL + "/api/auth/loginCheck";
        const req = {
            email: localStorage.getItem('email')
        };
        axios
            .post(path, req, {
                headers: {
                    Authorization: localStorage.getItem("token"),
                },
            })
            .then((res) => {
                setAuthStatus(res.data.authentication);

            })
            .catch((err) => {
                console.log(err);
                setAuthStatus(false);
            });
    }, []);

    if (authStatus === null) {
        // Authentication status is unknown, show a loading indicator
        return <div className="text-center"><Loader loading={true} /></div>;
    } else if (authStatus === true) {
        // User is authenticated, show the children components
        return <>{<Navigate to="/" />}</>;
    } else {
        // User is not authenticated, redirect to login page
        return <>{children}</>;
    }
};

export default PublicRoute