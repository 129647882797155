import { FC } from "react";
import { useState } from "react";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useGoogleLogin } from '@react-oauth/google'
import ReCAPTCHA from "react-google-recaptcha";
import { toast } from "react-toastify";
import Logo from '../assets/chat_smith_icon.svg';
import { FcGoogle } from 'react-icons/fc'

const Signin: FC = () => {
  const navigate = useNavigate();
  const [captcha, setCaptcha] = useState(false)
  const [errorCaptcha, setErrorCaptcha] = useState("");

  const [email, setEmail] = useState("");

  const [errorMessage, setErrorMessage] = useState("")
  const [emailStatus, setEmailStatus] = useState(null);
  const [loginClickState, setLoginClickState] = useState(false)

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setErrorCaptcha("")
    let flag = false;
    setErrorMessage("");
    if (email === "") {
      flag = true;
    }

    if (flag) return;

    if (!captcha) {
      setErrorCaptcha('invaid reCAPTCHA')
      return;
    }

    setLoginClickState(true)

    const path = process.env.REACT_APP_BASE_URL + "/api/sendVerifyEmail";
    const request = {
      email: email,
    };

    await axios
      .post(path, request)
      .then((res) => {
        if (res.data) {
          setEmailStatus(res.data.status);
          return;
        }
      })
      .catch((err) => {
        console.log(err.response);
        setEmailStatus(err.response.data.status);
        return;
      });
  };

  // const decodeJwtResponse = (token: String) => {
  //   var base64Url = token.split('.')[1];
  //   var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  //   var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
  //     return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  //   }).join(''));

  //   return JSON.parse(jsonPayload);
  // }

  const googleLogin = useGoogleLogin({
    onSuccess: async ({ access_token }) => {
      const userInfo = await axios.get(
        'https://www.googleapis.com/oauth2/v3/userinfo',
        { headers: { Authorization: `Bearer ${access_token}` } },
      );

      if (userInfo.data.email_verified) {
        const path =
          process.env.REACT_APP_BASE_URL +
          "/api/auth/googleLogin";
        const request = {
          email: userInfo.data.email,
          credential: access_token
        };

        await axios
          .post(path, request)
          .then((res) => {
            if (res.data) {
              localStorage.setItem("token", res.data.token);
              localStorage.setItem("email", res.data.email);
              navigate("/");
            }
          })
          .catch((err) => {
            console.log("err = ", err);
            // setErrorMessage(err.response.data.message);
          });
      }

    },
    onError: errRes => {
      toast.error(errRes.toString());
    }
  })

  return (
    <>
      <div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img
            className="mx-auto w-auto"
            src={Logo}
            style={{ height: '6rem' }}
            alt="Your Company"
          />
          <h2 className="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Welcome Back
          </h2>
        </div>

        <div className="mt-5 sm:mx-auto sm:w-full sm:max-w-[480px]">
          <div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
            <h1 className="text-[12px] text-red-900">{errorMessage}</h1>
            <form className="space-y-6" onSubmit={handleSubmit}>
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Email address
                </label>
                <div className="mt-2">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 mb-[20px]"
                  />
                  <ReCAPTCHA
                    sitekey="6Lf5HzQnAAAAAL7JD-FQxsyXdMcRlBe4ji2teGii"
                    size="normal"
                    onChange={() => setCaptcha(true)}
                  />
                  <h1 className="text-red-700 text-[12px]">{errorCaptcha}</h1>
                </div>
              </div>
              {emailStatus === true && (
                <h1 className="text-green-700 text-[14px]">
                  An email has been sent to your email. Please check your inbox
                  and click on the link to complete the sign-in process (be sure
                  to check junk/spam inbox).
                </h1>
              )}
              {emailStatus === false && (
                <h1 className="text-red-700 text-[14px]">Email sent failed!</h1>
              )}
              <div>
                <button
                  type="submit"
                  className="flex w-full justify-center rounded-md bg-[#FFE294] border-[1px] border-[#FED35E] hover:bg-[#fed35e] px-3 py-1.5 text-sm font-semibold leading-6 text-[#251a15] shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  disabled={loginClickState}
                >
                  Sign in
                </button>
              </div>
            </form>

            <div>
              <div className="relative mt-10">
                <div
                  className="absolute inset-0 flex items-center"
                  aria-hidden="true"
                >
                  <div className="w-full border-t border-gray-200" />
                </div>
                <div className="relative flex justify-center text-sm font-medium leading-6">
                  <span className="bg-white px-6 text-gray-900">
                    Or continue with
                  </span>
                </div>
              </div>

              <div className="w-full mt-[20px]">
                <div className="flex justify-center">
                  <button className="flex google-signin-btn hover:bg-[#302f20] bg-[#251a15] focus-visible:outline px-[20px] py-[10px] rounded-[8px] text-white" onClick={() => { googleLogin() }} >
                    {/* <LazyLoadImage src={GoogleSvg} alt="google" style={{ marginRight: 15 }} /> */}
                    <FcGoogle style={{ marginRight: 15, marginTop: 5 }} />
                    Sign in with Google
                  </button>
                </div>
                {/* <div className="flex justify-center">
                  <MicrosoftLogin clientId="8ee2af7c-582d-4d9e-a955-79355a76c9b0" buttonTheme="dark" redirectUri="http://localhost:3001"  authCallback={authHandler} className="w-[220px] mt-[10px] border-none cursor-pointer" >
                    Sign in with Microsoft
                  </MicrosoftLogin>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Signin