import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Modal from '@mui/joy/Modal/Modal';
import ModalDialog from "@mui/joy/ModalDialog/ModalDialog";
import { ExclamationTriangleIcon } from "@heroicons/react/20/solid";
import { Link } from 'react-router-dom';
import { GridLoader } from "react-spinners";
import validator from 'validator';

import Loader from '../../components/Loader';

import Back from '../../assets/back.svg';
import Next from '../../assets/next.svg';

interface typeEditUrl {
    urls: string;
    totalCount: number;
    botId: number;
    planType: string;
};

const EditUrl = ({ urls, totalCount, botId, planType }: typeEditUrl) => {
    const [allUrls, setAllUrls] = useState<Array<string>>(urls.length > 2 ? urls.split(',') : []);
    const [chatUrls, setChatUrls] = useState<Array<string>>(urls.length > 2 ? urls.split(',') : []);
    const [submitable, setSubmitable] = useState<boolean>(false);
    const [showAddModal, setShowAddModal] = useState<boolean>(false);
    const [valid, setValid] = useState<boolean>(true);
    const [searchText, setSearchText] = useState<string>('');
    const [showWarning, setShowWarning] = useState(false);
    const [fetching, setFetching] = useState<string>('before');
    const [websiteUrl, setWebsiteUrl] = useState<string>('');
    const [fetchedUrls, setFetchedUrls] = useState<Array<string>>([]);
    const [selectableUrls, setSelectableUrls] = useState<Array<string>>([]);
    const [selectedUrls, setSelectedUrls] = useState<Array<string>>([]);
    const [manualUrl, setManualUrl] = useState('');
    const [loading, setLoading] = useState(false);
    const [buttonState, setButtonState] = useState(false);

    const [currentSource, setCurrentSource] = useState('crawl');

    useEffect(() => {
        const originUrls = urls.length > 2 ? urls.split(',') : [];
        setSubmitable(!allUrls.every(url => originUrls.includes(url)) || originUrls.length !== allUrls.length);
        setChatUrls(allUrls.filter(url => url.includes(searchText)));
    }, [allUrls]);

    useEffect(() => {
        setChatUrls(allUrls.filter(url => url.includes(searchText)));
    }, [searchText]);

    useEffect(() => {
        if (currentSource === 'manual') {
            const urlM = manualUrl.split(',');
            setButtonState(urlM[0].length >= 2 && urlM.length >= 1);
        }
    }, [currentSource, manualUrl]);

    const closeAddModal = () => {
        setShowAddModal(false);
        setFetching('before');
    };

    const handleFetch = () => {
        var urlPattern = new RegExp('^(https?:\\/\\/)?' + // validate protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // validate domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // validate OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // validate port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // validate query string
            '(\\#[-a-z\\d_]*)?$', 'i'); // validate fragment locator

        const isValid = urlPattern.test(websiteUrl);
        setValid(isValid);
        if (!isValid) return;

        setFetching('doing');
        const path = `${process.env.REACT_APP_BASE_URL}/api/fetchPage`;
        axios.post(path, { url: websiteUrl }).then(res => {
            setFetchedUrls(res.data.urls);
            setSelectableUrls(res.data.urls);
            setSelectedUrls([]);
        }).catch(err => {
            console.log('fetching error:', err);
        }).finally(() => setFetching('done'));
    };

    const handleChangeFilter = (e: any) => {
        setSelectableUrls(fetchedUrls.filter((url: any) => url.includes(e.target.value)));
    };

    const handleChangeUrlSelection = (itemUrl: string, selected: boolean) => {
        if (selected) {
            setSelectedUrls([
                ...selectedUrls,
                itemUrl,
            ]);
        } else {
            setSelectedUrls(selectedUrls.filter(url => url !== itemUrl));
        }
    };

    const handleAddUrls = () => {
        setShowAddModal(false);
        setAllUrls(Array.from(new Set([...allUrls, ...selectedUrls])));
    };

    const handleChangeSearchText = (e: any) => {
        setSearchText(e.target.value);
    };

    const handleDeleteUrl = (deleteUrl: string) => {
        setAllUrls(allUrls.filter(url => url !== deleteUrl));
    };

    const handleSubmit = () => {
        if (Number(totalCount) < allUrls.length) {
            setShowWarning(true);
            return;
        } else {
            setShowWarning(false);
        }

        // Submit code
        const formData = new FormData();

        // Append each file to the formData object
        const user_email = localStorage.getItem('email');

        formData.append("email", String(user_email));
        formData.append("bot_id", String(botId));
        formData.append("urls_input", allUrls.join(','));

        setLoading(true);
        const path = process.env.REACT_APP_BASE_URL + "/api/updateChat";
        axios.post(path, formData, {
            headers: {
                Authorization: localStorage.getItem("token"),
                "Content-Type": "multipart/form-data",
            },
        }).then(() => {
            window.location.reload()
            localStorage.setItem('bot-editing', 'false');
        }).catch((err) => {
            console.log(err)
        }).finally(() => setLoading(false));
    };

    return (
        <>
            <div className="col-span-full p-2">
                <Loader loading={loading}>
                    <div className='flex justify-between items-center'>
                        <div>
                            <h4 className={`text-[Inter] font-bold text-[16px] ${allUrls.length > totalCount ? 'text-[red]' : 'text-black'}`}>{`${allUrls.length} / ${totalCount}`}
                                <span className='ms-1 text-black'>URL's</span>
                            </h4>
                        </div>
                        <div>
                            <button className='font-bold text-xs border-[#D1D5DB] border-[1px] rounded-[4px] px-4 py-2 me-2 mb-2' onClick={() => setShowAddModal(true)}>+ Add</button>
                            <button
                                className={`font-bold text-xs px-4 py-2 rounded-[4px] ${!submitable ? 'bg-[#D9D9D9]' : 'bg-[#FFE294] border-[1px] border-[#FED35E] hover:bg-[#fed35e]'}`}
                                onClick={handleSubmit}
                            >
                                Publish
                            </button>
                        </div>
                    </div>
                    <p className='m-0 text-[Inter] text-[12px] font-normal text-[#8F98A1]'>
                            Your chat bot is trained on these links.
                            {planType !== 'pro' && <span className='font-bold sm:ms-1 block sm:inline-flex'>Need more? <Link className='text-[#18A2AB]' to="/pricing">Upgrade Now</Link></span>}
                        </p>
                    <div className='mt-3 flex justify-between items-center pb-3'>
                        <p className='text-[Inter] font-medium text-xs m-0'>URL's</p>
                        <div className='rounded-[4px] border-[1px] border-[#D1D5DB] flex items-center p-2'>
                            <svg className='mx-2' width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M19.0008 19L13.8038 13.803M13.8038 13.803C15.2104 12.3965 16.0006 10.4887 16.0006 8.49955C16.0006 6.51035 15.2104 4.60262 13.8038 3.19605C12.3972 1.78947 10.4895 0.999268 8.50028 0.999268C6.51108 0.999268 4.60336 1.78947 3.19678 3.19605C1.79021 4.60262 1 6.51035 1 8.49955C1 10.4887 1.79021 12.3965 3.19678 13.803C4.60336 15.2096 6.51108 15.9998 8.50028 15.9998C10.4895 15.9998 12.3972 15.2096 13.8038 13.803Z" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>

                            <input
                                className='flex-1 ms-2 focus-visible:outline-0'
                                placeholder='Search Pages'
                                value={searchText}
                                onChange={handleChangeSearchText}
                            />
                        </div>
                    </div>
                    <div>
                        {
                            chatUrls.map((url, index) => (
                                <div key={index} className='border-t border-[#D1D5DB] py-3 flex justify-between items-center'>
                                    <p className='m-0 font-[Inter] font-medium text-xs truncate'>{url}</p>
                                    <button
                                        className='w-[38px] h-[38px] hover:bg-[#C81212]/[.1] bg-white hover:stroke-[#C81212] hover:border-[#C81212]/[.5] border-[#E7E7E7] stroke-[#251A15] border-[1px] rounded-[4px] flex items-center justify-center'
                                        onClick={() => handleDeleteUrl(url)}
                                    >
                                        <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9.05933 5.88426L8.8056 12.3961M5.2944 12.3961L5.04067 5.88426M12.3505 3.5617C12.6013 3.59933 12.8507 3.63912 13.1 3.68181M12.3505 3.5617L11.5673 13.6066C11.5354 14.0155 11.3481 14.3975 11.043 14.6762C10.7379 14.9548 10.3375 15.1095 9.92173 15.1094H4.17827C3.76254 15.1095 3.36208 14.9548 3.05698 14.6762C2.75189 14.3975 2.56463 14.0155 2.53267 13.6066L1.74947 3.5617M12.3505 3.5617C11.5042 3.43546 10.6534 3.33965 9.8 3.27446M1.74947 3.5617C1.49867 3.5986 1.24933 3.6384 1 3.68109M1.74947 3.5617C2.59583 3.43546 3.44657 3.33965 4.3 3.27446M9.8 3.27446V2.6117C9.8 1.75792 9.13267 1.04596 8.26733 1.01919C7.45598 0.993604 6.64402 0.993604 5.83267 1.01919C4.96733 1.04596 4.3 1.75865 4.3 2.6117V3.27446M9.8 3.27446C7.9694 3.13487 6.1306 3.13487 4.3 3.27446" stroke="inherit" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                    </button>
                                </div>
                            ))
                        }
                    </div>
                </Loader>
            </div>
            {
                showWarning &&
                <div className="fixed bottom-[100px] left-[0] z-30 w-full flex items-center justify-center">
                    <div className="border-l-4 border-yellow-400 bg-yellow-50 p-4 w-auto">
                        <div className="flex">
                            <div className="flex-shrink-0">
                                <ExclamationTriangleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                            </div>
                            <div className="ml-3">
                                <p className="text-sm text-yellow-700 mb-[0]">
                                    You have exceeded the number of links.{' '}
                                    <Link to="/pricing" className="font-medium text-yellow-700 underline hover:text-yellow-600">
                                        Upgrade plan
                                    </Link>
                                </p>
                            </div>
                            <div className="ms-[50px] cursor-pointer" onClick={() => setShowWarning(false)}>
                                <svg width="20" height="20" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M3.5 10.5L10.5 3.5M3.5 3.5L10.5 10.5" stroke="#FED35E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
            }
            <Modal open={showAddModal}>
                <ModalDialog
                    sx={{
                        padding: '0',
                    }}
                >
                    <div className='px-[1rem] pt-[2rem] sm:p-8 pb-[35px]'>
                        <button className='absolute right-[15px] top-[15px]' onClick={closeAddModal}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6 18L18 6M6 6L18 18" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </button>
                        {(fetching === 'before' || fetching === 'doing') && 
                            <>
                                <h3 className='font-[Inter] font-bold text-xl'>Add URL's</h3>
                                <p className='add-url font-[Inter] font-normal text-[#8F98A1] text-sm mb-4'>Choose additional web pages to train your chatbot</p>
                                <div className='mt-3 mb-4'>
                                    <button
                                        className={`text-sm sm:text-md mb-[0.5rem] sm:mb-[0px] me-3 inline-flex items-center px-4 py-2 font-bold border-[1px] rounded ${currentSource === 'crawl' ? 'text-[#18A2AB] border-[#18A2AB]' : 'text-[#251A15] border-[#D1D5DB]'}`}
                                        style={{
                                        background: `${currentSource === 'website' ? 'rgba(24, 162, 171, 0.08)' : 'transparent'}`
                                        }}
                                        onClick={() => setCurrentSource('crawl')}
                                    >
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className="me-2">
                                        <path d="M7.5 14.375V15.2142C7.50004 15.5426 7.43537 15.8678 7.30968 16.1712C7.184 16.4746 6.99976 16.7503 6.7675 16.9825L6.25 17.5H13.75L13.2325 16.9825C13.0002 16.7503 12.816 16.4746 12.6903 16.1712C12.5646 15.8678 12.5 15.5426 12.5 15.2142V14.375M17.5 4.375V12.5C17.5 12.9973 17.3025 13.4742 16.9508 13.8258C16.5992 14.1775 16.1223 14.375 15.625 14.375H4.375C3.87772 14.375 3.40081 14.1775 3.04917 13.8258C2.69754 13.4742 2.5 12.9973 2.5 12.5V4.375M17.5 4.375C17.5 3.87772 17.3025 3.40081 16.9508 3.04917C16.5992 2.69754 16.1223 2.5 15.625 2.5H4.375C3.87772 2.5 3.40081 2.69754 3.04917 3.04917C2.69754 3.40081 2.5 3.87772 2.5 4.375M17.5 4.375V10C17.5 10.4973 17.3025 10.9742 16.9508 11.3258C16.5992 11.6775 16.1223 11.875 15.625 11.875H4.375C3.87772 11.875 3.40081 11.6775 3.04917 11.3258C2.69754 10.9742 2.5 10.4973 2.5 10V4.375" stroke={`${currentSource === 'crawl' ? '#18A2AB' : 'black'}`} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>

                                        Crawl Website
                                    </button>
                                    <button
                                        className={`text-sm sm:text-md mb-[0.5rem] sm:mb-[0px] me-3 inline-flex items-center px-4 py-2 font-bold border-[1px] rounded ${currentSource === 'manual' ? 'text-[#18A2AB] border-[#18A2AB]' : 'text-[#251A15] border-[#D1D5DB]'}`}
                                        style={{
                                        background: `${currentSource === 'website-m' ? 'rgba(24, 162, 171, 0.08)' : 'transparent'}`
                                        }}
                                        onClick={() => setCurrentSource('manual')}
                                    >
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className="me-2">
                                        <path d="M7.5 14.375V15.2142C7.50004 15.5426 7.43537 15.8678 7.30968 16.1712C7.184 16.4746 6.99976 16.7503 6.7675 16.9825L6.25 17.5H13.75L13.2325 16.9825C13.0002 16.7503 12.816 16.4746 12.6903 16.1712C12.5646 15.8678 12.5 15.5426 12.5 15.2142V14.375M17.5 4.375V12.5C17.5 12.9973 17.3025 13.4742 16.9508 13.8258C16.5992 14.1775 16.1223 14.375 15.625 14.375H4.375C3.87772 14.375 3.40081 14.1775 3.04917 13.8258C2.69754 13.4742 2.5 12.9973 2.5 12.5V4.375M17.5 4.375C17.5 3.87772 17.3025 3.40081 16.9508 3.04917C16.5992 2.69754 16.1223 2.5 15.625 2.5H4.375C3.87772 2.5 3.40081 2.69754 3.04917 3.04917C2.69754 3.40081 2.5 3.87772 2.5 4.375M17.5 4.375V10C17.5 10.4973 17.3025 10.9742 16.9508 11.3258C16.5992 11.6775 16.1223 11.875 15.625 11.875H4.375C3.87772 11.875 3.40081 11.6775 3.04917 11.3258C2.69754 10.9742 2.5 10.4973 2.5 10V4.375" stroke={`${currentSource === 'manual' ? '#18A2AB' : 'black'}`} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>

                                        Manually Enter URL's
                                    </button>
                                </div>
                                {currentSource === 'crawl' &&
                                <>
                                    <p className='mt-3 font-semibold font-normal text-[#251A15] text-[14px]'>
                                        Website URL
                                        {!valid && <span className="text-[red] text-[12px] font-light ms-2">Please Input Website URL</span>}
                                    </p>
                                    <div className='flex items-center justify-center'>
                                        <input
                                            placeholder='https://mywebsite.com'
                                            value={websiteUrl}
                                            onChange={e => setWebsiteUrl(e.target.value)}
                                            className={`flex-1 border-[1px] px-3 py-2 rounded-[4px] min-w-[calc(40vw)] focus-visible:outline-0 text-xs border-[1px] ${valid ? 'border-[#18A2AB]' : 'border-[red]'}`}
                                        />
                                        <button
                                            className='flex ms-3 items-center font-bold font-[Inter] text-xs text-[#161616] px-4 sm:px-4 py-2 bg-[#FFE294] border-[1px] border-[#FED35E] hover:bg-[#fed35e] rounded-[4px] sm:w-[135px] justify-center'
                                            onClick={handleFetch}
                                        >
                                            {
                                                fetching === 'doing' &&
                                                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" className='me-2'>
                                                    <path d="M10.0146 5.8424H13.1346L11.1465 3.85303C10.5064 3.21295 9.70911 2.75265 8.83474 2.5184C7.96037 2.28416 7.03974 2.28423 6.1654 2.5186C5.29107 2.75298 4.49384 3.21339 3.85386 3.85357C3.21388 4.49374 2.75371 5.29112 2.51961 6.16553M1.86586 12.2774V9.1574M1.86586 9.1574H4.98586M1.86586 9.1574L3.85336 11.1468C4.49343 11.7869 5.29073 12.2472 6.1651 12.4814C7.03947 12.7156 7.9601 12.7156 8.83444 12.4812C9.70878 12.2468 10.506 11.7864 11.146 11.1462C11.786 10.5061 12.2461 9.70869 12.4802 8.83428M13.1346 2.7224V5.84115" stroke="#161616" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>
                                            }

                                            {fetching === 'doing' ? 'Fetching...' : 'Fetch'}
                                        </button>
                                    </div>
                                    <p className='font-[Inter] font-normal text-xs text-[#8F98A1] mt-3'>Website to train your chatbot - Use root URL's only</p>

                                    {
                                    fetching === 'doing' &&
                                        <div
                                            className="my-4 py-5 flex-row items-center justify-center text-[#18A2AB] font-bold border-dashed border-[1px] border-[#18A2AB] rounded"
                                            style={{ background: 'rgba(24, 162, 171, 0.08)' }}
                                        >
                                            <div className="text-center mb-3">
                                                <GridLoader color='#18A2AB' size={10}/>
                                            </div>
                                            <p className="text-center">Fetching Pages</p>
                                        </div>
                                    }
                                </>
                                }
                                {currentSource === 'manual' &&
                                <>
                                     <p className='mt-3 font-semibold font-normal text-[#251A15] text-[14px]'>
                                        Enter URL's
                                        {!valid && <span className="block sm:inline text-[red] text-[12px] font-light mt-2 sm:mt-0 sm:ms-2">Enter website URLs, separated by commas. Ensure each starts with "https://" (e.g., https://mysite.com,https://www.mysite.com). Avoid spaces between commas.</span>}
                                    </p>
                                    <textarea
                                        placeholder='https://www.example.com,https://www.exmaple2.com'
                                        rows={5}
                                        value={manualUrl}
                                        onChange={e => setManualUrl(e.target.value)}
                                        className={`flex-1 border-[1px] px-3 py-2 rounded-[4px] min-w-[calc(40vw)] focus-visible:outline-0 text-xs border-[1px] w-full ${valid ? 'border-[#18A2AB]' : 'border-[red]'}`}
                                    />
                                    <p className='font-[Inter] font-normal text-xs text-[#8F98A1] mt-3'>Website URL's to train your chatbot - Enter comma separated</p>

                                    <div>
                                    <footer className="py-4 bg-white drop-shadow-sm text-center flex items-center justify-center fixed bottom-[0] left-[0] w-full" >
                                        <button
                                            className="px-4 py-2 text-[#161616] inline-flex items-center bg-[#F5F5F5] me-1 rounded-[4px] font-bold text-[12px] hover:bg-gray-400"
                                            onClick={closeAddModal}
                                        >
                                            <img className="me-2" src={Back} alt="back" />
                                            Cancel
                                        </button>
                                        <button
                                            onClick={() => {
                                                const urlM = manualUrl.split(',');

                                                for ( let i = 0; i < urlM.length; i ++) {
                                                    if (urlM[i] && !validator.isURL(urlM[i])) {
                                                        setValid(false);
                                                        return;
                                                    }
                                                }
                                                setValid(true);
                                                setAllUrls(Array.from(new Set([...allUrls, ...urlM])));
                                                setManualUrl('');
                                                closeAddModal();
                                            }}
                                            disabled={!buttonState}
                                            className="inline-flex ms-1 items-center rounded-[4px] font-bold bg-[#FFE294] border-[1px] border-[#FED35E] hover:bg-[#fed35e] px-4 py-2 text-[12px] disabled:bg-[#D1D5DB] disabled:border-[0] disabled:cursor-no-drop"
                                        >
                                            Next
                                            <img className="ms-2" src={Next} alt="next" />
                                        </button>
                                    </footer>
                                    </div>
                                </>
                                }
                            </>
                        }
                        {
                            fetching === 'done' &&
                            <>
                                <div className='xs:flex-col sm:flex justify-between min-w-[calc(50vw)] items-center'>
                                    <div>
                                        <h3 className='font-[Inter] font-bold text-xl'>{`${fetchedUrls.length} Pages found`}</h3>
                                        <p className='add-url font-[Inter] font-normal text-xs text-[#8F98A1]'>Choose the page you want to use to train your chatbot</p>
                                    </div>
                                    <div className='rounded-[4px] border-[1px] border-[#D1D5DB] flex items-center p-1'>
                                        <svg className='mx-2' width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M19.0008 19L13.8038 13.803M13.8038 13.803C15.2104 12.3965 16.0006 10.4887 16.0006 8.49955C16.0006 6.51035 15.2104 4.60262 13.8038 3.19605C12.3972 1.78947 10.4895 0.999268 8.50028 0.999268C6.51108 0.999268 4.60336 1.78947 3.19678 3.19605C1.79021 4.60262 1 6.51035 1 8.49955C1 10.4887 1.79021 12.3965 3.19678 13.803C4.60336 15.2096 6.51108 15.9998 8.50028 15.9998C10.4895 15.9998 12.3972 15.2096 13.8038 13.803Z" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>

                                        <input
                                            className='flex-1 ms-2 focus-visible:outline-0'
                                            onChange={handleChangeFilter}
                                            placeholder='Search Pages'
                                        />
                                    </div>

                                </div>
                                <div className="py-3 px-1">
                                    <input
                                        type="checkbox"
                                        checked={selectableUrls.every(sel => selectedUrls.indexOf(sel) >= 0)}
                                        onChange={(e) => {
                                            if (e.target.checked) {
                                                setSelectedUrls(Array.from(new Set([...selectedUrls, ...selectableUrls])))
                                            } else {
                                                setSelectedUrls(selectedUrls.filter(item => !selectableUrls.includes(item)));
                                            }
                                        }}
                                        className="w-4 h-4 text-[#18A2AB] bg-gray-100 border-gray-300 rounded-[2px] focus:ring-[#18A2AB] dark:focus:ring-[#18A2AB] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <span className="ms-2 text-[#161616] text-[Inter] text-[12px] font-bold">Pages</span>
                                </div>
                                <div className="max-h-[calc(30vh)] overflow-y-auto px-1">
                                    {
                                        selectableUrls.map((item, index) => (
                                            <div key={index} className="py-3 border-[#D1D5DB]/[.5] border-t-[1px]">
                                                <input
                                                    type="checkbox"
                                                    checked={selectedUrls.indexOf(item) !== -1}
                                                    onChange={(e) => handleChangeUrlSelection(item, e.target.checked)}
                                                    className="w-4 h-4 text-[#18A2AB] bg-gray-100 border-gray-300 rounded-[2px] focus:ring-[#18A2AB] dark:focus:ring-[#18A2AB] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                                />
                                                <span className="ms-2 text-[#161616] text-[Inter] text-xs font-bold">{item}</span>
                                            </div>
                                        ))
                                    }
                                </div>
                                <div className='flex justify-center items-center py-3 bg-white absolute bottom-0 left-0 w-full shadow-[0_-4px_8px_-4px_rgba(0,0,0,.7)] rounded-b-[4px]'>
                                    <button
                                        className='px-3 py-2 flex items-center text-xs font-bold rounded-[4px] bg-[#F5F5F5] me-2 justify-center'
                                        onClick={() => setFetching('before')}
                                    >
                                        <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" className='me-1'>
                                            <path d="M3.75 11.25L11.25 3.75M3.75 3.75L11.25 11.25" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                        Cancel
                                    </button>
                                    <button
                                        className='px-4 py-2 flex items-center text-xs font-bold rounded-[4px] enabled:bg-[#FFE294] ms-2 border-[1px] enabled:border-[#FED35E] disabled:bg-[#D9D9D9] enabled:hover:bg-[#fed35e]'
                                        disabled={selectedUrls.length <= 0}
                                        onClick={handleAddUrls}
                                    >
                                        Add
                                    </button>
                                </div>
                            </>
                        }
                    </div>
                </ModalDialog>
            </Modal>
        </>
    );
};

export default EditUrl;