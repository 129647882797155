import { FC, useEffect } from 'react'
import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import {Link} from 'react-router-dom';
import axios from 'axios';
import Loader from './Loader';
import Stripe from 'stripe';

const secretKey = process.env.REACT_APP_STRIPE_SECRET_KEY;
const stripeCheckoutSession = new Stripe(secretKey || '', {
  apiVersion: '2022-11-15',
});

interface PlanModalProps {
  open: boolean;
  handleOpen: React.MouseEventHandler;
  handleClose: () => void;
}

const planNames = [{
  type: 'free',
  label: 'Free',
  money: '0',
}, {
  type: 'hobby',
  label: 'Hobby',
  money: '19',
}, {
  type: 'standard',
  label: 'Standard',
  money: '49',
}, {
  type: 'pro',
  label: 'Pro',
  money: '99',
}];

const PlanModal: FC<PlanModalProps> = ({ open, handleOpen, handleClose }) => {
  const [loading, setLoading] = useState(false);
  const [currentPlan, setCurrentPlan] = useState('free');
  const [currentPeriod, setCurrentPeriod] = useState('monthly');

  useEffect(() => {
    const path = process.env.REACT_APP_BASE_URL + "/api/getSubscription";
    const user_email = localStorage.getItem('email');
    const request = {
      email: user_email
    };

    setLoading(true);
    axios.post(path, request, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    }).then(async (res) => {
      setCurrentPlan(res.data.type);
      setCurrentPeriod(res.data.period);
    }).catch(err => {
      console.log('err:', err);
    }).finally(() => setLoading(false));
  }, []);

  const onUpgradeNow = async () => {
    const email = localStorage.getItem('email');
    let customer;
    // check if customer already exists
    const existingCustomers = await stripeCheckoutSession.customers.list({
      email: email || ''
    });

    if (existingCustomers.data.length > 0) {
      // customer already exists
      customer = existingCustomers.data[0];
    } else {
      // create a new customer
      customer = await stripeCheckoutSession.customers.create({
        email: email || '',
        // add any other customer details here as necessary
      });
    }

    console.log('mag:', customer);
    const session = await stripeCheckoutSession.billingPortal.sessions.create({
      customer: customer.id,
      return_url: 'https://app.chatsmith.ai/#/',
    });

    // const stripePromise = loadStripe(publicKey || "");
    // const stripe = await stripePromise;
    // await stripe?.redirectToCheckout({
    //   sessionId: session.id,
    // });
    window.location.href = session.url;
  }

  const onCancleNow = async () => {

    const path = process.env.REACT_APP_BASE_URL + "/api/getSubscription";
    const user_email = localStorage.getItem('email')
    const request = {
      email: user_email
    }
    axios.post(path, request, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    }).then(async (res) => {
      const subscriptionId = res.data.subscriptionId;
      if (subscriptionId) {
        const deleted = await stripeCheckoutSession.subscriptions.cancel(String(subscriptionId));
        const path = process.env.REACT_APP_BASE_URL + "/api/cancelSubscription";
        const user_email = localStorage.getItem('email')
        const request = {
          email: user_email,
          customer_id: deleted.customer,
          subscription_id: deleted.id
        }
        axios.post(path, request, {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }).then((res) => {
          window.location.reload()
        }).catch((err) => {
          window.location.reload()
        })
      }
    }).catch(err => {
      console.log(err)
    })
  }

  return (
    <>
      <Modal show={open} onHide={handleClose} >
        {/* <PaymentElement /> */}
        <div className='bg-white px-[20px] py-[20px] rounded'>
          <Loader loading={loading}>
            <Modal.Header color='#FFFFFF' className='flex items-center !justify-center relative !border-none'>
              <Modal.Title>
                <h1 className='font-[Inter] font-bold text-sm text-[#000000]/[.46] text-center'>Your Current plan</h1>
              </Modal.Title>
              <button className='absolute right-0 top-0' onClick={handleClose}>
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="18" height="18" rx="4" fill="#F7F7F8"/>
                  <path d="M4.5 13.5L13.5 4.5M4.5 4.5L13.5 13.5" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
              </button>
            </Modal.Header>
            <Modal.Body>
              <h1 className='text-center text-[24px] font-[Inter] font-bold text-black'>
                {planNames.find(plan => plan.type === currentPlan)?.label}
                <span className='text-[#18A2AB] ms-3'>{`USD $${
                  currentPlan === 'free' ? '0' :
                  currentPeriod === 'monthly' ? 
                    planNames.find(plan => plan.type === currentPlan)?.money : 
                    `${planNames.find(plan => plan.type === currentPlan)?.money}0`
                  } /${currentPlan === 'free' ? 'mo' : currentPeriod === 'monthly' ? 'mo' : 'yr'}`}</span>
              </h1>
              <div className='py-[10px] text-center'>
                <Link className='font-[Inter] font-normal text-sm text-[#000000]/[.46]' to="/pricing" onClick={() => handleClose()}>View Plan Details</Link>
              </div>
              <div className='mt-[10px]'>
                <p className="font-[Inter] font-normal text-center text-black/[.46] text-xs">
                  We offer a variety of plans to fit your needs. Whether you're just getting started or are a large business, we have the plan for you.
                </p>
              </div>
              <div className='flex flex-col justify-center items-center'>
                <button className='bg-[#FFE294] rounded border-[1px] border-[#FED35E] px-4 py-2'>
                  <Link to="/pricing" className="text-[#161616] font-[Inter] font-bold text-sm no-underline" onClick={() => handleClose()}>Upgrade Now</Link>
                </button>
                <button onClick={onUpgradeNow} className='cursor-pointer text-[20px] mt-3 border-b-[1px] border-[#000000]/[.46] text-[#000000]/[.46] text-sm'>Manage my subscription</button>
                {/* <div className='mt-[20px]'>
                  <a onClick={onCancleNow} className='cursor-pointer text-white text-[20px]'>Cancle my subscription</a>
                </div>
                <div className='mt-[20px]'>
                  <a href="#" className='text-white text-[20px] mt-[30px]'>I need hlep with a billing issue</a>
                </div> */}
              </div>
            </Modal.Body>
            <Modal.Footer className='!border-none !justify-center'>
              <p className='text-black text-center font-bold text-sm mb-0'>
              {`Your are logged in as ${localStorage.getItem('email')}`}
              </p>
            </Modal.Footer>
          </Loader>
        </div>
      </Modal>
    </>
  );
}

export default PlanModal;