import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route, HashRouter } from "react-router-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";
// import { AzureAD, MsalAuthProvider } from 'react-aad-msal';
import { MsalProvider } from "@azure/msal-react";
import { Configuration, PublicClientApplication } from "@azure/msal-browser";
import reportWebVitals from "./reportWebVitals";
import "./index.css";
import AppRouter from './router/AppRouter'
import { AuthContextProvider, useAuthContext } from './store/AuthContextProvider'
import '@fontsource/inter';


// MSAL configuration
const configuration: Configuration = {
  auth: {
    clientId: "7a93b9af-a3a8-4806-a5c1-94f378fe192d"
  }
};

const pca = new PublicClientApplication(configuration);


const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
const AppWithHeader = () => {

  const [{ }, { setAuthenticated }] = useAuthContext();

  return (
    <>
      <AppRouter />
    </>
  );
};

root.render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID || ''}>
      <AuthContextProvider>
        <HashRouter>
          <MsalProvider instance={pca}>
            <AppWithHeader />
          </MsalProvider>
        </HashRouter>
      </AuthContextProvider>
    </GoogleOAuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
