import { FC } from 'react';
import { useLocation } from "react-router-dom";
import PrivateRoutes from './PrivateRoutes';
import PublicRoutes from './PublicRoutes'
import Header from '../components/Header'


const AppRouter: FC = () => {
    const location = useLocation();
    const isAuthPage =
        location.pathname === "/sign-in" || location.pathname === "/register" || location.pathname === "/embed";

    return (
        <>
            {!isAuthPage && <Header />}
            <div style={{ minHeight: 'calc(100vh - 64px)' }}>
                <div className='bg-[#F5F8FA]'>
                    <PrivateRoutes />
                </div>
                <PublicRoutes />
            </div>
        </>

    );
};

export default AppRouter;