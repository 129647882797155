import { FC, useState, useRef, useEffect } from 'react';

import './ChatBubble.css';

const ChatBubble: FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  // Specify the type of element the ref will be attached to
  const firstInteractiveElement = useRef<HTMLButtonElement>(null);
  const toggleChat = () => {
    setIsOpen(!isOpen);
    if (!isOpen) {
      // Check if current is not null and then call focus
      setTimeout(() => {
        if (firstInteractiveElement.current) {
          firstInteractiveElement.current.focus();
        }
      }, 100);
    }
  };

  useEffect(() => {
    if (!isOpen) {
      // Use querySelector with type assertion
      const chatBubble = document.querySelector('.chat-bubble') as HTMLButtonElement | null;
      if (chatBubble) {
        chatBubble.focus();
      }
    }
  }, [isOpen]);

  return (
    <div className="chat-bubble-container">
      <div className={`chat-window ${isOpen ? 'open' : ''}`} aria-live="polite">
        <button className={`inner-close-button ${isOpen ? 'close-button' : 'open-button'}`} onClick={toggleChat} aria-label={isOpen ? 'Close chat' : 'Open chat'}>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.75} stroke="currentColor" className="w-6 h-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
        {/* You can add your chat UI here */}
        <iframe
          className='w-full h-full'
          title="helper"
          src="https://app.chatsmith.ai/#/embed?pb=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6Imx1a2VAbHVrZWJlcnJ5LmNvIiwiYm90X25hbWUiOiJmNGJmMzAxNC03NjgyLTExZWUtODg2My02NjdmYzEwNGY2NDgiLCJjdXN0b21lcl9pZCI6IiIsInN1YnNjcmlwdGlvbl9pZCI6IiJ9.YUdyL-cq4_ytCIRfBzfQFDifgXgDltubdzX-cFZTr5k"
        />
      </div>
      <button style={{ background: '#0A0333' }} className={`chat-bubble ${isOpen ? 'close-button' : 'open-button'}`} onClick={toggleChat} aria-label={isOpen ? 'Close chat' : 'Open chat'}>
        <span className={`icon text-white ${isOpen ? 'close-icon' : 'open-icon'}`}>
          {isOpen ?
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.75} stroke="currentColor" className="w-6 h-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
            :
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.75} stroke="currentColor" className="w-6 h-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="M20.25 8.511c.884.284 1.5 1.128 1.5 2.097v4.286c0 1.136-.847 2.1-1.98 2.193-.34.027-.68.052-1.02.072v3.091l-3-3c-1.354 0-2.694-.055-4.02-.163a2.115 2.115 0 01-.825-.242m9.345-8.334a2.126 2.126 0 00-.476-.095 48.64 48.64 0 00-8.048 0c-1.131.094-1.976 1.057-1.976 2.192v4.286c0 .837.46 1.58 1.155 1.951m9.345-8.334V6.637c0-1.621-1.152-3.026-2.76-3.235A48.455 48.455 0 0011.25 3c-2.115 0-4.198.137-6.24.402-1.608.209-2.76 1.614-2.76 3.235v6.226c0 1.621 1.152 3.026 2.76 3.235.577.075 1.157.14 1.74.194V21l4.155-4.155" />
            </svg>
          }
        </span>
      </button>
    </div>
  );
};
export default ChatBubble;