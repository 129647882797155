import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router";
import { useNavigate } from 'react-router-dom';
import { ExclamationTriangleIcon } from "@heroicons/react/20/solid";
import { Link } from 'react-router-dom';
import { Switch } from '@headlessui/react'
import CharacterLimitComponent from "../../components/CharacterLimitComponenet";
import Avatar from "@mui/joy/Avatar/Avatar";

import EditUrl from "./EditUrl";
import EditPdf from './EditPdf';
import ShowHistory from './ShowHistory';
import Loader from "../../components/Loader";

interface typeChatInfo {
  bot_id: number;
  instace_name: string;
  chat_name: string;
  prompt: string;
  urls: string;
  custom_text: string;
  created: string
}

interface EditBotProps {
  setEmbedUrl: (state: string) => void,
  setHeadChatInstanceName: (state: string) => void,
  activeTab: string,
  onChangeColor: Function,
  onChangeWelcome?: Function,
  onChangeLabel?: Function, 
}

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

const InstanceName = "";
const Prompt = "";
const ChatUrls = "";
const CustomContent = "";

export default function EditBot({ setEmbedUrl, setHeadChatInstanceName, activeTab, onChangeColor, onChangeLabel, onChangeWelcome }: EditBotProps) {
  const { id } = useParams();

  const navigate = useNavigate();
  const [botId, setBotId] = useState(0);
  const [loading, setLoading] = useState(false);
  const [showWarning, setShowWarning] = useState(false);

  useEffect(() => {
    localStorage.setItem('bot-editing', 'false');
    if (id) {
      setLoading(true);
      Promise.all([
        getChatInfos(id),
        getEmbedScriptToken(id),
        getTotalCount(),
      ]).catch(err => {
        console.log('err:', err);
      }).finally(() => setLoading(false));
    }
  }, [id]);

  const fileInput = React.useRef<any>()
  // states for editing and saving the items in settings
  const [editingChatInstanceName, setEditingChatInstanceName] = useState(false);
  const [editingPrompt, setEditingPrompt] = useState(false);
  const [editingCustomContent, setEditingCustomContent] = useState(false);
  const [avatarSizeExceeded, setAvatarSizeExceeded] = useState(false)

  // states for the values of the items in settings
  const [chatInstanceName, setChatInstanceName] = useState(InstanceName)
  // const [chatBotName, setChatBotName] = useState(BotName);
  const [primaryColor, setPrimaryColor] = useState<string>('#0b0333');
  const [avatarUrl, setAvatarUrl] = useState('')
  const [avatarFile, setAvatarFile] = useState<any>()
  const [chatUrls, setChatUrls] = useState(ChatUrls);
  const [prompt, setPrompt] = useState(Prompt);
  const [customContent, setCustomContent] = useState(CustomContent);
  const [submitable, setSubmitable] = useState<boolean>(false);
  const [welcome, setWelcome] = useState("");
  const [label, setLabel] = useState({
    text: 'Powered By ChatSmith.ai',
    link: 'https://chatsmith.ai',
  });

  const [model, setModel] = useState('gpt-3.5');
  const [botCount, setBotCount] = useState(1);
  const [planType, setPlanType] = useState('free');
  const [urlCount, setUrlCount] = useState(2);
  const [pdfCount, setPdfCount] = useState(1);
  const [characterCount, setCharacterCount] = useState(100);

  const [enabled, setEnabled] = useState(false);
  const [labelEnabled, setLabelEnabled] = useState(true);
  const [editingWelcome, setEditingWelcome] = useState(false);
  const [editingLabelText, setEditingLabelText] = useState(false);
  const [editingLabelLink, setEditingLabelLink] = useState(false);

  const [isChanging, setIsChaging] = useState(false);
  const [displayText, setDisplayText] = useState('GPT 3.5 Turbo costs 1 credit per message, while GPT 4 costs 20 credits per message');

  useEffect(() => {
    if (isChanging) {
      setDisplayText('Reduce prompt to 300 characters or less to select GPT-4');
    } else {
      setDisplayText('GPT 3.5 Turbo costs 1 credit per message, while GPT 4 costs 20 credits per message');
    }
  }, [isChanging]);

  useEffect(() => {
    setIsChaging(prompt.length > 300 && model === 'gpt-4');
  }, [prompt]);

  // change edit to true for our settings
  const handleEditChatInstanceName = () => {
    setEditingChatInstanceName(true);
  };

  const handleEditPrompt = () => {
    setEditingPrompt(true);
  };

  const handleEditCustomContent = () => {
    setEditingCustomContent(true);
  };

  // handle changes to the settings

  const handleChatInstanceNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setChatInstanceName(event.target.value);
  };

  const handlePromptChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setPrompt(event.target.value)
  };

  const handleCustomContentChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setCustomContent(event.target.value);
  };

  // handle submit for the settings
  const handleSubmitChatInstanceName = (event: React.FormEvent) => {
    event.preventDefault();
    setEditingChatInstanceName(false);
    localStorage.setItem('bot-editing', 'true');
    setSubmitable(true);
  };

  const handleSubmitPrompt = (event: React.FormEvent) => {
    event.preventDefault();
    setEditingPrompt(false);
    localStorage.setItem('bot-editing', 'true');
    setSubmitable(true);
  };

  const handleSubmitWelcome = (event: React.FormEvent) => {
    if (onChangeWelcome) {
      onChangeWelcome({
        enable: enabled,
        welcome: welcome,
      });
    }
    event.preventDefault();
    setEditingWelcome(false);
    localStorage.setItem('bot-editing', 'true');
    setSubmitable(true);
  };

  const handleSubmitLabelText = (event: React.FormEvent) => {
    event.preventDefault();
    if (onChangeLabel) {
      onChangeLabel({
        enable: labelEnabled,
        text: label.text,
        link: label.link,
      });
    }
    setEditingLabelText(false);
    localStorage.setItem('bot-editing', 'true');
    setSubmitable(true);
  };

  const handleSubmitLabelLink = (event: React.FormEvent) => {
    event.preventDefault();
    if (onChangeLabel) {
      onChangeLabel({
        enable: labelEnabled,
        text: label.text,
        link: label.link,
      });
    }
    setEditingLabelLink(false);
    localStorage.setItem('bot-editing', 'true');
    setSubmitable(true);
  }

  const handleSubmitCustomContent = (event: React.FormEvent) => {
    event.preventDefault();
    if (characterCount <= customContent.length) {
      setShowWarning(true);
      return;
    }
    setEditingCustomContent(false);
    localStorage.setItem('bot-editing', 'true');
    setSubmitable(true);
  };

  const getEmbedScriptToken = (id: string) => (
    new Promise((resolve, reject) => {
      const path = process.env.REACT_APP_BASE_URL + "/api/createEmbedScriptToken";
      const user_email = localStorage.getItem('email')
      const request = {
        email: user_email,
        bot_name: id
      }
      axios.post(path, request, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }).then((res) => {
        const _embedurl = `<iframe src="https://app.chatsmith.ai/#/embed?pb=${res.data.token}" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>`
        setEmbedUrl(_embedurl);
        resolve(true);
      }).catch(err => {
        console.log(err.data);
        reject();
      })
    })
  );

  function getFileSize(base64data: any) {
    return (atob(base64data).length / 1024).toFixed(2);
  }

  const getAvatarSize = () => {
    if (avatarFile) {
      return (avatarFile.size / 1024).toFixed(2);
    } else {
      if (avatarUrl) {
        return getFileSize(avatarUrl.slice(22));
      } else {
        return '0';
      }
    }
  };

  const getChatInfos = (id: string) => {
    return new Promise((resolve, reject) => {
      const path = process.env.REACT_APP_BASE_URL + "/api/getChatInfos";
      const user_email = localStorage.getItem('email')
      const request = {
        email: user_email
      }
      axios.post(path, request, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }).then((res) => {
        let _chatInfos = res.data.chats;
        _chatInfos.sort((a: typeChatInfo, b: typeChatInfo) => a.bot_id - b.bot_id);
        const chatData = _chatInfos.filter((chat: any) => chat.bot_name === id)[0];
        setHeadChatInstanceName(chatData.instance_name)
        setChatInstanceName(chatData.instance_name)
        if (chatData.pdf_file.length > 4) {
          setPrimaryColor(chatData.pdf_file);
          onChangeColor(chatData.pdf_file);
        }
        setAvatarUrl(`data:image/png;base64,${chatData.avatar}`)
        setChatUrls(chatData.urls)
        setPrompt(chatData.bot_prompt)
        setBotId(chatData.bot_id)
        setEnabled(chatData.welcome_message ? chatData.welcome_message.enable : false)
        setWelcome(chatData.welcome_message ? chatData.welcome_message.welcome : "")
        setLabelEnabled(chatData.label ? chatData.label.enable : true)
        setLabel(chatData.label ? { text: chatData.label.text, link: chatData.label.link } : { text: 'Powered By ChatSmith.ai', link: 'https://chatsmith.ai' })
        setModel(chatData.model)

        chatData.custom_text ? setCustomContent(chatData.custom_text) : setCustomContent(customContent)
        
        resolve(true);
      }).catch((err) => {
        console.log(err)
        navigate('/');
        reject();
      })
    });
  }

  const getTotalCount = () => {
    return new Promise((resolve, reject) => {
      let _totalCount = 1;
      const path = process.env.REACT_APP_BASE_URL + "/api/getSubscription";
      const user_email = localStorage.getItem('email')
      const request = {
        email: user_email
      }
      axios.post(path, request, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }).then((res) => {
        _totalCount = parseInt(res.data.detail.botCount);
        setBotCount(_totalCount);
        setPlanType(res.data.type);
        setUrlCount(res.data.detail.urlCount);
        setPdfCount(res.data.detail.pdfCount);
        setCharacterCount(res.data.detail.characterLimit);
        resolve(true);
      }).catch((err) => {
        console.log(err);
        reject();
      })
    });
  }

  const handleSubmitPublish = (event: React.FormEvent) => {
    event.preventDefault();
    const formData = new FormData();

    // Append each file to the formData object
    const user_email = localStorage.getItem('email')

    formData.append("email", String(user_email));
    formData.append("instance_name", chatInstanceName);
    formData.append("prompt", prompt);
    formData.append('welcome', JSON.stringify({enable: enabled, welcome: welcome}));
    formData.append('label', JSON.stringify({
      enable: labelEnabled,
      ...label,
    }))
    formData.append("bot_id", String(botId));
    formData.append('model', model);
    formData.append('bot_color', primaryColor);
    if (avatarFile) formData.append('bot_avatar', avatarFile)
    formData.append("custom_text", customContent);

    const path = process.env.REACT_APP_BASE_URL + "/api/updateChat";
    setLoading(true);
    axios.post(path, formData, {
      headers: {
        Authorization: localStorage.getItem("token"),
        "Content-Type": "multipart/form-data",
      },
    }).then(() => {
      window.location.reload()
      localStorage.setItem('bot-editing', 'false');
    }).catch((err) => {
      console.log(err)
    }).finally(() => {
      setLoading(false);
    });
  };

  const handleAvatarChange = (e: any) => {
    if (e.target.files.length <= 0) return;
    if (e.target.files[0].size > 100 * 1024) {
      setAvatarSizeExceeded(true);
      return;
    } else {
      setAvatarSizeExceeded(false);
    }
    setAvatarFile(e.target.files[0]);
    setAvatarUrl(URL.createObjectURL(e.target.files[0]));
    localStorage.setItem('bot-editing', 'true');
    setSubmitable(true);
  };

  return (
    <div className="w-full">
      <Loader loading={loading}>
        <div className="mx-auto max-w-7xl pt-3 sm:pt-4">
          <div className="ring-1 ring-gray-900/5 rounded-[20px] bg-white">
            <div className="px-3 py-4 sm:p-8 ">
              <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">

                {/* Instance name */}
                {
                  activeTab === 'settings' &&
                  <>
                    <h3 className="text-[#8F98A1] font-bold text-[20px]">Settings</h3>
                    <div className="col-span-full sm:px-4">
                      <div className="border-b pb-4 border-[#EFEFEF]">
                        <div className="text-[14px] font-semibold leading-6 text-gray-900 text-left">
                          Chat Instance Name
                        </div>
                        {editingChatInstanceName ? (
                          <div
                            className="flex flex-col md:flex-row items-start md:items-end pt-2"
                          >
                            <input
                              className="flex w-full text-sm leading-6 text-gray-900 sm:col-span-2 sm:mt-0 text-left border-gray-300 rounded-md shadow-sm focus:border-[#18A2AB] focus:outline-none sm:text-sm"
                              type="text"
                              value={chatInstanceName}
                              onChange={handleChatInstanceNameChange}
                            />
                            <button
                              className="ml-0 md:ml-3 mt-3 md:mt-0 rounded-[4px] px-4 py-2 text-sm font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 bg-[#FFE294] border-[1px] border-[#FED35E] hover:bg-[#fed35e]"
                              onClick={handleSubmitChatInstanceName}
                            >
                              Save
                            </button>
                          </div>
                        ) : (
                          <div className="flex justify-between items-center">
                            <div className="mt-1 flex text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                              {chatInstanceName}
                            </div>
                            <button
                              className="hover:opacity-40"
                              onClick={handleEditChatInstanceName}
                            >
                              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.6465 2.36519L12.9117 1.09919C13.1755 0.835434 13.5332 0.687256 13.9062 0.687256C14.2793 0.687256 14.637 0.835434 14.9008 1.09919C15.1645 1.36295 15.3127 1.72068 15.3127 2.09369C15.3127 2.4667 15.1645 2.82443 14.9008 3.08819L6.9365 11.0524C6.53999 11.4487 6.05102 11.74 5.51375 11.8999L3.5 12.4999L4.1 10.4862C4.25996 9.94892 4.55123 9.45995 4.9475 9.06344L11.6465 2.36519ZM11.6465 2.36519L13.625 4.34369M12.5 9.49994V13.0624C12.5 13.51 12.3222 13.9392 12.0057 14.2557C11.6893 14.5722 11.2601 14.7499 10.8125 14.7499H2.9375C2.48995 14.7499 2.06072 14.5722 1.74426 14.2557C1.42779 13.9392 1.25 13.51 1.25 13.0624V5.18744C1.25 4.73989 1.42779 4.31067 1.74426 3.9942C2.06072 3.67773 2.48995 3.49994 2.9375 3.49994H6.5" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
                              </svg>
                            </button>
                          </div>
                        )}
                      </div>

                      {/* Bot Avatar */}
                      <div className="border-b py-4 border-[#EFEFEF]">
                        <div
                          className="block text-sm font-semibold leading-6 text-gray-900"
                        >
                          Bot Avatar
                        </div>
                        <div className="flex mt-3 gap-[10px]">
                          <Avatar src={avatarUrl} sx={{ "--Avatar-size": '24px' }} />
                          <button className="border-[1px] text-[10px] font-bold px-[5px] py-[2px] rounded-[8px]" type="button" onClick={() => fileInput.current.click()}>Change</button>
                          <div className="text-[14px] text-gray-500 font-bold">
                            {`${getAvatarSize()}/100KB`}
                          </div>
                        </div>
                        <p className="mt-2 text-xs  leading-2 text-gray-400">
                          Suggested size: 64x64 pixels
                          {
                            avatarSizeExceeded && (<span className="text-[red] m-[0] text-xs ml-2">File size exceeded of 100KB</span>)
                          }
                        </p>
                        <input
                          ref={fileInput}
                          type="file"
                          accept="image/*"
                          style={{ display: 'none' }}
                          onChange={handleAvatarChange}
                        />
                      </div>

                      {/* Bot Model */}
                      <div className="border-b py-4 border-[#EFEFEF]">
                        <div
                          className="block text-sm font-semibold leading-6 text-gray-900"
                        >
                          Model
                        </div>
                        <select
                          disabled={planType === 'free'}
                          className={`mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 sm:text-sm sm:leading-6 focus:ring-2 ${isChanging ? 'ring-[red] focus:ring-[red]' : 'ring-[#18A2AB] focus:ring-[#18A2AB]'}`}
                          value={model}
                          onChange={e => {
                            localStorage.setItem('bot-editing', 'true');
                            setSubmitable(true);
                            setModel(e.target.value);

                            if (e.target.value === 'gpt-4') {
                              setIsChaging(true);

                              if (prompt.length < 300) {
                                setIsChaging(false);
                              } else {
                                setIsChaging(true);
                              }
                            } else {
                              setIsChaging(false);
                            }
                          }}
                        >
                          <option value="gpt-3.5">GPT 3.5 Turbo</option>
                          <option value="gpt-4">GPT 4</option>
                        </select>
                        <p className={`font-[Inter] font-normal text-xs mb-0 mt-2 ${isChanging ? 'text-[red]' : 'text-[#000000]/[.46]'}`}>{displayText}</p>
                      </div>

                      {/* prompt texts */}
                      <div className="border-b py-4 border-[#EFEFEF]">
                        <div className="text-[14px] font-semibold leading-6 text-gray-900 text-left">
                          Prompt
                        </div>
                        {editingPrompt ? (
                          <div
                            className="flex flex-col md:flex-row items-start md:items-end pt-2"
                          >
                            <textarea
                              className="flex w-full text-sm leading-6 text-gray-900 sm:col-span-2 sm:mt-0 text-left border-gray-300 rounded-md shadow-sm focus:border-[#18A2AB] focus:outline-none sm:text-sm"
                              rows={8}
                              value={prompt}
                              onChange={handlePromptChange}
                              maxLength={600}
                            />
                            <button
                              className="ml-0 md:ml-3 mt-3 md:mt-0 rounded-[4px] px-4 py-2 text-sm font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 bg-[#FFE294] border-[1px] border-[#FED35E] hover:bg-[#fed35e]"
                              onClick={handleSubmitPrompt}
                            >
                              Save
                            </button>
                          </div>
                        ) : (
                          <div className="flex justify-between items-start">
                            <div className="mt-1 flex text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 text-left">
                              <CharacterLimitComponent text={prompt} limit={250} />
                            </div>
                            <button
                              className="hover:opacity-40 pl-5"
                              onClick={handleEditPrompt}
                            >
                              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.6465 2.36519L12.9117 1.09919C13.1755 0.835434 13.5332 0.687256 13.9062 0.687256C14.2793 0.687256 14.637 0.835434 14.9008 1.09919C15.1645 1.36295 15.3127 1.72068 15.3127 2.09369C15.3127 2.4667 15.1645 2.82443 14.9008 3.08819L6.9365 11.0524C6.53999 11.4487 6.05102 11.74 5.51375 11.8999L3.5 12.4999L4.1 10.4862C4.25996 9.94892 4.55123 9.45995 4.9475 9.06344L11.6465 2.36519ZM11.6465 2.36519L13.625 4.34369M12.5 9.49994V13.0624C12.5 13.51 12.3222 13.9392 12.0057 14.2557C11.6893 14.5722 11.2601 14.7499 10.8125 14.7499H2.9375C2.48995 14.7499 2.06072 14.5722 1.74426 14.2557C1.42779 13.9392 1.25 13.51 1.25 13.0624V5.18744C1.25 4.73989 1.42779 4.31067 1.74426 3.9942C2.06072 3.67773 2.48995 3.49994 2.9375 3.49994H6.5" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
                              </svg>
                            </button>
                          </div>
                        )}
                      </div>
                      <div className="mt-2 flex justify-between">
                        <p className="text-[#000000]/[.46] text-xs mb-0">GPT 3.5 Turbo: 600 character limit - GPT 4: 300 character limit</p>
                        <p className={`whitespace-nowrap ms-2 text-xs mb-0 ${prompt.length > (model === 'gpt-3.5' ? 600 : 300) ? 'text-[red]' : ''}`}>
                          {`${prompt.length} / ${(model === 'gpt-3.5' ? 600 : 300)}`}
                        </p>
                      </div>
                      <div className="border-b py-4 border-[#EFEFEF]">
                        <div className="text-[14px] font-semibold leading-6 text-gray-900 text-left">
                          Primary Color
                        </div>
                        <div
                          className="flex justify-start items-center my-1"
                        >
                          <input
                            value={primaryColor}
                            type="color"
                            className="w-[16px] h-[16px] rounded me-2"
                            onChange={e => {
                              setPrimaryColor(e.target.value);
                              onChangeColor(e.target.value);
                              localStorage.setItem('bot-editing', 'true');
                              setSubmitable(true);
                            }}
                          />
                          <input
                            type="text"
                            value={primaryColor}
                            onChange={e => {
                              setPrimaryColor(e.target.value);
                              onChangeColor(e.target.value);
                              localStorage.setItem('bot-editing', 'true');
                              setSubmitable(true);
                            }}
                            className="text-xs font-[Inter] font-normal border-0 focus:ring-0 outline-none text-[black]"
                          />
                          {/* {primaryColor} */}
                        </div>
                      </div>
                      <div className="border-b py-4 border-[#EFEFEF]">
                        <div className="text-[14px] font-semibold leading-6 text-gray-900 text-left flex items-center mb-2">
                          Welcome Message
                          <Switch
                            checked={enabled}
                            onChange={(e) => {
                              if (onChangeWelcome) {
                                onChangeWelcome({
                                  enable: e,
                                  welcome: welcome,
                                });
                              }
                              setEnabled(e);
                              localStorage.setItem('bot-editing', 'true');
                              setSubmitable(true);
                            }}
                            className={classNames(
                              enabled ? 'bg-[#18A2AB]' : 'bg-gray-200',
                              'ms-3 relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2'
                            )}
                          >
                            <span className="sr-only">Use setting</span>
                            <span
                              aria-hidden="true"
                              className={classNames(
                                enabled ? 'translate-x-5' : 'translate-x-0',
                                'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                              )}
                            />
                          </Switch>
                        </div>
                        {
                        enabled && (editingWelcome ? (
                          <div
                            className="flex flex-col md:flex-row items-start md:items-end"
                          >
                            <textarea
                              className="flex w-full text-sm leading-6 text-gray-900 sm:col-span-2 sm:mt-0 text-left border-gray-300 rounded-md shadow-sm focus:border-[#18A2AB] focus:outline-none sm:text-sm"
                              rows={8}
                              value={welcome}
                              onChange={e => setWelcome(e.target.value)}
                            />
                            <button
                              className="ml-0 md:ml-3 mt-3 md:mt-0 rounded-[4px] px-4 py-2 text-sm font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 bg-[#FFE294] border-[1px] border-[#FED35E] hover:bg-[#fed35e]"
                              onClick={handleSubmitWelcome}
                            >
                              Save
                            </button>
                          </div>
                        ) : (
                          <div className="flex justify-between items-start">
                            <div className="mt-1 flex text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 text-left">
                              <CharacterLimitComponent text={welcome} limit={250} />
                            </div>
                            <button
                              className="hover:opacity-40 pl-5"
                              onClick={() => setEditingWelcome(true)}
                            >
                              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.6465 2.36519L12.9117 1.09919C13.1755 0.835434 13.5332 0.687256 13.9062 0.687256C14.2793 0.687256 14.637 0.835434 14.9008 1.09919C15.1645 1.36295 15.3127 1.72068 15.3127 2.09369C15.3127 2.4667 15.1645 2.82443 14.9008 3.08819L6.9365 11.0524C6.53999 11.4487 6.05102 11.74 5.51375 11.8999L3.5 12.4999L4.1 10.4862C4.25996 9.94892 4.55123 9.45995 4.9475 9.06344L11.6465 2.36519ZM11.6465 2.36519L13.625 4.34369M12.5 9.49994V13.0624C12.5 13.51 12.3222 13.9392 12.0057 14.2557C11.6893 14.5722 11.2601 14.7499 10.8125 14.7499H2.9375C2.48995 14.7499 2.06072 14.5722 1.74426 14.2557C1.42779 13.9392 1.25 13.51 1.25 13.0624V5.18744C1.25 4.73989 1.42779 4.31067 1.74426 3.9942C2.06072 3.67773 2.48995 3.49994 2.9375 3.49994H6.5" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
                              </svg>
                            </button>
                          </div>
                        ))
                        }
                        <p className="font-[Inter] text-[#000000]/[.46] font-normal text-xs mb-0 mt-2">Show an opening message to users</p>
                      </div>
                      <div className="border-b py-4 border-[#EFEFEF]">
                        {planType !== 'pro' &&
                          <p className="text-xs font-[Inter] text-[black]/[.46] font-bold">Requires Pro Plan <Link to="/pricing" className="text-[#18A2AB] no-underline">Upgrade Now</Link></p>
                        }
                        <div className={`text-[14px] font-semibold leading-6 text-gray-900 text-left flex items-center mb-2 ${planType === 'pro' ? 'opacity-1' : 'opacity-[.5]'}`}>
                          Powered by Message
                          <Switch
                            disabled={planType !== 'pro'}
                            checked={labelEnabled}
                            onChange={(e) => {
                              setLabelEnabled(e);
                              localStorage.setItem('bot-editing', 'true');
                              setSubmitable(true);
                              if (onChangeLabel) {
                                onChangeLabel({
                                  enable: e,
                                  text: label.text,
                                  link: label.link,
                                });
                              }
                            }}
                            className={classNames(
                              labelEnabled ? 'bg-[#18A2AB]' : 'bg-gray-200',
                              'ms-3 relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2'
                            )}
                          >
                            <span className="sr-only">Use setting</span>
                            <span
                              aria-hidden="true"
                              className={classNames(
                                labelEnabled ? 'translate-x-5' : 'translate-x-0',
                                'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                              )}
                            />
                          </Switch>
                        </div>
                        {
                        labelEnabled && <>{editingLabelText ? 
                          (
                            <div
                              className="flex flex-col md:flex-row items-start md:items-end"
                            >
                              <textarea
                                className="flex w-full text-sm leading-6 text-gray-900 sm:col-span-2 sm:mt-0 text-left border-gray-300 rounded-md shadow-sm focus:border-[#18A2AB] focus:outline-none sm:text-sm"
                                rows={1}
                                value={label.text}
                                onChange={e => setLabel({ ...label, text: e.target.value })}
                              />
                              <button
                                className="ml-0 md:ml-3 mt-3 md:mt-0 rounded-[4px] px-4 py-2 text-sm font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 bg-[#FFE294] border-[1px] border-[#FED35E] hover:bg-[#fed35e]"
                                onClick={handleSubmitLabelText}
                              >
                                Save
                              </button>
                            </div>
                          ) : (
                            <div className={`flex justify-between items-center ${planType === 'pro' ? 'opacity-1' : 'opacity-[.5]'}`}>
                              <div className="mt-1 flex flex-col text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 text-left">
                                <p className="w-full font-bold mb-1 text-md">Text</p>
                                <CharacterLimitComponent text={label.text} limit={250} />
                              </div>
                              <button
                                className="hover:opacity-40 pl-5"
                                onClick={() => {
                                  if (planType !== 'pro') {
                                    return;
                                  }
                                  setEditingLabelText(true);
                                }}
                              >
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M11.6465 2.36519L12.9117 1.09919C13.1755 0.835434 13.5332 0.687256 13.9062 0.687256C14.2793 0.687256 14.637 0.835434 14.9008 1.09919C15.1645 1.36295 15.3127 1.72068 15.3127 2.09369C15.3127 2.4667 15.1645 2.82443 14.9008 3.08819L6.9365 11.0524C6.53999 11.4487 6.05102 11.74 5.51375 11.8999L3.5 12.4999L4.1 10.4862C4.25996 9.94892 4.55123 9.45995 4.9475 9.06344L11.6465 2.36519ZM11.6465 2.36519L13.625 4.34369M12.5 9.49994V13.0624C12.5 13.51 12.3222 13.9392 12.0057 14.2557C11.6893 14.5722 11.2601 14.7499 10.8125 14.7499H2.9375C2.48995 14.7499 2.06072 14.5722 1.74426 14.2557C1.42779 13.9392 1.25 13.51 1.25 13.0624V5.18744C1.25 4.73989 1.42779 4.31067 1.74426 3.9942C2.06072 3.67773 2.48995 3.49994 2.9375 3.49994H6.5" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                              </button>
                            </div>
                          )}
                          {editingLabelLink ? (
                            <div
                              className="flex flex-col md:flex-row items-start md:items-end"
                            >
                              <textarea
                                className="flex w-full text-sm leading-6 text-gray-900 sm:col-span-2 sm:mt-0 text-left border-gray-300 rounded-md shadow-sm focus:border-[#18A2AB] focus:outline-none sm:text-sm"
                                rows={1}
                                value={label.link}
                                onChange={e => setLabel({ ...label, link: e.target.value })}
                              />
                              <button
                                className="ml-0 md:ml-3 mt-3 md:mt-0 rounded-[4px] px-4 py-2 text-sm font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 bg-[#FFE294] border-[1px] border-[#FED35E] hover:bg-[#fed35e]"
                                onClick={handleSubmitLabelLink}
                              >
                                Save
                              </button>
                            </div>
                          ) : (
                            <div className={`flex justify-between items-center ${planType === 'pro' ? 'opacity-1' : 'opacity-[.5]'}`}>
                              <div className="mt-1 flex flex-col text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 text-left">
                                <p className="w-full font-bold mb-1 text-md">Link</p>
                                <CharacterLimitComponent text={label.link} limit={250} />
                              </div>
                              <button
                                className="hover:opacity-40 pl-5"
                                onClick={() => {
                                  if (planType !== 'pro') {
                                    return;
                                  }
                                  setEditingLabelLink(true);
                                }}
                              >
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M11.6465 2.36519L12.9117 1.09919C13.1755 0.835434 13.5332 0.687256 13.9062 0.687256C14.2793 0.687256 14.637 0.835434 14.9008 1.09919C15.1645 1.36295 15.3127 1.72068 15.3127 2.09369C15.3127 2.4667 15.1645 2.82443 14.9008 3.08819L6.9365 11.0524C6.53999 11.4487 6.05102 11.74 5.51375 11.8999L3.5 12.4999L4.1 10.4862C4.25996 9.94892 4.55123 9.45995 4.9475 9.06344L11.6465 2.36519ZM11.6465 2.36519L13.625 4.34369M12.5 9.49994V13.0624C12.5 13.51 12.3222 13.9392 12.0057 14.2557C11.6893 14.5722 11.2601 14.7499 10.8125 14.7499H2.9375C2.48995 14.7499 2.06072 14.5722 1.74426 14.2557C1.42779 13.9392 1.25 13.51 1.25 13.0624V5.18744C1.25 4.73989 1.42779 4.31067 1.74426 3.9942C2.06072 3.67773 2.48995 3.49994 2.9375 3.49994H6.5" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                              </button>
                            </div>
                          )}
                          </>
                        }
                        <p className="font-[Inter] text-[#000000]/[.46] font-normal text-xs mb-0 mt-2">Hide or white label the Chat UI</p>
                      </div>
                    </div>

                    <h3 className="text-[#8F98A1] font-bold text-[20px]">Additional&nbsp;Context</h3>
                    <div className="col-span-full sm:px-4">
                      {/* Custom Content */}
                      <div className="col-span-full border-b pb-4">
                        {botCount === 1 &&
                        <p className="text-xs font-[Inter] text-[black]/[.46] font-bold">Requires Hobby Plan <Link to="/pricing" className="text-[#18A2AB] no-underline">Upgrade Now</Link></p>
                        }
                        <div className={`text-[14px] font-semibold leading-6 text-gray-900 text-left ${botCount === 1 ? 'opacity-[.5]' : 'opacity-1'}`}>
                          Custom 
                        </div>
                        {editingCustomContent ? (
                          <div
                            className="flex flex-col md:flex-row items-start md:items-end"
                          >
                            <textarea
                              className="flex w-full text-sm leading-6 text-gray-900 sm:col-span-2 sm:mt-0 text-left border-gray-300 rounded-md shadow-sm focus:border-[#18A2AB] focus:outline-none sm:text-sm"
                              value={customContent}
                              placeholder="Enter promo code: DAD20 at checkout to receive 20% off your order at checkout."
                              rows={8}
                              onChange={handleCustomContentChange}
                            />
                            <button
                              className="ml-0 md:ml-3 mt-3 md:mt-0 rounded-[4px] px-4 py-2 text-sm font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 bg-[#FFE294] border-[1px] border-[#FED35E] hover:bg-[#fed35e]"
                              onClick={handleSubmitCustomContent}
                            >
                              Save
                            </button>
                          </div>
                        ) : (
                          <div className={`flex justify-between items-start ${botCount === 1 ? 'opacity-[.5]' : 'opacity-1'}`}>
                            <div className="mt-1 flex text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 text-left">
                              <CharacterLimitComponent
                                text={customContent}
                                limit={250}
                              />
                            </div>
                            <button
                              className="hover:opacity-40 pl-5"
                              onClick={handleEditCustomContent}
                              disabled={botCount === 1}
                            >
                              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.6465 2.36519L12.9117 1.09919C13.1755 0.835434 13.5332 0.687256 13.9062 0.687256C14.2793 0.687256 14.637 0.835434 14.9008 1.09919C15.1645 1.36295 15.3127 1.72068 15.3127 2.09369C15.3127 2.4667 15.1645 2.82443 14.9008 3.08819L6.9365 11.0524C6.53999 11.4487 6.05102 11.74 5.51375 11.8999L3.5 12.4999L4.1 10.4862C4.25996 9.94892 4.55123 9.45995 4.9475 9.06344L11.6465 2.36519ZM11.6465 2.36519L13.625 4.34369M12.5 9.49994V13.0624C12.5 13.51 12.3222 13.9392 12.0057 14.2557C11.6893 14.5722 11.2601 14.7499 10.8125 14.7499H2.9375C2.48995 14.7499 2.06072 14.5722 1.74426 14.2557C1.42779 13.9392 1.25 13.51 1.25 13.0624V5.18744C1.25 4.73989 1.42779 4.31067 1.74426 3.9942C2.06072 3.67773 2.48995 3.49994 2.9375 3.49994H6.5" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
                              </svg>
                            </button>
                          </div>
                        )}
                        <div className="text-gray-500 text-xs mt-2" >Give your bot additional custom context.</div>
                      </div>
                    </div>

                    <div className="col-span-full">
                      <button
                        onClick={handleSubmitPublish}
                        disabled={!submitable || (prompt.length > (model === 'gpt-4' ? 300 : 600))}
                        className="font-bold text-xs px-4 py-2 rounded-[4px] disabled:bg-[#D9D9D9] enabled:bg-[#FFE294] enabled:border-[#FED35E] enabled:border-[1px] enabled:hover:bg-[#fed35e] "
                      >
                        Publish
                      </button>
                    </div>
                  </>
                }
                {
                  activeTab === 'url' &&
                  <EditUrl urls={chatUrls} totalCount={urlCount} planType={planType} botId={botId} />
                }

                {activeTab === 'pdf' &&
                  <EditPdf botId={botId} totalCount={pdfCount} />
                }
                {
                  activeTab === 'history' && <ShowHistory botName={id} avatar={avatarUrl} />
                }
              </div>
            </div>
          </div >
          {
            showWarning &&
            <div className="fixed bottom-[100px] left-[0] z-30 w-full flex items-center justify-center">
                <div className="border-l-4 border-yellow-400 bg-yellow-50 p-4 w-auto">
                    <div className="flex">
                        <div className="flex-shrink-0">
                            <ExclamationTriangleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                        </div>
                        <div className="ml-3">
                            <p className="text-sm text-yellow-700 mb-[0]">
                                You have exceeded the number of links.{' '}
                                <Link to="/pricing" className="font-medium text-yellow-700 underline hover:text-yellow-600">
                                    Upgrade plan
                                </Link>
                            </p>
                        </div>
                        <div className="ms-[50px] cursor-pointer" onClick={() => setShowWarning(false)}>
                            <svg width="20" height="20" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3.5 10.5L10.5 3.5M3.5 3.5L10.5 10.5" stroke="#FED35E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </div>
                    </div>
                </div>
              </div>
            }
        </div>
      </Loader>
    </div >
  );
}
