import React, { FC, useEffect, useState, useRef } from "react";
import Modal from "@mui/joy/Modal/Modal";
import Avatar from '@mui/joy/Avatar/Avatar';
import ModalDialog from "@mui/joy/ModalDialog/ModalDialog";
import CircularProgress from '@mui/joy/CircularProgress';
import axios from 'axios'
import { Link, useNavigate } from 'react-router-dom';
import { ExclamationTriangleIcon } from "@heroicons/react/20/solid";
import Loader from "../components/Loader";
import { GridLoader } from "react-spinners";

import CompletedStep from '../assets/completed-step.svg';
import InactiveStep from '../assets/inactive-step.svg';
import Arrow from '../assets/arrow.svg';
import ActiveStep from '../assets/active-step.svg';
import Back from '../assets/back.svg';
import Next from '../assets/next.svg';
import Close from '../assets/close.svg';


const CreateBot: FC = () => {
  const [instanceName, setInstancename] = useState("")
  const [botPrompt, setBotPrompt] = useState("")
  const [urls, setUrls] = useState([]);
  const [selectableUrls, setSelectableUrls] = useState<string[]>([]);
  const [selectedUrls, setSelectedUrls] = useState<string[]>([]);
  const [websiteUrl, setWebsiteUrl] = useState('');
  const [manualUrl, setManualUrl] = useState('');
  const [botId, setBotId] = useState(1)
  const [showModal, setShowModal] = useState(false)
  const [buttonState, setButtonState] = useState(false)
  const [loading, setLoading] = useState(false)
  const [fetching, setFetching] = useState('before')
  const [botCount, setBotCount] = useState(-1);
  const [urlCount, setUrlCount] = useState(2);
  const [pdfCount, setPdfCount] = useState(1);
  const [avatarUrl, setAvatarUrl] = useState('')
  const [avatarFile, setAvatarFile] = useState<any>()
  const [valid, setValid] = useState(true)
  const [avatarSizeExceeded, setAvatarSizeExceeded] = useState(false)
  const [fileSize, setFileSize] = useState(0);
  const [fileList, setFileList] = useState<File[]>([]);
  const [activeStep, setActiveStep] = useState(1);
  const [showWarning, setShowWarning] = useState(false);
  const [currentSource, setCurrentSource] = useState('website'); //'website' and 'pdf' are available.
  const [currentPlan, setCurrentPlan] = useState('free');

  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const fileInput = useRef<any>();

  const navigate = useNavigate();

  useEffect(() => {
    getInfo()
  }, [])

  useEffect(() => {
    setValid(true);
    setShowWarning(false);
    setAvatarSizeExceeded(false);
  }, [activeStep]);

  useEffect(() => {
    if (currentSource === 'website') {
      // setFileList([]);
      setButtonState(selectedUrls.length >= 1);
    } else if (currentSource === 'pdf') {
      // setSelectedUrls([]);
      setButtonState(fileList.length >= 1);
    } else if (currentSource === 'website-m') {
      const urlM = manualUrl.split(',');
      setButtonState(urlM[0].length >= 2 && urlM.length >= 1);
    }

    if (Number(fileSize) > 10) {
      setButtonState(false);
    }
  }, [selectedUrls, fileList, fileSize, manualUrl]);

  useEffect(() => {
    if (currentSource === 'website') {
      setFileList([]);
      setManualUrl('');
    } else if (currentSource === 'pdf') {
      setSelectedUrls([]);
      setManualUrl('');
    } else if (currentSource === 'website-m') {
      setSelectableUrls([]);
      setFileList([]);
    }
    setShowWarning(false);
  }, [currentSource]);

  useEffect(() => {
    let sum = 0;
    for (const item of fileList) {
      sum += item.size / 1024 / 1024;
    }
    setFileSize(sum);
  }, [fileList]);

  const getInfo = async () => {
    const path = process.env.REACT_APP_BASE_URL + "/api/getChatInfos";
    const user_email = localStorage.getItem('email')
    const request = {
      email: user_email
    }
    setLoading(true);
    axios.post(path, request, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    }).then(async (res) => {
      if (res.data.chats.length === 0) {
        setBotId(1)
        let _totalCount = 1;
        const path = process.env.REACT_APP_BASE_URL + "/api/getSubscription";
        const user_email = localStorage.getItem('email')
        const request = {
          email: user_email
        }
        await axios.post(path, request, {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }).then((res) => {
          _totalCount = parseInt(res.data.detail.botCount)
          setBotCount(_totalCount);
          setCurrentPlan(res.data.type);
          setUrlCount(res.data.detail.urlCount);
          setPdfCount(res.data.detail.pdfCount);
        }).catch((err) => {
          console.log(err)
        })
      } else {
        let _botId = 0;
        let _totalCount = 1;
        const path = process.env.REACT_APP_BASE_URL + "/api/getSubscription";
        const user_email = localStorage.getItem('email')
        const request = {
          email: user_email
        }
        await axios.post(path, request, {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }).then((res) => {
          _totalCount = parseInt(res.data.detail.botCount)
          setBotCount(_totalCount);
          setCurrentPlan(res.data.type);
          setUrlCount(res.data.detail.urlCount);
          setPdfCount(res.data.detail.pdfCount);
        }).catch((err) => {
          console.log(err)
        })
        outerloop:
        for (var i = 1; i <= _totalCount; i++) {
          var flag = 0;
          for (var j = 0; j < res.data.chats.length; j++) {
            if (i === res.data.chats[j].bot_id) {
              flag = 1;
              break;
            }
          }
          if (flag === 0) {
            _botId = i;
            setBotId(i);
            break outerloop;
          }
        }
        if (_botId === 0) {
          navigate('/')
        }
      }
    }).catch((err) => {
      console.log(err)
    }).finally(() => setLoading(false));
  };

  const handleChangeUrlSelection = (itemUrl: string, selected: boolean) => {
    if (selected) {
      setSelectedUrls([
        ...selectedUrls,
        itemUrl,
      ]);
    } else {
      setSelectedUrls(selectedUrls.filter(url => url !== itemUrl));
    }
  };

  const handleSubmit = () => {
    if (activeStep === 1) {
      if (
        (currentSource === 'website' && urlCount < selectedUrls.length) || 
        (currentSource === 'pdf' && fileList.length > pdfCount) ||
        (currentSource === 'website-m' && urlCount < manualUrl.split(',').length)
      ) {
        setShowWarning(true);
        return;
      }
      setActiveStep(2);
      return;
    }

    if (instanceName !== "" && ((currentSource === 'website' && selectedUrls.length > 0) || (currentSource === 'pdf' && fileList.length > 0) || (currentSource === 'website-m' && 0 < manualUrl.split(',').length))) {
      setValid(true)
      // setButtonState(true)
      setLoading(true)
      setShowModal(true)

      const user_email = localStorage.getItem('email')


      const path = process.env.REACT_APP_BASE_URL + "/api/newChat"
      const requestBody = new FormData()
      requestBody.append('instace_name', instanceName)
      // requestBody.append('bot_name', botName);
      if (avatarFile) requestBody.append('bot_avatar', avatarFile)
      fileList.forEach((file) => {
        requestBody.append("files", file);
      });
      if (user_email) requestBody.append('email', user_email)
      requestBody.append('bot_id', botId.toString());
      if (currentSource === 'website') {
        requestBody.append('urls_input', selectedUrls.join(','));
      } else if (currentSource === 'website-m') {
        requestBody.append('urls_input', manualUrl);
      } else {
        requestBody.append('urls_input', '');
      }
      requestBody.append('bot_prompt', botPrompt)

      axios.post(path, requestBody, {
        headers: {
          Authorization: localStorage.getItem("token"),
          "Content-Type": 'multipart/form-data'
        },
      }).then((res) => {
        navigate('/')
      }).catch((err) => {
        console.log(err)
      }).finally(() => {
        setLoading(false);
        setShowModal(false);
      });
    } else {
      setValid(false);
    }
  };

  const handleBack = () => {
    if (activeStep === 2) {
      setActiveStep(1);
    } else if (activeStep === 1) {
      navigate('/');
    }
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const files = Array.from(event.dataTransfer.files);
    const pdfFiles = files.filter(file => file.type === 'application/pdf');
    setFileList(prevFiles => [...prevFiles, ...pdfFiles]);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = event.target.files;

    if (selectedFiles) {
      const newFiles = Array.from(selectedFiles)

      const pdfFiles = newFiles.filter(file => file.type === 'application/pdf');

      setFileList(prevFiles => [...prevFiles, ...pdfFiles]);
      localStorage.setItem('bot-editing', 'true');
    }
  };

  const onChangeUrl = (event: any) => {
    setWebsiteUrl(event.target.value);
  }

  const handleAvatarChange = (e: any) => {
    if (e.target.files.length <= 0) return;
    if (e.target.files[0].size > 100 * 1024) {
      setAvatarSizeExceeded(true);
      return;
    } else {
      setAvatarSizeExceeded(false);
    }
    setAvatarFile(e.target.files[0]);
    setAvatarUrl(URL.createObjectURL(e.target.files[0]));
  };

  const handleChangeFilter = (e: any) => {
    setSelectableUrls(urls.filter((url: any) => url.includes(e.target.value)));
  };

  const onManualUrlChange = (e: any) => {
    setManualUrl(e.target.value);
  };

  const handleFetch = () => {
    var urlPattern = new RegExp('^(https?:\\/\\/)?' + // validate protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // validate domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // validate OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // validate port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // validate query string
      '(\\#[-a-z\\d_]*)?$', 'i'); // validate fragment locator

    const isValid = urlPattern.test(websiteUrl);
    setValid(isValid);
    if (!isValid) return;

    setFetching('doing');
    const path = `${process.env.REACT_APP_BASE_URL}/api/fetchPage`;
    axios.post(path, { url: websiteUrl }).then(res => {
      setUrls(res.data.urls);
      setSelectableUrls(res.data.urls);
      setSelectedUrls([]);
    }).catch(err => {
      console.log('fetching error:', err);
    }).finally(() => setFetching('done'));
  };

  return (
    <div className="relative" style={{ minHeight: 'calc(100vh - 64px)' }}>
      <Loader loading={loading}>
        <div className={"py-10"}>
          <header>
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 flex items-center">
              <h1 className="text-[24px] font-bold leading-tight tracking-tight text-[#161616]">
                Create Chatbot
              </h1>

              <div className="ms-4 flex">
                {activeStep === 1 ? <img src={ActiveStep} alt="active" /> : <img src={CompletedStep} alt="complete" />}
                <div className="ms-2">
                  <p className="text-[#8F98A1] text-[10px] font-[Inter] mb-0 font-medium">Step 1</p>
                  <p className={`text-[12px] font-[Inter] mb-0 font-medium ${activeStep === 1 ? 'text-[#18A2AB]' : 'text-[#161616]'}`}>Context Source</p>
                </div>
              </div>

              <div className="ms-4">
                <img src={Arrow} alt="arrow" />
              </div>

              <div className="ms-4 flex">
                {activeStep === 2 ? <img src={ActiveStep} /> : <img src={InactiveStep} />}
                <div className="ms-2">
                  <p className="text-[#8F98A1] text-[10px] font-[Inter] font-medium mb-0">Step 2</p>
                  <p className={`text-[12px] font-[Inter] mb-0 font-medium ${activeStep === 2 ? 'text-[#18A2AB]' : 'text-[#161616]'}`}>Chatbot Settings</p>
                </div>
              </div>
            </div>
          </header>
          <main className="mx-auto max-w-7xl py-10 sm:px-6">
            <div className="shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl sm:mx-0 bg-white">
              <div className="px-4 py-6 sm:p-8">
                {/* <div className="grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6"> */}
                {activeStep === 2 &&
                  <div>
                    <div className="col-span-full">
                      <div className="grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-[20px]">
                        <div>
                          <label
                            htmlFor="about"
                            className="block text-sm font-semibold leading-6 text-gray-900"
                          >
                            Chat Instance Name *
                            {!valid && <span className="text-[red] text-[12px] ms-3">Please input your chat instance name</span>}
                          </label>
                          <div className="mt-2">
                            <input
                              id="chatName"
                              name="chatName"
                              onChange={(e) => {
                                setInstancename(e.target.value)
                              }}
                              placeholder="Instance Chat Name"
                              className={`min-w-[500px] block rounded-md border-[1px] px-3 py-1.5 text-gray-900 placeholder:text-gray-400 sm:text-sm sm:leading-6 focus:border-[#18A2AB] focus:outline-none ${instanceName.length <= 0 && !valid ? 'border-[red]' : ''}`}
                            />
                          </div>
                          <p className="mt-1 mb-3 text-xs leading-2 text-gray-400">
                            Something that will help you remember what this chat is
                            about, such as {`{Website Name}`} Chat.
                          </p>
                        </div>
                        <div>
                          <label
                            htmlFor="about"
                            className="block text-sm font-semibold leading-6 text-gray-900 flex gap-[20px]"
                          >
                            Bot Avatar
                            {botCount === 1 && <label className="text-[12px] text-gray-500 font-semibold">Premium feature <a className="text-[#18A2AB] cursor-pointer text-decoration-none">Upgade now</a></label>}
                          </label>
                          <input
                            ref={fileInput}
                            type="file"
                            accept="image/*"
                            style={{ display: 'none' }}
                            onChange={handleAvatarChange}
                          />
                          <div className="flex mt-3 gap-[10px]">
                            <Avatar sx={{ "--Avatar-size": '24px' }} src={avatarUrl} />
                            <button className="border-[1px] text-[10px] font-bold px-[5px] py-[2px] rounded-[8px]" type="button" onClick={() => fileInput.current.click()}>Change</button>
                            <label className="text-[14px] text-gray-500 font-bold">({!avatarFile ? 0 : (avatarFile.size / 1024).toFixed(2)}/100KB)</label>
                          </div>
                          <div className="mt-1 mb-3 text-xs  leading-2 text-gray-400">
                            Suggested size: 64x64 pixels
                            {
                              avatarSizeExceeded && (<span className="text-[red] m-[0] text-xs ml-2">File size exceeded of 100KB</span>)
                            }
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-span-full mt-3">
                      <label
                        htmlFor="about"
                        className="block text-sm font-semibold leading-6 text-gray-900"
                      >
                        Prompt
                      </label>
                      <div className="mt-2">
                        <textarea
                          id="prompt"
                          name="prompt"
                          placeholder="Answer the following question using only the information provided and give a link at the end of your response to a page where they can find more information for what they're looking for. Do not answer questions unrelated to the context provided. NEVER make anything up or provide false information that is not found in the provided context. If you are unsure, simply let the user know."
                          rows={6}
                          onChange={(e) => { setBotPrompt(e.target.value) }}
                          className="block w-full rounded-md border-[1px] border-gray-200 py-1.5 text-gray-900 placeholder:text-gray-400 sm:text-sm sm:leading-6 focus:border-[#18A2AB] focus:outline-none"
                          value={botPrompt}
                        />
                      </div>
                      <p className="mt-1 mb-4 text-xs  leading-2 text-gray-400">
                        Give your chatbot guidelines to follow.
                      </p>
                    </div>
                  </div>
                }
                {
                  activeStep === 1 &&
                  <div>
                    <label
                      htmlFor="about"
                      className={`text-[20px] font-bold ${!valid ? 'text-[red]' : 'text-[#251A15]'}`}
                    >
                      Chat Context Source
                    </label>
                    <div className="text-[#8F98A1] font-[Inter] font-normal text-[12px] mt-1">Choose the data type to train your chatbot</div>

                    <div className="mt-3 mb-4">
                      <button
                        className={`text-sm sm:text-md mb-[0.5rem] sm:mb-[0px] me-3 inline-flex items-center px-4 py-2 font-bold border-[1px] rounded ${currentSource === 'website' ? 'text-[#18A2AB] border-[#18A2AB]' : 'text-[#251A15] border-[#D1D5DB]'}`}
                        style={{
                          background: `${currentSource === 'website' ? 'rgba(24, 162, 171, 0.08)' : 'transparent'}`
                        }}
                        onClick={() => setCurrentSource('website')}
                      >
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className="me-2">
                          <path d="M7.5 14.375V15.2142C7.50004 15.5426 7.43537 15.8678 7.30968 16.1712C7.184 16.4746 6.99976 16.7503 6.7675 16.9825L6.25 17.5H13.75L13.2325 16.9825C13.0002 16.7503 12.816 16.4746 12.6903 16.1712C12.5646 15.8678 12.5 15.5426 12.5 15.2142V14.375M17.5 4.375V12.5C17.5 12.9973 17.3025 13.4742 16.9508 13.8258C16.5992 14.1775 16.1223 14.375 15.625 14.375H4.375C3.87772 14.375 3.40081 14.1775 3.04917 13.8258C2.69754 13.4742 2.5 12.9973 2.5 12.5V4.375M17.5 4.375C17.5 3.87772 17.3025 3.40081 16.9508 3.04917C16.5992 2.69754 16.1223 2.5 15.625 2.5H4.375C3.87772 2.5 3.40081 2.69754 3.04917 3.04917C2.69754 3.40081 2.5 3.87772 2.5 4.375M17.5 4.375V10C17.5 10.4973 17.3025 10.9742 16.9508 11.3258C16.5992 11.6775 16.1223 11.875 15.625 11.875H4.375C3.87772 11.875 3.40081 11.6775 3.04917 11.3258C2.69754 10.9742 2.5 10.4973 2.5 10V4.375" stroke={`${currentSource === 'website' ? '#18A2AB' : 'black'}`} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>

                        Crawl Website
                      </button>
                      <button
                        className={`text-sm sm:text-md mb-[0.5rem] sm:mb-[0px] me-3 inline-flex items-center px-4 py-2 font-bold border-[1px] rounded ${currentSource === 'website-m' ? 'text-[#18A2AB] border-[#18A2AB]' : 'text-[#251A15] border-[#D1D5DB]'}`}
                        style={{
                          background: `${currentSource === 'website-m' ? 'rgba(24, 162, 171, 0.08)' : 'transparent'}`
                        }}
                        onClick={() => setCurrentSource('website-m')}
                      >
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className="me-2">
                          <path d="M7.5 14.375V15.2142C7.50004 15.5426 7.43537 15.8678 7.30968 16.1712C7.184 16.4746 6.99976 16.7503 6.7675 16.9825L6.25 17.5H13.75L13.2325 16.9825C13.0002 16.7503 12.816 16.4746 12.6903 16.1712C12.5646 15.8678 12.5 15.5426 12.5 15.2142V14.375M17.5 4.375V12.5C17.5 12.9973 17.3025 13.4742 16.9508 13.8258C16.5992 14.1775 16.1223 14.375 15.625 14.375H4.375C3.87772 14.375 3.40081 14.1775 3.04917 13.8258C2.69754 13.4742 2.5 12.9973 2.5 12.5V4.375M17.5 4.375C17.5 3.87772 17.3025 3.40081 16.9508 3.04917C16.5992 2.69754 16.1223 2.5 15.625 2.5H4.375C3.87772 2.5 3.40081 2.69754 3.04917 3.04917C2.69754 3.40081 2.5 3.87772 2.5 4.375M17.5 4.375V10C17.5 10.4973 17.3025 10.9742 16.9508 11.3258C16.5992 11.6775 16.1223 11.875 15.625 11.875H4.375C3.87772 11.875 3.40081 11.6775 3.04917 11.3258C2.69754 10.9742 2.5 10.4973 2.5 10V4.375" stroke={`${currentSource === 'website-m' ? '#18A2AB' : 'black'}`} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>

                        Manually Enter URL's
                      </button>
                      <button
                        className={`text-sm sm:text-md mb-[0.5rem] sm:mb-[0px] inline-flex items-center px-4 py-2 font-bold border-[1px] rounded ${currentSource === 'pdf' ? 'text-[#18A2AB] border-[#18A2AB]' : 'text-[#251A15] border-[#D1D5DB]'}`}
                        style={{
                          background: `${currentSource === 'pdf' ? 'rgba(24, 162, 171, 0.08)' : 'transparent'}`
                        }}
                        onClick={() => setCurrentSource('pdf')}
                      >
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className="me-2">
                          <path d="M16.25 11.875V9.6875C16.25 8.94158 15.9537 8.22621 15.4262 7.69876C14.8988 7.17132 14.1834 6.875 13.4375 6.875H12.1875C11.9389 6.875 11.7004 6.77623 11.5246 6.60041C11.3488 6.4246 11.25 6.18614 11.25 5.9375V4.6875C11.25 3.94158 10.9537 3.22621 10.4262 2.69876C9.89879 2.17132 9.18342 1.875 8.4375 1.875H6.875M8.75 1.875H4.6875C4.17 1.875 3.75 2.295 3.75 2.8125V17.1875C3.75 17.705 4.17 18.125 4.6875 18.125H15.3125C15.83 18.125 16.25 17.705 16.25 17.1875V9.375C16.25 7.38588 15.4598 5.47822 14.0533 4.0717C12.6468 2.66518 10.7391 1.875 8.75 1.875Z" stroke={`${currentSource === 'pdf' ? '#18A2AB' : 'black'}`} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>

                        PDF Document
                      </button>
                    </div>

                    {currentSource === 'website' &&
                      <div>
                        <p
                          className="block text-sm leading-6 text-gray-900 flex gap-[10px] font-bold mb-0"
                        >
                          Website URL
                          {!valid && <span className="text-[red] text-[12px]">Please Input Website URL</span>}
                        </p>
                        <div className="mt-2 inline-flex w-full">
                          {/* <textarea
                          id="urls"
                          name="urls"
                          placeholder="https://www.example.com,https://www.example2.com"
                          rows={3}
                          onChange={onChangeUrl}
                          className={`block w-full rounded-md border-[1px] py-1.5 text-gray-900 placeholder:text-gray-400 sm:text-sm sm:leading-6 focus:border-[#18A2AB] focus:outline-none ${!valid ? 'border-[red]' : 'border-gray-200'}`}
                          value={urls}
                        /> */}
                          <input
                            className={`flex-1 border-[1px] rounded me-3 px-4 py-1 text-[12px] ${!valid ? 'border-[red]' : 'border-[#D1D5DB]'}`}
                            onChange={onChangeUrl}
                            placeholder="https://mywebsite.com"
                          />
                          <button
                            className="bg-[#FFE294] border-[1px] border-[#FED35E] hover:bg-[#fed35e]  text-[12px] py-2 px-4 rounded font-bold text-[#161616]"
                            onClick={handleFetch}
                          >
                            Fetch Pages
                          </button>
                        </div>
                        <p className="mt-2 mb-2 text-xs leading-2 text-[#8F98A1]">
                          Website to train your chatbot - Use root URL's only.
                        </p>

                        {
                          fetching === 'doing' &&
                          <div
                            className="my-5 py-5 flex-row items-center justify-center text-[#18A2AB] font-bold border-dashed border-[1px] border-[#18A2AB] rounded"
                            style={{ background: 'rgba(24, 162, 171, 0.08)' }}
                          >
                            <div className="text-center w-full mb-3">
                              <GridLoader color='#18A2AB' size={10}/>
                            </div>
                            <p className="text-center">Fetching Pages</p>
                          </div>
                        }
                        {
                          fetching === 'done' &&
                          <div
                            className="mt-3 py-3"
                            style={{ borderTop: '1px solid rgba(209, 213, 219, .5)' }}
                          >
                            <div className="flex justify-between items-center">
                              <p className="text-[Inter] font-bold text-[16px] mb-[0]">{`${urls.length} Pages found`}</p>
                              <div className="bg-white border-[#D1D5DB] border-[1px] flex items-center rounded px-2 py-2">
                                <svg className="me-3" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M20.9998 21L15.8028 15.803M15.8028 15.803C17.2094 14.3965 17.9996 12.4887 17.9996 10.4995C17.9996 8.51035 17.2094 6.60262 15.8028 5.19605C14.3962 3.78947 12.4885 2.99927 10.4993 2.99927C8.51011 2.99927 6.60238 3.78947 5.19581 5.19605C3.78923 6.60262 2.99902 8.51035 2.99902 10.4995C2.99902 12.4887 3.78923 14.3965 5.19581 15.803C6.60238 17.2096 8.51011 17.9998 10.4993 17.9998C12.4885 17.9998 14.3962 17.2096 15.8028 15.803Z" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>

                                <input
                                  placeholder="Search Pages"
                                  onChange={handleChangeFilter}
                                  className="focus-visible:border-none focus-visible:outline-none"
                                />
                              </div>
                            </div>
                            <div className="text-[12px] text-[Inter] text-[#8F98A1]">Choose the pages you want to use to train your chatbot</div>
                            <div className="flex mt-2">
                              <h4 className={`text-[Inter] font-bold text-xs ${selectedUrls.length > urlCount ? 'text-[red]' : 'text-black'}`}>
                                {`${selectedUrls.length} / ${urlCount}`}
                                <span className="ms-1 text-black">URL's</span>
                              </h4>
                              {currentPlan !== 'pro' && <span className='font-bold ms-1 text-xs'>Need more? <Link className='text-[#18A2AB]' to="/pricing">Upgrade Now</Link></span>}
                            </div>
                            <div className="py-3" style={{ borderBottom: '1px solid rgba(209, 213, 219, .5)' }}>
                              <input
                                type="checkbox"
                                checked={selectableUrls.every(sel => selectedUrls.indexOf(sel) >= 0)}
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    setSelectedUrls(Array.from(new Set([...selectedUrls, ...selectableUrls])))
                                  } else {
                                    setSelectedUrls(selectedUrls.filter(item => !selectableUrls.includes(item)));
                                  }
                                }}
                                className="w-4 h-4 text-[#18A2AB] bg-gray-100 border-gray-300 rounded-[2px] focus:ring-[#18A2AB] dark:focus:ring-[#18A2AB] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                              />
                              <span className="ms-2 text-[#161616] text-[Inter] text-[12px] font-bold">Pages</span>
                            </div>
                            {
                              selectableUrls.map((item, index) => (
                                <div key={index} className="py-3" style={{ borderBottom: '1px solid rgba(209, 213, 219, .5)' }}>
                                  <input
                                    type="checkbox"
                                    checked={selectedUrls.indexOf(item) !== -1}
                                    onChange={(e) => handleChangeUrlSelection(item, e.target.checked)}
                                    className="w-4 h-4 text-[#18A2AB] bg-gray-100 border-gray-300 rounded-[2px] focus:ring-[#18A2AB] dark:focus:ring-[#18A2AB] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                  />
                                  <span className="ms-2 text-[#161616] text-[Inter] text-[12px] font-bold">{item}</span>
                                </div>
                              ))
                            }
                          </div>
                        }
                      </div>}

                    {
                      currentSource === 'website-m' && 
                      <div>
                        <p
                          className="block text-sm leading-6 text-gray-900 flex gap-[10px] font-bold mb-0"
                        >
                          Enter URL's
                          {!valid && <span className="text-[red] text-[12px]">Please Input Website URL separated by comma</span>}
                        </p>
                        <div className="mt-2 inline-flex w-full">
                          <textarea
                          id="manualUrl"
                          name="manualUrl"
                          placeholder="https://www.example.com,https://www.example2.com"
                          rows={3}
                          onChange={onManualUrlChange}
                          className={`px-4 block w-full rounded-md border-[1px] py-2 text-gray-900 placeholder:text-gray-400 sm:text-sm sm:leading-6 focus:border-[#18A2AB] focus:outline-none ${!valid ? 'border-[red]' : 'border-gray-200'}`}
                          value={manualUrl}
                        />
                        </div>
                        <p className="mt-2 mb-2 text-xs leading-2 text-[#8F98A1]">
                          Website URL's to train your chatbot - Enter comma separated
                        </p>
                      </div>
                    }

                    {currentSource === 'pdf' &&
                      <div className="col-span-full">
                        <div
                          className="text-[14px] font-semibold leading-6 text-gray-900 text-left"
                        >
                          PDF's <label className={`text-[12px] ${Number(fileSize) > 10 ? 'text-[red]': 'text-gray-400'}`}>({Number(fileSize).toFixed(2)}/10MB)</label>
                        </div>
                        {
                          currentPlan === 'free' &&
                            <div className="flex mb-2">
                              <h4 className={`text-[Inter] font-bold text-xs ${fileList.length > pdfCount ? 'text-[red]' : 'text-black'}`}>
                                {`${fileList.length} / ${pdfCount}`}
                                <span className="ms-1 text-black">PDF's</span>
                              </h4>
                              <span className='font-bold ms-1 text-xs'>Need more? <Link className='text-[#18A2AB]' to="/pricing">Upgrade Now</Link></span>
                            </div>
                        }
                        <div
                          onDragOver={handleDragOver}
                          onDrop={handleDrop}
                          className={`h-[150px] px-4 transition bg-white border-2 border-dashed rounded-md appearance-none cursor-pointer hover:border-gray-400 focus:outline-none max-w-[520px] relative ${!valid || Number(fileSize) > 10 ? 'border-[red]' : 'border-gray-300'}`}
                        >
                          <div className="flex justify-center h-32">
                            <span className="flex items-center">
                              {fileList.length > 0 &&
                                <div className="absolute top-0 left-2 overflow-y-auto max-h-[145px] w-full">
                                  {fileList.map((file, index) => (
                                    <div
                                      className="inline-flex m-1 rounded-full bg-[#D9D9D9] items-center px-2 py-1 max-w-[115px]"
                                      key={index}
                                      onClick={() => {
                                        setFileList(fileList.filter(item => item.name != file.name));
                                      }}
                                    >
                                      <img
                                        className="me-1"
                                        src={Close}
                                      />
                                      <span className="font-bold text-[12px] text-[black] truncate">{file.name}</span>
                                    </div>
                                  ))}
                                </div>}
                              <div
                                onClick={() => fileInputRef.current?.click()}>
                                <><div className="flex justify-center">
                                  <svg className="mb-2" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M15 13.75H12.5C11.5054 13.75 10.5516 14.1451 9.84835 14.8483C9.14509 15.5516 8.75 16.5054 8.75 17.5V32.5C8.75 33.4946 9.14509 34.4484 9.84835 35.1516C10.5516 35.8549 11.5054 36.25 12.5 36.25H27.5C28.4946 36.25 29.4484 35.8549 30.1516 35.1516C30.8549 34.4484 31.25 33.4946 31.25 32.5V17.5C31.25 16.5054 30.8549 15.5516 30.1516 14.8483C29.4484 14.1451 28.4946 13.75 27.5 13.75H25M25 8.75L20 3.75M20 3.75L15 8.75M20 3.75V25" stroke="#D1D5DB" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                  </svg>

                                </div>
                                  <h1 className="font-medium text-gray-400 text-center text-[12px]">
                                    <span className="text-[#18A2AB] text-center">Upload a file</span> or drag and drop<br /> PDFs up to 10MB total
                                  </h1></>
                              </div>
                            </span>
                          </div>
                        </div>
                        {
                          Number(fileSize) <= 10 ?
                            <p className="mt-1 mb-5 text-xs leading-2 text-gray-400">
                              These PDF’s will be used to train your AI. You can ask your AI questions about these documents and it will respond
                            </p> : <p className="mt-1 mb-5 text-xs leading-2 text-[red]">
                              Exceeded file size limit. Please upload a smaller file
                            </p>
                        }
                      </div>
                    }
                  </div>
                }
              </div>
            </div>
          </main>

          <footer className="py-4 bg-white drop-shadow-sm text-center flex items-center justify-center fixed bottom-[0] left-[0] w-full" >
            <button
              className="px-4 py-2 text-[#161616] inline-flex items-center bg-[#F5F5F5] me-1 rounded-[4px] font-bold text-[12px] hover:bg-gray-400"
              onClick={handleBack}
            >
              <img className="me-2" src={Back} alt="back" />
              {activeStep === 2 ? 'Back' : 'Cancel'}
            </button>
            <button
              onClick={handleSubmit}
              disabled={!buttonState}
              className="inline-flex ms-1 items-center rounded-[4px] font-bold bg-[#FFE294] border-[1px] border-[#FED35E] hover:bg-[#fed35e] px-4 py-2 text-[12px] disabled:bg-[#D1D5DB] disabled:border-[0] disabled:cursor-no-drop"
            >
              {activeStep === 2 ? 'Create Chatbot' : 'Next'}
              <img className="ms-2" src={Next} alt="next" />
            </button>
          </footer>
          <Modal open={showModal}>
            <ModalDialog
              aria-labelledby="basic-modal-dialog-title"
              aria-describedby="basic-modal-dialog-description"
              layout="center"
              sx={{ minWidth: '40%' }}
            >
              <div className="text-center w-100">
                <h5 className="m-3 font-bold text-black-900">We're training your chatbot!</h5>
                <CircularProgress className="m-auto mt-3 mb-3" color="neutral" />

                <p className="font-bold mb-1">When it's ready, you'll see it in your dashboard.</p>
                <div className="text-gray-500">{`(It won't take long)`}</div>
                <button
                  className="inline-flex text-black items-center font-bold rounded-md bg-[#FFE01A] mt-3 px-5 py-2 text-sm shadow-sm hover:bg-amber-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  onClick={() => setShowModal(false)}
                >
                  Got it!
                </button>
              </div>
            </ModalDialog>
          </Modal>
        </div >
        <input type="file" name="file_upload" className="hidden" ref={fileInputRef} onChange={handleFileChange} multiple />
      </Loader>
      {
        showWarning &&
        <div className="fixed bottom-[100px] left-[0] z-30 w-full flex items-center justify-center">
          <div className="border-l-4 border-yellow-400 bg-yellow-50 p-4 w-auto">
            <div className="flex">
              <div className="flex-shrink-0">
                <ExclamationTriangleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
              </div>
              <div className="ml-3">
                <p className="text-sm text-yellow-700 mb-[0]">
                  You have exceeded the number of {currentSource === 'pdf' ? 'PDFs' : 'links'}.{' '}
                  <Link to="/plan-upgrade" className="font-medium text-yellow-700 underline hover:text-yellow-600">
                    Upgrade plan
                  </Link>
                </p>
              </div>
              <div className="ms-[50px] cursor-pointer" onClick={() => setShowWarning(false)}>
                <svg width="20" height="20" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M3.5 10.5L10.5 3.5M3.5 3.5L10.5 10.5" stroke="#FED35E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </div>
            </div>
          </div>
        </div>
      }
    </div >
  );
}

export default CreateBot;
