import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';

interface typesMobileDetail {
    open: boolean;
    close: Function;
    deleteHistory: Function;
    children: any;
}

export default function MobileHistoryDetail({ open, close, deleteHistory, children }: typesMobileDetail) {

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => close()}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <div className="relative flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                    <div className='fixed top-0 p-4 border-b-[#D1D5DB] border-b-[1px] w-full flex items-center justify-between z-10 bg-white'>
                        <button className='flex text-[black] font-bold text-xs font-[Inter] items-center justify-center' onClick={() => close()}>
                            <svg width="7" height="11" viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg" className='me-3'>
                                <path d="M5.84375 10.1875L1.15625 5.5L5.84375 0.8125" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                            Back
                        </button>
                        <button
                            className='w-[38px] h-[38px] hover:bg-[#C81212]/[.1] bg-white hover:stroke-[#C81212] hover:border-[#C81212]/[.5] border-[#E7E7E7] stroke-[#251A15] border-[1px] rounded-[4px] flex items-center justify-center'
                            onClick={() => deleteHistory()}
                        >
                            <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.05933 5.88426L8.8056 12.3961M5.2944 12.3961L5.04067 5.88426M12.3505 3.5617C12.6013 3.59933 12.8507 3.63912 13.1 3.68181M12.3505 3.5617L11.5673 13.6066C11.5354 14.0155 11.3481 14.3975 11.043 14.6762C10.7379 14.9548 10.3375 15.1095 9.92173 15.1094H4.17827C3.76254 15.1095 3.36208 14.9548 3.05698 14.6762C2.75189 14.3975 2.56463 14.0155 2.53267 13.6066L1.74947 3.5617M12.3505 3.5617C11.5042 3.43546 10.6534 3.33965 9.8 3.27446M1.74947 3.5617C1.49867 3.5986 1.24933 3.6384 1 3.68109M1.74947 3.5617C2.59583 3.43546 3.44657 3.33965 4.3 3.27446M9.8 3.27446V2.6117C9.8 1.75792 9.13267 1.04596 8.26733 1.01919C7.45598 0.993604 6.64402 0.993604 5.83267 1.01919C4.96733 1.04596 4.3 1.75865 4.3 2.6117V3.27446M9.8 3.27446C7.9694 3.13487 6.1306 3.13487 4.3 3.27446" stroke="inherit" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </button>
                    </div>
                    <div className="relative mt-[90px] flex-1 px-4 sm:px-6 z-0">
                        {children}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
