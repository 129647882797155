import { Menu, Transition } from "@headlessui/react";
import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";
import { PlusIcon } from "@heroicons/react/20/solid";
import LinearProgress from '@mui/joy/LinearProgress';
import { loadStripe } from '@stripe/stripe-js';
import Stripe from "stripe";

import axios from 'axios'
import moment from "moment-timezone";
import { useEffect, useState } from "react";
import { Fragment } from "react";
import { Link } from "react-router-dom";
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog'
import Avatar from "@mui/joy/Avatar/Avatar";
import "./App.css";

import Loader from "./components/Loader";
import 'bootstrap/dist/css/bootstrap.min.css';

const publicKey = process.env.REACT_APP_STRIPE_PUBLISH_KEY;
const secretKey = process.env.REACT_APP_STRIPE_SECRET_KEY;
const stripeCheckoutSession = new Stripe(secretKey as string, { apiVersion: '2022-11-15' });

function classNames<T extends string>(...classes: T[]): string {
  return classes.filter(Boolean).join(" ");
}

interface typeChatInfo {
  bot_id: number;
  instance_name: string;
  bot_name: string;
  chat_name: string;
  chats: Array<any>;
  complete: boolean;
  created: string;
  avatar: string;
}

export default function App() {

  const [chatInfos, setChatInfos] = useState<typeChatInfo[]>([])
  const [totalCount, setTotalCount] = useState(1);
  const [messageCount, setMessageCount] = useState(50); //Initial state
  const [additionalConnect, setAdditionalConnect] = useState(0);
  const [leftCount, setLeftCount] = useState(0);
  const [deleteModal, setDeleteModal] = useState(false)
  const [deleteBotId, setDeleteBotId] = useState(0)
  const [loading, setLoading] = useState<boolean>(false);
  const [currentPlan, setCurrentPlan] = useState('free');
  const [buyModal, setBuyModal] = useState(false);


  useEffect(() => {
    setLoading(true);
    Promise.all([
      getTotalCount(),
      getChatInfos()
    ]).catch(err => {
      console.log('err:', err);
    }).finally(() => setLoading(false));
  }, [])

  const getChatInfos = () => {
    return new Promise((resolve, reject) => {
      const path = process.env.REACT_APP_BASE_URL + "/api/getChatInfos";
      const user_email = localStorage.getItem('email')
      const request = {
        email: user_email
      };

      axios.post(path, request, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }).then((res) => {
        let _chatInfos = res.data.chats;
        _chatInfos.sort((a: typeChatInfo, b: typeChatInfo) => a.bot_id - b.bot_id);
        setChatInfos(_chatInfos);
        resolve(true);
      }).catch((err) => {
        console.log(err)
        setChatInfos([]);
        reject();
      });
    });
  }

  const getTotalCount = () => {
    return new Promise((resolve, reject) => {
      const path = process.env.REACT_APP_BASE_URL + "/api/getSubscription";
      const user_email = localStorage.getItem('email')
      const request = {
        email: user_email
      };
      axios.post(path, request, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }).then((res) => {
        console.log('data:', res.data);
        const count = res.data.detail.botCount;
        setTotalCount(parseInt(count));
        setMessageCount(res.data.detail.monthMessage);
        setLeftCount(res.data.messageLeft);
        setAdditionalConnect(res.data.additionalConnect);
        setCurrentPlan(res.data.type);
        resolve(true);
      }).catch((err) => {
        console.log(err)
        setTotalCount(1);
        setMessageCount(50);
        setLeftCount(50);
        setCurrentPlan('free');
        setAdditionalConnect(0);
        reject();
      });
    });
  }

  const onDeleteBot = async (id: number) => {
    const path = process.env.REACT_APP_BASE_URL + "/api/botDelete";
    const user_email = localStorage.getItem('email')
    const request = {
      email: user_email,
      bot_id: id
    }

    setLoading(true);
    axios.post(path, request, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    }).then((res) => {
      console.log(res);
      getChatInfos();
    }).catch(err => {
      console.log(err)
      getChatInfos();
    }).finally(() => setLoading(false));
  }

  const handlePurchase = async (value: Number) => {
    let priceId: any = '';
    if (value === 13) {
      priceId = process.env.REACT_APP_ADDITIONAL_13;
    } else if (value === 19) {
      priceId = process.env.REACT_APP_ADDITIONAL_19;
    } else if (value === 49) {
      priceId = process.env.REACT_APP_ADDITIONAL_49;
    } else if (value === 99) {
      priceId = process.env.REACT_APP_ADDITIONAL_99;
    } else {
      priceId = process.env.REACT_APP_ADDITIONAL_499;
    }

    const email = localStorage.getItem('email');
    let customer;
    const existingCustomers = await stripeCheckoutSession.customers.list({
      email: email as string
    });

    if (existingCustomers.data.length > 0) {
      // customer already exists
      customer = existingCustomers.data[0];
    } else {
      // create a new customer
      customer = await stripeCheckoutSession.customers.create({
        email: email as string,
      });
    }

    const checkoutSession = await stripeCheckoutSession.checkout.sessions.create({
      customer: customer.id,
      mode: "payment",
      line_items: [{
        price: priceId,
        quantity: 1,
      }],
      success_url: 'https://app.chatsmith.ai/#',
      cancel_url: 'https://app.chatsmith.ai/#',
      automatic_tax: { enabled: true },
      customer_update: { address: 'auto' }
    });

    const stripePromise = loadStripe(publicKey || "");
    const stripe = await stripePromise;

    await stripe?.redirectToCheckout({
      sessionId: checkoutSession.id
    });
  };

  return (
    <div className="chatsmith">
      {/* <iframe src="http://localhost:3000/#/embed?pb=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6InlldmhlbjA2MDhAZ21haWwuY29tIiwiYm90X25hbWUiOiJiNjllODFmMS04ZjU0LTExZWUtODAyMS05MDFiMGVhNDljM2MiLCJjdXN0b21lcl9pZCI6ImN1c19PSW1TeGw0SnVFSkgySiIsInN1YnNjcmlwdGlvbl9pZCI6InN1Yl8xT0NHMlBDUFJxMHB0RnNySEN3V0g3UlQifQ.buwWYH9Oz3mxzBa1-w7P5j77Hl6jxUEIYVmf2TSRf6Q" width="600" height="450"></iframe> */}
      <div className="py-10 bg-[#F5F8FA] min-h-[calc(100vh-64px)]">
        <header>
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 ">
            <div className="flex justify-between items-center">
              <div>
                <div className="xs:block sm:block md:flex lg:flex xl:flex items-center">
                  <h1 className="text-[24px] font-bold leading- tight tracking-tight text-gray-900 mb-0 me-3">
                    Chatbots
                  </h1>
                  <p className="text-[20px] font-bold text-xs mb-0 text-[#8F98A2] mt-1">
                    {chatInfos.length} of {totalCount} Chats{" "}
                    <span className="text-xs text-gray-500 font-normal">
                      ({totalCount - chatInfos.length} remaining)
                    </span>
                  </p>
                </div>
                {
                  currentPlan !== 'pro' &&
                  <div>
                    {(totalCount === 1 || chatInfos.length >= totalCount) && <p className="mt-1 text-sm text-gray-500 mb-2">
                      Need more bots?{" "}
                      <Link
                        className="text-[#18A2AB] ml-1 font-semibold cursor-pointer text-decoration-none"
                        to="/pricing"
                      // onClick={onUpgradeNow}
                      >
                        Upgrade now
                      </Link>
                    </p>}
                  </div>
                }
              </div>
              {
                chatInfos.length > 0 && <div className="flex items-center justify-between border-b border-gray-100">
                  <div className="flex gap-2">

                    {(chatInfos.length < totalCount) &&
                      <Link to="/create" className="inline-flex text-black items-center font-bold rounded-md bg-[#FFE294] border-[1px] border-[#FED35E] hover:bg-[#fed35e] px-3 py-2 text-sm no-underline">
                        <PlusIcon
                          className="-ml-0.5 mr-1.5 h-5 w-5"
                          aria-hidden="true"
                        />
                        Create Chatbot
                      </Link>
                    }
                  </div>
                </div>
              }
            </div>
          </div>
        </header>
        <main className="mx-auto max-w-7xl py-10 sm:px-6 lg:px-8 px-4 relative">
          <Loader loading={loading}>
            <div className="relative">
              {/* Show below if no bots created */}
              {chatInfos.length === 0 && <div className="text-center">
                <h3 className="mt-2 text-[28px] font-bold">
                  Build your chatbot
                </h3>
                <p className="mt-1 text-sm text-gray-500">
                  Get started by creating your first AI chatbot!
                </p>
                <div className="mt-6">
                  <Link to="/create" className="inline-flex text-black items-center font-bold rounded-md bg-[#FFE294] border-[1px] border-[#FED35E] hover:bg-[#fed35e] px-3 py-2 text-sm no-underline  hover:bg-[#fed35e]">
                    <PlusIcon
                      className="-ml-0.5 mr-1.5 h-5 w-5"
                      aria-hidden="true"
                    />
                    Create Chatbot
                  </Link>
                </div>
              </div>}
              {/* End show if no bots created  */}

              {
                <ul className="divide-y divide-gray-100 p-0">
                  {chatInfos.map((bot: typeChatInfo, index: number) => {
                    return (
                      <li
                        key={index}
                        className={`flex items-center justify-between p-4 mb-4 bg-white rounded-[15px] border border-gray-100 ${index >= totalCount ? 'opacity-50' : 'opacity-100'} `}
                      >
                        <div className="min-w-0">
                          <div className="flex items-center">
                            <Avatar
                              alt={bot.instance_name}
                              sx={{
                                marginRight: '1rem'
                              }}
                              src={`data:image/png;base64,${bot.avatar}`}
                            />
                            <div>
                              <div className="flex gap-[10px] items-center">
                                <label className="text-[16px] font-bold leading-6 text-gray-900">
                                  {bot.instance_name}
                                </label>
                                {bot.complete ? <label className="text-[10px] px-[8px] py-[2px] rounded-[4px] bg-[#F0FDF4] text-[#16803D] border-[1px] border-[#CCEBD4]">Published</label> : <label className="text-[10px] px-[8px] py-[2px] rounded-[4px] bg-[#FEFCE8] text-[#854D0F] border-[1px] border-[#F4E6C1]">Training</label>}
                              </div>

                              <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                                <p className="mb-0">Updated: {`${moment.utc(bot.created).local().format('dddd, DD MMM YYYY HH:mm:ss')} ${moment.tz(moment.tz.guess()).format('z')}`}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="flex flex-none items-center gap-x-4">
                          {bot.complete ?
                            <>
                              <Link to={'/edit-bot/' + bot.bot_name} className="hidden sm:flex rounded-md bg-white px-2.5 py-1.5 text-sm fon-semibold text-gray-900  ring-1 ring-inset ring-[#E7E7E7] hover:bg-gray-50 items-center gap-[5px] text-decoration-none" onClick={e => {
                                if (index >= totalCount) e.preventDefault();
                              }}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                  <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                                </svg>
                                <span className="font-bold">Edit</span>
                              </Link>
                              <Link to={'/chatbot/' + bot.bot_name} className="hidden sm:flex rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900  ring-1 ring-inset ring-[#E7E7E7] hover:bg-gray-50 items-center gap-[5px] text-decoration-none" onClick={e => {
                                if (index >= totalCount) e.preventDefault();
                              }}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                  <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                                  <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                </svg>
                                <span className="font-bold">View</span>
                              </Link>
                              <button className="hidden sm:flex rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900  ring-1 ring-inset ring-[#E7E7E7] hover:bg-gray-50 items-center gap-[5px] text-decoration-none cursor-pointer" onClick={() => { if (index >= totalCount) return; setDeleteModal(true); setDeleteBotId(bot.bot_id) }} >
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                  <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                </svg>
                              </button>
                            </> : <>
                              <button className="hidden sm:flex rounded-md bg-white px-2.5 py-1.5 text-sm fon-semibold text-gray-400  ring-1 ring-inset ring-gray-300 hover:bg-gray-50 items-center gap-[5px] text-decoration-none text-gray-400">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                  <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                                </svg>
                                <span className="font-bold">Edit</span>
                              </button>
                              <button className="hidden sm:flex rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-400  ring-1 ring-inset ring-gray-300 hover:bg-gray-50 items-center gap-[5px] text-decoration-none">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                  <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                                  <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                </svg>
                                <span className="font-bold">View</span>
                              </button>
                              <button className="hidden sm:flex rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-400  ring-1 ring-inset ring-gray-300 hover:bg-gray-50 items-center gap-[5px] text-decoration-none cursor-pointer" disabled>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                  <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                </svg>
                              </button>
                            </>}

                          <Menu as="div" className="relative block sm:hidden flex-none">
                            <Menu.Button className="-m-2.5 block p-2.5 text-gray-500 hover:text-gray-900">
                              <span className="sr-only">Open options</span>
                              <EllipsisVerticalIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </Menu.Button>
                            <Transition
                              as={Fragment}
                              enter="transition ease-out duration-100"
                              enterFrom="transform opacity-0 scale-95"
                              enterTo="transform opacity-100 scale-100"
                              leave="transition ease-in duration-75"
                              leaveFrom="transform opacity-100 scale-100"
                              leaveTo="transform opacity-0 scale-95"
                            >
                              <Menu.Items className="absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                                <Menu.Item>
                                  {({ active }) => (
                                    bot.complete ?
                                      <Link
                                        to={"/chatbot/" + bot.bot_name}
                                        className={classNames(
                                          active ? "bg-gray-50" : "",
                                          "px-3 py-1 text-sm leading-6 text-gray-900 block sm:hidden no-underline"
                                        )}
                                        onClick={e => {
                                          if (index >= totalCount) e.preventDefault();
                                        }}
                                      >
                                        Preview
                                        <span className="sr-only">, {bot.chat_name}</span>
                                      </Link> : <button
                                        className={classNames(
                                          active ? "bg-gray-50" : "",
                                          "px-3 py-1 text-sm leading-6 text-gray-900 block sm:hidden"
                                        )}
                                        disabled
                                      >
                                        Preview
                                        <span className="sr-only">, {bot.chat_name}</span>
                                      </button>
                                  )}
                                </Menu.Item>
                                <Menu.Item>
                                  {({ active }) => (
                                    bot.complete ?
                                      <Link
                                        to={'/edit-bot/' + bot.bot_name}
                                        className={classNames(
                                          active ? "bg-gray-50" : "",
                                          "block px-3 py-1 text-sm leading-6 text-gray-900 block sm:hidden no-underline"
                                        )}
                                        onClick={e => {
                                          if (index >= totalCount) e.preventDefault();
                                        }}
                                      >
                                        Edit
                                        <span className="sr-only">, {bot.chat_name}</span>
                                      </Link> : <button
                                        className={classNames(
                                          active ? "bg-gray-50" : "",
                                          "px-3 py-1 text-sm leading-6 text-gray-900 block sm:hidden"
                                        )}
                                        disabled
                                      >
                                        Edit
                                        <span className="sr-only">, {bot.chat_name}</span>
                                      </button>
                                  )}
                                </Menu.Item>
                                <Menu.Item>
                                  {({ active }) => (
                                    bot.complete ?
                                      <button
                                        onClick={() => { if (index >= totalCount) return; setDeleteModal(true); setDeleteBotId(bot.bot_id) }}
                                        className={classNames(
                                          active ? "bg-gray-50" : "",
                                          "block px-3 py-1 text-sm leading-6 text-gray-900 block sm:hidden cursor-pointer no-underline"
                                        )}
                                      >
                                        Delete
                                        <span className="sr-only">, {bot.chat_name}</span>
                                      </button> : <button
                                        className={classNames(
                                          active ? "bg-gray-50" : "",
                                          "px-3 py-1 text-sm leading-6 text-gray-900 block sm:hidden"
                                        )}
                                        disabled
                                      >
                                        Delete
                                        <span className="sr-only">, {bot.chat_name}</span>
                                      </button>
                                  )}
                                </Menu.Item>
                              </Menu.Items>
                            </Transition>
                          </Menu>
                        </div>
                      </li>
                    );
                  }
                  )}
                </ul>
              }
              <div>
                <p className="font-[Inter] text-lg text-[#8F98A1] font-bold">
                  Account Usage
                </p>
                <LinearProgress determinate value={(leftCount + additionalConnect) === 0 ? 100 : (messageCount - leftCount) * 100 / (messageCount + additionalConnect)} size="lg" sx={{ color: '#18A2AB' }} />
                <p className="font-[Inter] mt-3 mb-0 font-bold text-[#251C15] text-sm">{`${messageCount - leftCount} /  ${messageCount + additionalConnect} credits used`}</p>
                <p className="font-[Inter] mt-1 mb-2 font-semibold text-xs text-[#8F98A1]">Credit is a representation of each message. Each model consumes different amount of credits per message. Please check that under the model dropdown in bot settings.</p>
                {/* <p className="font-[Inter] text-[#8f98A1] text-sm font-bold">Need more? <span><Link className="text-[#18A2AB] no-underline" to="/pricing">Upgrade now</Link></span></p> */}
                <div className="flex justify-left items-center">
                  <button className="rounded bg-[#ffe294] border-[1px] border-[#fed35e] font-[Inter] font-bold text-xs px-4 py-2 me-2" onClick={() => setBuyModal(true)}>Buy Credits</button>
                  <Link className="no-underline border-[#e7e7e7] border-[1px] rounded bg-white font-bold text-xs px-4 py-2 text-black" to="/pricing">Upgrade Plan</Link>
                </div>

              </div>
            </div>
          </Loader>
          <Modal aria-labelledby="modal-title"
            aria-describedby="modal-desc"
            open={deleteModal}
            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <ModalDialog
              sx={{ borderRadius: '20px' }}
            >
              <h1 className="text-center text-black text-[20px] font-bold mt-[30px] mx-[50px]">Are you sure you want to</h1>
              <h1 className="text-center text-black text-[20px] font-bold mb-[20px] mx-[20px]">delete this chat?</h1>
              <div className="flex justify-center gap-[20px] mb-[30px]">
                <button className="text-[14px] text-black font-bold px-[35px] py-[12px] bg-gray-200 hover:bg-gray-400 rounded-[4px]" onClick={() => { setDeleteModal(false) }}>Cancel</button>
                <button className="text-[14px] text-black font-bold px-[35px] py-[12px] bg-[#FFE294] border-[1px] border-[#FED35E] hover:bg-[#fed35e] rounded-[4px]" onClick={() => { onDeleteBot(deleteBotId); setDeleteModal(false) }}>Delete</button>
              </div>
            </ModalDialog>
          </Modal>
          <Modal
            open={buyModal}
            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          >
            <ModalDialog>
              <div className="rounded-[20px] flex justify-center items-center flex-col p-[10px] md:p-[30px] sm:w-[700px] relative">
                <button className="absolute right-[10px] top-[10px]" onClick={() => setBuyModal(false)}>
                  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="18" height="18" rx="4" fill="#F7F7F8" />
                    <path d="M4.5 13.5L13.5 4.5M4.5 4.5L13.5 13.5" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </button>
                <p className="text-[#1798a1] font-[Inter] text-sm font-semibold">Already subscribed to a plan?</p>
                <h3 className="my-3 text-black font-[Inter] text-[28px] font-bold text-center">Buy Additional Credits</h3>
                <p className="font-[Inter] text-xs text-[#8f98a1] font-medium text-center sm:max-w-[450px]">Top up your account with more message credits. Any credits you purchase separate from your plan will rollover to the following month if unused</p>

                <div className="grid grid-cols-2 md:grid-cols-5 gap-2">
                  <div className="flex flex-col justify-center items-center">
                    <button
                      className="font-[Inter] w-full rounded text-[#18A2AB] border-[#18A2AB] px-3 py-2 border-[1px] hover:bg-[#18a2ab] hover:text-white whitespace-nowrap"
                      onClick={() => handlePurchase(13)}
                    >
                      Buy 1,000
                    </button>
                    <p className="my-2 font-[Inter] font-bold text-xs">$13</p>
                  </div>
                  <div className="flex flex-col justify-center items-center">
                    <button
                      className="font-[Inter] w-full rounded text-[#18A2AB] border-[#18A2AB] px-3 py-2 border-[1px] hover:bg-[#18a2ab] hover:text-white whitespace-nowrap"
                      onClick={() => handlePurchase(19)}
                    >
                      Buy 2,000
                    </button>
                    <p className="my-2 font-[Inter] font-bold text-xs">$19</p>
                  </div>
                  <div className="flex flex-col justify-center items-center">
                    <button
                      className="font-[Inter] w-full rounded text-[#18A2AB] border-[#18A2AB] px-3 py-2 border-[1px] hover:bg-[#18a2ab] hover:text-white whitespace-nowrap"
                      onClick={() => handlePurchase(49)}
                    >
                      Buy 5,000
                    </button>
                    <p className="my-2 font-[Inter] font-bold text-xs">$49</p>
                  </div>
                  <div className="flex flex-col justify-center items-center">
                    <button
                      className="font-[Inter] w-full rounded text-[#18A2AB] border-[#18A2AB] px-3 py-2 border-[1px] hover:bg-[#18a2ab] hover:text-white whitespace-nowrap"
                      onClick={() => handlePurchase(99)}
                    >
                      Buy 10,000
                    </button>
                    <p className="my-2 font-[Inter] font-bold text-xs">$99</p>
                  </div>
                  <div className="flex flex-col justify-center items-center col-span-2 md:col-span-1">
                    <button
                      className="font-[Inter] w-full rounded text-[#18A2AB] border-[#18A2AB] px-3 py-2 border-[1px] hover:bg-[#18a2ab] hover:text-white whitespace-nowrap"
                      onClick={() => handlePurchase(499)}
                    >
                      Buy 50,000
                    </button>
                    <p className="my-2 font-[Inter] font-bold text-xs">$499</p>
                  </div>
                </div>
              </div>
            </ModalDialog>
          </Modal>
        </main>
      </div>
    </div>
  );
}
