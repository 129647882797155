import React, { useState, useEffect, useRef } from "react";
import axios from 'axios'
import { v4 as uuidv4 } from "uuid";
import Avatar from '@mui/joy/Avatar/Avatar';
import javascript from 'highlight.js/lib/languages/javascript';
import highlight from 'highlight.js';
import { useLocation } from 'react-router-dom';
import socket from "../socket";
import "../App.css";

import './Embed.css';

highlight.registerLanguage('javascript', javascript)

interface Message {
  id: string;
  content: string;
  sender: string;
}

interface ChatMessageProps {
  message: Message;
  botName: string;
  avatar: string;
  generating: boolean;
}

interface typeChatInfo {
  instance_name: string;
  bot_id: number;
}

const LoadingIcon = () => (
  <svg
    style={{
      margin: "0",
      display: "block",
      shapeRendering: "auto",
      padding: "7px",
      width: "60px",
    }}
    width="44px"
    height="44px"
    viewBox="0 0 100 100"
    preserveAspectRatio="xMidYMid"
  >
    <circle cx="23" cy="57.5" r="9" fill="#85a2b6">
      <animate
        attributeName="cy"
        calcMode="spline"
        keySplines="0 0.5 0.5 1;0.5 0 1 0.5;0.5 0.5 0.5 0.5"
        repeatCount="indefinite"
        values="57.5;42.5;57.5;57.5"
        keyTimes="0;0.25;0.5;1"
        dur="0.8695652173913042s"
        begin="-0.43478260869565216s"
      ></animate>
    </circle>{" "}
    <circle cx="50" cy="57.5" r="9" fill="#bbcedd">
      <animate
        attributeName="cy"
        calcMode="spline"
        keySplines="0 0.5 0.5 1;0.5 0 1 0.5;0.5 0.5 0.5 0.5"
        repeatCount="indefinite"
        values="57.5;42.5;57.5;57.5"
        keyTimes="0;0.25;0.5;1"
        dur="0.8695652173913042s"
        begin="-0.2898550724637681s"
      ></animate>
    </circle>{" "}
    <circle cx="77" cy="57.5" r="9" fill="#dce4eb">
      <animate
        attributeName="cy"
        calcMode="spline"
        keySplines="0 0.5 0.5 1;0.5 0 1 0.5;0.5 0.5 0.5 0.5"
        repeatCount="indefinite"
        values="57.5;42.5;57.5;57.5"
        keyTimes="0;0.25;0.5;1"
        dur="0.8695652173913042s"
        begin="-0.14492753623188406s"
      ></animate>
    </circle>
  </svg>
);



const ChatMessage: React.FC<ChatMessageProps> = ({ message, botName, avatar, generating }) => {
  const [buttonText, setButtonText] = useState('Copy Code');

  const renderCode: any = (code: string) => {
    if (code.indexOf('```') === -1) {
      return (<div dangerouslySetInnerHTML={{ __html: code }} />);
    }

    const bracketIndex = code.indexOf('```');
    return (
      <>
        <div dangerouslySetInnerHTML={{ __html: code.substring(0, bracketIndex) }} />
        <div className="bg-[#343641] rounded-[8px] mt-2">
          <div className="flex items-center justify-between px-3 py-2">
            <p className="m-0 text-[#f2f2f2]">Code</p>
            <button className="flex justify-center text-[#f2f2f2] items-center hover:opacity-75 cursor-pointer" onClick={() => {
              navigator.clipboard.writeText(code.substring(code.indexOf('\n', bracketIndex + 1), code.indexOf('```', bracketIndex + 1)))
                .then(() => {
                  setButtonText('Copied!');
                  setTimeout(() => {
                    setButtonText('Copy Code');
                  }, 2000); // Reverts back to 'Submit' after 2 seconds 
                })
                .catch((error) => {
                  console.error("Failed to copy value:", error);
                });
            }}>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 cursor-pointer rounded-full">
                <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 7.5V6.108c0-1.135.845-2.098 1.976-2.192.373-.03.748-.057 1.123-.08M15.75 18H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08M15.75 18.75v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5A3.375 3.375 0 006.375 7.5H5.25m11.9-3.664A2.251 2.251 0 0015 2.25h-1.5a2.251 2.251 0 00-2.15 1.586m5.8 0c.065.21.1.433.1.664v.75h-6V4.5c0-.231.035-.454.1-.664M6.75 7.5H4.875c-.621 0-1.125.504-1.125 1.125v12c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V16.5a9 9 0 00-9-9z" />
              </svg>
              <h4 className="text-[12px] font-bold my-auto">{buttonText}</h4>
            </button>
          </div>
          <pre className="bg-[#202020] px-3 text-white">
            <code>
              {
                code.indexOf('```', bracketIndex + 1) > -1 ?
                  <div dangerouslySetInnerHTML={{ __html: highlight.highlight(code.substring(code.indexOf('\n', bracketIndex + 1), code.indexOf('```', bracketIndex + 1)), { language: 'xml' }).value }} /> :
                  <div dangerouslySetInnerHTML={{ __html: highlight.highlight((code + "```").substring((code + "```").indexOf('\n', (code + '```').indexOf('```') + 1), (code + "```").indexOf('```', (code + "```").indexOf('```') + 1)), { language: 'xml' }).value }} />
              }
            </code>
          </pre>
        </div>
        {
          code.indexOf('```', bracketIndex + 3) !== -1 ?
            renderCode(code.substring(code.indexOf('```', code.indexOf('```') + 3) + 3)) : (<></>)
        }
      </>
    );
  };

  return (
    <div className={`chat-message ${message.sender}`}
      style={{
        backgroundColor: message.content.indexOf('Moderation error: Your') > -1
          || message.content.indexOf('please reload the conversation and submit something shorter.') > -1
          || message.content.indexOf('Rate limit reached for gpt-4') > -1 ? '#f5d3d6' : message.sender === 'bot' ? '#F7F7F8' : 'initial',
      }}
    >
      {message.sender === "bot" ? (
        <div className="avatar">
          <img
            src={avatar}
            alt="avatar"
            className="object-cover"
          />
        </div>
      ) : (
        <Avatar sx={{ "--Avatar-size": '24px', margin: '15px' }} />
      )}
      {
        // generating ? 
        // <div className="message-content">{message.content}</div> : 
        message.sender === 'bot' && message.content.indexOf('```') > -1 ?
          <div className="message-content  whitespace-pre-wrap">
            {
              renderCode(message.content)
            }
          </div> : message.sender === 'bot' ?
            <div
              className="message-content whitespace-pre-wrap"
              style={{
                color: message.content.indexOf('Moderation error: Your') > -1
                  || message.content.indexOf('please reload the conversation and submit something shorter.') > -1
                  || message.content.indexOf('Rate limit reached for gpt-4') > -1 ? '#ff0000' : 'rgba(0, 0, 0, 0.7)'
              }}
              dangerouslySetInnerHTML={{ __html: message.content }}
            /> : <div className="message-content  whitespace-pre-wrap font-[Inter] mb-0 font-semibold">{message.content}</div>
      }
    </div>
  );
};

interface ChatInputProps {
  onSubmit: (message: string) => void;
  pendingState: Boolean,
  primaryColor: string,
  disabled: boolean,
  currentPlan: string,
  label: any,
}

const ChatInput: React.FC<ChatInputProps> = ({ onSubmit, pendingState, primaryColor, disabled, currentPlan, label }) => {
  const [message, setMessage] = useState("");
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);

  useEffect(() => {
    const handleInput = () => {
      if (textareaRef.current) {
        textareaRef.current.style.height = 'auto';
        textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
      }
    };

    if (textareaRef.current) {
      textareaRef.current.addEventListener('input', handleInput);

      // Cleanup listener on unmount
      return () => {
        if (textareaRef.current) {
          textareaRef.current.removeEventListener('input', handleInput);
        }
      };
    }
  }, []);

  const handleSubmit = () => {
    if (message.trim()) {
      if (message.trim().length <= 0) return;
      onSubmit(message.trim());
      setMessage("");
      if (textareaRef.current) {
        textareaRef.current.style.height = 'auto';  // Reset the height
      }
    }
  };



  const handleProcessKey = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && e.shiftKey) {
      e.preventDefault();
      setMessage(message + '\n');
      setTimeout(() => {
        if (textareaRef.current) {
          textareaRef.current.style.height = 'auto';
          textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
        }
      }, 0);  // Use setTimeout to delay the height adjustment
      return;
    }

    if (e.key === 'Enter' && !pendingState) {
      e.preventDefault();
      handleSubmit();
    }
  };

  return (
    <div className="chat-input-wrapper">
      <div className="chat-input">
        <div className="flex items-end w-full border-[#D1D5D8]/[.5] border-[1px] rounded px-3 py-2">
          <textarea
            ref={textareaRef}
            id="text"
            // type="text"
            rows={1}
            disabled={disabled}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            onKeyDown={handleProcessKey}
            placeholder="Send a message..."
            className="bg-white p-0"
          />
          <button onClick={() => handleSubmit()} disabled={!!pendingState} className="hover:opacity-80">
            <svg width="21" height="19" viewBox="0 0 21 19" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M3.73092 9.1979L1 1C7.61513 2.77495 13.7588 5.57348 19.2167 9.19766C13.7588 12.8219 7.6152 15.6205 1.00009 17.3955L3.73092 9.1979ZM3.73092 9.1979L11.2312 9.19791" stroke={primaryColor} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </button>
        </div>
      </div>
      <div className="py-3">
        {label.enable &&
          <div className="font-[Inter] w-full font-medium text-[11px] text-center text-[black]">
            <a className="font-bold text-[black]" href={label.link} target="_blank" rel="noreferrer">{label.text}</a>
          </div>
        }
      </div>
    </div>
  );
};

let uniqueId: any = undefined;

export default function EmbedBot() {

  const [messages, setMessages] = useState<Message[]>([]);
  const [pendingState, setPendingState] = useState<Boolean>(false)
  const [loading, setLoading] = useState<boolean>(false);
  // const [hasMounted, setHasMounted] = useState<boolean>(false);
  const [userInfo, setUserInfo] = useState<typeChatInfo>({
    instance_name: '',
    bot_id: 0
  });
  const hasMounted = useRef(false);
  const [avatar, setAvatar] = useState();
  const [primaryColor, setPrimaryColor] = useState<string>('');
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const [newMessage, setNewMessage] = useState('');
  const [currentMessageId, setCurrentMessageId] = useState('0');
  const [generating, setGenerating] = useState(false);
  const [currentPlan, setCurrentPlan] = useState('free');
  const [welcome, setWelcome] = useState({
    enable: true,
    welcome: 'Welcome to our site! Ask me anything about our website!'
  });
  const [label, setLabel] = useState({
    enable: true,
    text: 'Powered by ChatSmith.ai',
    link: 'https://chatsmith.ai'
  });

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const pb = params.get('pb');

  const [chatData, setChatData] = useState<any>("");

  const getUniqueId = () => {

    let value: any = '';
    if (window)
      value = window.sessionStorage.getItem('unique-id');
    else
      value = uniqueId;

    if (window) {
      if (!value || !window.name) {
        value = uuidv4();
        if (!value) return;
        window.sessionStorage.setItem('unique-id', value);
      }
    } else {
      if (!value || !uniqueId) {
        value = uuidv4();
        if (!value) return;
        uniqueId = value;
      }
    }

    if (window) {
      window.name = value;
    } else {
      uniqueId = value;
    }
    return value;
  };

  const [isIncognito, setIsIncognito] = useState(false);

  useEffect(() => {
    socket.on('connect', onConnect);
    socket.on('stream_new_token', onNewToken);
    socket.on('disconnect', onDisconnect);


    const testKey = '__test__';

    try {
      // Attempt to use localStorage
      window.localStorage.setItem(testKey, testKey);
      window.localStorage.removeItem(testKey);
    } catch (error) {
      // If an error occurs, it might be due to incognito mode
      setIsIncognito(true);
    }

    return () => {
      socket.off('stream_new_token', onNewToken);
      socket.off('connect', onConnect);
      socket.off('disconnect', onDisconnect);
    };

  }, []);

  const onNewToken = (token: string) => {
    setNewMessage((prev) => prev + token);
    setLoading(false);
  };

  const onConnect = () => {
    socket.emit('join', {
      username: getUniqueId(),
      room: uuidv4(),
    });
    setGenerating(true);
  };

  const onDisconnect = () => {
    setGenerating(false);
  };



  useEffect(() => {
    setMessages(prevMessages => {
      const mesData = [...prevMessages];
      const current = mesData.find(item => item.id === currentMessageId);
      if (current) {
        const id = mesData.indexOf(current);
        if (~id) {
          mesData[id] = {
            ...current,
            content: newMessage,
          };
        }
      } else if (newMessage !== '') {
        mesData.push({
          id: currentMessageId,
          content: newMessage,
          sender: 'bot',
        });
      }
      return mesData;
    });
  }, [newMessage, currentMessageId]);


  useEffect(() => {
    if (pb) {
      // setHasMounted(true);
      getChatInfos(pb)
    }
  }, [pb])

  useEffect(() => {
    if (!hasMounted.current) {
      hasMounted.current = true;
      return;
    }

    if (messagesEndRef.current && generating && messages.length >= 2) {
      messagesEndRef.current.scrollTop = messagesEndRef.current?.scrollHeight
    }
  }, [generating, messages]);

  useEffect(() => {
    setMessages(prevMessages => {
      let mes = [...prevMessages];
      if (welcome.enable) {
        const index = mes.findIndex((mes: any) => mes.id === '0');
        if (index === -1) {
          mes.unshift({
            id: '0',
            content: welcome.welcome,
            sender: 'bot',
          });
        } else {
          mes[index] = {
            id: '0',
            content: welcome.welcome,
            sender: 'bot'
          };

        }

        return mes;
      } else {
        const index = mes.findIndex((mes: any) => mes.id === '0');
        if (index === -1) {
        } else {
          mes.shift();
        }
        return mes;
      }
    });
  }, [welcome]);

  const getChatInfos = async (token: String) => {
    const path = process.env.REACT_APP_BASE_URL + "/api/getEmbedChatBotInfo";
    const request = {
      token: token
    }
    await axios.post(path, request).then((res) => {
      let data = []
      setUserInfo({ instance_name: res.data.botName, bot_id: res.data.botId })
      if (res.data.color && res.data.color.length > 2) setPrimaryColor(res.data.color);
      else setPrimaryColor('#0b0333');
      if (res.data.label) {
        setLabel(res.data.label);
      }
      if (res.data.avatar && res.data.avatar.length > 10) setAvatar(res.data.avatar);
      let storedData: any = [];
      if (!isIncognito) {
        storedData = localStorage.getItem('chats');
      } else {
        storedData = chatData;
      }

      const chats = storedData ? JSON.parse(storedData || "") : [];
      if (chats.length >= 1)
        for (var i = 0; i < chats.length; i++) {
          data.push({
            id: uuidv4(),
            content: chats[i].question,
            sender: 'user'
          })
          data.push({
            id: uuidv4(),
            content: chats[i].answer,
            sender: 'bot'
          })
        }
      setMessages(data);
      setCurrentPlan(res.data.plan);
      setWelcome(res.data.welcome);
    }).catch((err) => {
      console.log(err)
    })
  }

  const addMessage = (content: string) => {
    setPendingState(true)
    setLoading(true);
    let responseMessage = "";

    // Simulate a response
    const path = process.env.REACT_APP_BASE_URL + "/api/embedChat";
    const tabUniqueId = getUniqueId();
    const request = {
      token: pb,
      bot_id: userInfo.bot_id,
      query: content,
      // current_url: window.location.href,
      unique_id: tabUniqueId,
    };

    setNewMessage('');
    const id = uuidv4();
    setCurrentMessageId(id);
    setMessages(prevMessages => [
      ...prevMessages,
      { id: uuidv4(), content, sender: 'user' },
      // { id, content: '', sender: "bot" },
    ]);
    socket.connect();
    axios.post(path, request).then((res) => {
      responseMessage = res.data.message;
      setMessages(prevMessages => {
        const mesData = [...prevMessages];
        const lastData = mesData[mesData.length - 1];
        mesData[mesData.length - 1] = {
          ...lastData,
          content: responseMessage,
        };
        return mesData;
      });
      let storedData: any = [];
      if (!isIncognito) {
        storedData = localStorage.getItem('chats');
      } else {
        storedData = chatData;
      }
      const chats = storedData ? JSON.parse(storedData || "") : [];
      const data = { "answer": responseMessage, "question": content }
      chats.push(data)
      const jsonString = JSON.stringify(chats);

      if (!isIncognito)
        localStorage.setItem('chats', jsonString);
      else setChatData(jsonString);
    }).catch((err) => {
      console.log(err.response)
    }).finally(() => {
      setLoading(false);
      setPendingState(false);
      socket.disconnect();
    });
  };

  const deleteChats = async () => {
    setMessages([]);
    if (!isIncognito)
      localStorage.setItem('chats', "");
    else setChatData("");
  }

  return (
    <div className="flex justify-center bg-[transparent]">
      {/* <div className="pr-0 w-full sm:pl-5 pl-0">
        <div className="chat-header">
          {userInfo.instance_name}
          <TrashIcon className="w-[20px] cursor-pointer" onClick={deleteChats} />
        </div>
        <div className="chat-inner">
          <div className="chat-messages">
            {messages.map((message) => (
              <ChatMessage key={message.id} message={message} botName={userInfo.chat_name} />
            ))}
          </div>
        </div>
        <ChatInput onSubmit={addMessage} pendingState={pendingState} />
      </div> */}

      <div className="w-full p-0">
        <div className="chat-container">
          <div className="chat-header-and-messages">
            <div className="chat-header rounded-t-[20px] px-2" style={{ backgroundColor: primaryColor }}>
              <div className="chat-header-inner">
                <img
                  src={avatar ? `data:image/png;base64,${avatar}` : "/assets/image/robot.png"}
                  alt="avatar"
                  className="w-[33px] h-[33px] rounded ml-[20px] object-cover"
                />
                <div className="ai-name">
                  <h1 className="text-[18px] text-white mb-0 font-bold">
                    {userInfo.instance_name}
                  </h1>
                  <div className="text-[Montserrat] font-semibold text-white/[.5] text-xs ai-status">
                    online
                    <span className="ai-status-circle"></span>
                  </div>
                </div>
              </div>
              <button onClick={deleteChats} className="absolute right-4 flex text-[#6d6e7e] hover:text-white transition cursor-pointer">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="white" className="w-6 h-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                </svg>
              </button>
            </div>
            <div className="chat-inner bg-white">
              <div
                className="chat-messages w-full"
                style={{ overflowAnchor: 'none' }}
                ref={messagesEndRef}
              >
                {messages.map((message, index) => (
                  <ChatMessage
                    key={message.id}
                    message={message}
                    botName={userInfo.instance_name}
                    avatar={avatar ? `data:image/png;base64,${avatar}` : "/assets/image/robot.png"}
                    generating={generating && index === messages.length - 1}
                  />
                ))}
                {loading && (
                  <div className="chat-message bot">
                    <div className="avatar">
                      <img
                        src={avatar ? `data:image/png;base64,${avatar}` : "/assets/image/robot.png"}
                        alt="avatar"
                      />
                    </div>
                    <div className="message-conten">
                      <LoadingIcon />
                    </div>
                  </div>
                )}
                {/* <div ref={messagesEndRef} /> */}
              </div>
            </div>
          </div>
          <ChatInput onSubmit={addMessage} pendingState={pendingState} primaryColor={primaryColor} currentPlan={currentPlan} disabled={false} label={label} />
        </div>
      </div>
    </div>
  );
}
