import React from 'react';
import { GridLoader } from 'react-spinners';

interface typeLoader {
    loading: boolean;
    children?: any;
}

const Loader = ({ loading, children }: typeLoader) => {
    return (
        <>
            {
                loading &&
                <div className="w-full h-full flex items-center justify-center min-h-[300px]">
                    <GridLoader color='#18A2AB' size={12} />
                </div>
            }
            {!loading && children}
        </>
    );
};

export default Loader;