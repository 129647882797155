import { FC } from 'react';
import { Routes, Route } from 'react-router-dom';
import App from '../App';
import Account from '../Account'
import PrivateRoute from './PrivateRoute';
import CreateBot from '../pages/CreateBot'
import ExampleBot from '../pages/ExampleBot'
import Edit from '../pages/edit/Edit';
import Pricing from '../pages/Pricing';
import ChatBubble from '../pages/ChatBubble';

const PrivateRoutes: FC = () => {
  return (
    <div>
      <Routes>
        <Route path="/" element={
          <PrivateRoute>
            <App />
          </PrivateRoute>
        } />
        <Route path="/create" element={
          <PrivateRoute>
            <CreateBot />
          </PrivateRoute>} />
        <Route path="/account" element={
          <PrivateRoute>
            <Account />
          </PrivateRoute>} />
        <Route path="/chatbot/:id" element={
          <PrivateRoute>
            <ExampleBot />
          </PrivateRoute>} />
        <Route path='/edit-bot/:id' element={
          <PrivateRoute>
            <Edit />
          </PrivateRoute>} />
        <Route path='/pricing' element={
          <PrivateRoute>
            <Pricing />
          </PrivateRoute>
        } />
      </Routes>
      <ChatBubble />
    </div>
  );
};

export default PrivateRoutes;
