import { useEffect, useState } from 'react';
import axios from 'axios';
import highlight from 'highlight.js';
import { toast } from "sonner";
import { useMediaQuery } from 'react-responsive';
import javascript from 'highlight.js/lib/languages/javascript';

import moment from 'moment-timezone';
import Loader from '../../components/Loader';
import MobileHistoryDetail from './History-Mobile';

highlight.registerLanguage('javascript', javascript)

interface typeShowHistory {
    botName: any,
    avatar: string,
}

const ShowHistory = ({ botName, avatar }: typeShowHistory) => {
    const [chatHistory, setChatHistory] = useState<Array<any>>([]);
    const [selected, setSelected] = useState(0);
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);

    const isMobile = useMediaQuery({ maxWidth: 500 });

    useEffect(() => {
        const path = process.env.REACT_APP_BASE_URL + '/api/embed_chat_history';
        const user_email = localStorage.getItem('email');
        const request = {
            email: user_email,
            bot_name: botName
        };

        setLoading(true);
        axios.get(path, { params: request, headers: { Authoritzation: localStorage.getItem("token") } }).then(res => {
            setChatHistory(res.data.chats);
        }).catch(err => {
            console.log('err:', err);
        }).finally(() => setLoading(false));
    }, []);

    const handleDeleteHistory = (id: string) => {
        const path = process.env.REACT_APP_BASE_URL + '/api/embed_chat_history';
        const user_email = localStorage.getItem('email');
        const request = {
            email: user_email,
            id,
        };
        setLoading(true);
        axios.delete(path, { params: request }).then(() => {
            setSelected(0);
            setChatHistory(chatHistory.filter(chat => chat.id !== id));
        }).catch(err => {
            console.log('err:', err);
        }).finally(() => setLoading(false));
    };

    const renderCode: any = (code: string) => {
        if (code.indexOf('```') === -1) {
          return (<div dangerouslySetInnerHTML={{ __html: code }}/>);
        }
    
        const bracketIndex = code.indexOf('```');
        return (
          <>
            <div dangerouslySetInnerHTML={{ __html: code.substring(0, bracketIndex)}} />
            <div className="bg-[#343641] rounded-[8px] mt-2">
              <div className="flex items-center justify-between px-3 py-2">
                <p className="m-0 text-[#f2f2f2]">Code</p>
                <button className="flex justify-center text-[#f2f2f2] items-center hover:opacity-75 cursor-pointer" onClick={() => {
                navigator.clipboard.writeText(code.substring(code.indexOf('\n', bracketIndex + 1), code.indexOf('```', bracketIndex + 1)))
                  .then(() => {
                    toast.success("Copied successfully");
                  })
                  .catch((error) => {
                    console.error("Failed to copy value:", error);
                  });
                }}>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 cursor-pointer rounded-full">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 7.5V6.108c0-1.135.845-2.098 1.976-2.192.373-.03.748-.057 1.123-.08M15.75 18H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08M15.75 18.75v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5A3.375 3.375 0 006.375 7.5H5.25m11.9-3.664A2.251 2.251 0 0015 2.25h-1.5a2.251 2.251 0 00-2.15 1.586m5.8 0c.065.21.1.433.1.664v.75h-6V4.5c0-.231.035-.454.1-.664M6.75 7.5H4.875c-.621 0-1.125.504-1.125 1.125v12c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V16.5a9 9 0 00-9-9z" />
                  </svg>
                  <h4 className="text-[12px] font-bold my-auto">Copy Code</h4>
                </button>
              </div>
              <pre className="bg-[#202020] px-3 text-white">
                <code>
                  {
                    code.indexOf('```', bracketIndex + 1) > -1 ? 
                    <div dangerouslySetInnerHTML={{ __html: highlight.highlight(code.substring(code.indexOf('\n', bracketIndex + 1), code.indexOf('```', bracketIndex + 1)), { language: 'xml' }).value }} /> :
                    <div dangerouslySetInnerHTML={{ __html: highlight.highlight((code + "```").substring((code + "```").indexOf('\n', (code + '```').indexOf('```') + 1), (code + "```").indexOf('```', (code + "```").indexOf('```') + 1)), { language: 'xml' }).value }} />
                  } 
                </code>
              </pre>
            </div>
            {
              renderCode(code.substring(code.indexOf('```', code.indexOf('```') + 1) + 4))
            }
            </>
        );
      };

    return (
        <>
            <div className="col-span-full p-2">
                <Loader loading={loading}>
                    <div className="md:grid md:grid-cols-4 md:grid-flow-col gap-4 sm:block">
                        <div className="md:col-span-1 sm:col-span-4 border-[#D1D5DB] md:border-r-[1px] sm:pr-[30px]">
                            <h2 className='font-[Inter] font-bold pb-3 text-xl text-[#8F98A1] border-b-[1px] border-[#D1D5DB] sm:ms-2'>Chat History</h2>
                            <div className='flex justify-between items-center my-4 sm:mx-2'>
                                <p className='font-[Inter] font-bold font-md m-0 text-[black]'>All Conversations</p>
                                <p className='font-[Inter] font-bold font-md m-0 text-[black]'>{`(${chatHistory.length})`}</p>
                            </div>
                            <div className='mt-2'>
                                {
                                    chatHistory.map((item, index) => (
                                        <div key={index} className='flex items-center'>
                                            <div
                                                onClick={() => {
                                                    setSelected(index);
                                                    setOpen(true);
                                                }}
                                                className={`cursor-pointer flex flex-1 overflow-hidden font-[Inter] text-sm sm:mx-2 rounded-md px-2 py-3 sm:py-4 ${selected === index ? 'bg-[#18A2AB]/[.1] text-[#161616]' : 'bg-[transparent] text-[black]/[.7]'}`}
                                            >
                                                <div>
                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className='ml-2 mr-3 mt-1'>
                                                        <path fillRule="evenodd" clipRule="evenodd" d="M20.2277 20.7348C21.4203 19.6141 22.3704 18.2608 23.0193 16.7584C23.6681 15.256 24.0019 13.6365 24 12C24 5.3723 18.6277 0 12 0C5.37231 0 8.01134e-06 5.3723 8.01134e-06 12C-0.00188553 13.6365 0.331905 15.256 0.980746 16.7584C1.62959 18.2608 2.57969 19.6141 3.77231 20.7348C5.99623 22.8356 8.94073 24.0041 12 24C15.0593 24.0041 18.0038 22.8356 20.2277 20.7348ZM4.79385 19.1532C5.65795 18.0722 6.75448 17.1997 8.00202 16.6005C9.24955 16.0013 10.616 15.6909 12 15.6923C13.384 15.6909 14.7504 16.0013 15.998 16.6005C17.2455 17.1997 18.3421 18.0722 19.2062 19.1532C18.2634 20.1055 17.141 20.861 15.904 21.3761C14.667 21.8912 13.34 22.1556 12 22.1538C10.66 22.1556 9.33305 21.8912 8.09604 21.3761C6.85902 20.861 5.73659 20.1055 4.79385 19.1532ZM16.6154 8.30769C16.6154 9.53176 16.1291 10.7057 15.2636 11.5713C14.398 12.4368 13.2241 12.9231 12 12.9231C10.7759 12.9231 9.60198 12.4368 8.73643 11.5713C7.87088 10.7057 7.38462 9.53176 7.38462 8.30769C7.38462 7.08361 7.87088 5.90967 8.73643 5.04412C9.60198 4.17857 10.7759 3.69231 12 3.69231C13.2241 3.69231 14.398 4.17857 15.2636 5.04412C16.1291 5.90967 16.6154 7.08361 16.6154 8.30769Z" fill="#D1D5DB" />
                                                    </svg>
                                                </div>
                                                <div className='truncate'>
                                                    <p className='m-0'>{item.chats[item.chats.length - 1].question}</p>
                                                    <p className='m-0 text-[9px]'>{`${moment.utc(item.create_time).local().format('MM/DD/YYYY - HH:mm')} ${moment.tz(moment.tz.guess()).format('z')}`}</p>
                                                </div>
                                            </div>
                                            {isMobile &&
                                                <div className='ms-3'>
                                                    <button
                                                        className='w-[38px] h-[38px] hover:bg-[#C81212]/[.1] bg-white hover:stroke-[#C81212] hover:border-[#C81212]/[.5] border-[#E7E7E7] stroke-[#251A15] border-[1px] rounded-[4px] flex items-center justify-center'
                                                        onClick={() => handleDeleteHistory(chatHistory[selected].id)}
                                                    >
                                                        <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M9.05933 5.88426L8.8056 12.3961M5.2944 12.3961L5.04067 5.88426M12.3505 3.5617C12.6013 3.59933 12.8507 3.63912 13.1 3.68181M12.3505 3.5617L11.5673 13.6066C11.5354 14.0155 11.3481 14.3975 11.043 14.6762C10.7379 14.9548 10.3375 15.1095 9.92173 15.1094H4.17827C3.76254 15.1095 3.36208 14.9548 3.05698 14.6762C2.75189 14.3975 2.56463 14.0155 2.53267 13.6066L1.74947 3.5617M12.3505 3.5617C11.5042 3.43546 10.6534 3.33965 9.8 3.27446M1.74947 3.5617C1.49867 3.5986 1.24933 3.6384 1 3.68109M1.74947 3.5617C2.59583 3.43546 3.44657 3.33965 4.3 3.27446M9.8 3.27446V2.6117C9.8 1.75792 9.13267 1.04596 8.26733 1.01919C7.45598 0.993604 6.64402 0.993604 5.83267 1.01919C4.96733 1.04596 4.3 1.75865 4.3 2.6117V3.27446M9.8 3.27446C7.9694 3.13487 6.1306 3.13487 4.3 3.27446" stroke="inherit" strokeLinecap="round" strokeLinejoin="round" />
                                                        </svg>
                                                    </button>
                                                </div>
                                            }
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                        {isMobile &&
                        <MobileHistoryDetail open={open} close={() => setOpen(false)} deleteHistory={() => handleDeleteHistory(chatHistory[selected].id)}>
                            {chatHistory.length > 0 &&
                                <div className='relative'>
                                    {chatHistory[selected].chats.map((pair: any, index: number) => (
                                        <div key={index}>
                                            <div className='flex px-2 py-4 font-[Inter] font-medium text-sm'>
                                                <div className='mx-3'>
                                                    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path fillRule="evenodd" clipRule="evenodd" d="M20.5968 20.7348C21.7895 19.6141 22.7396 18.2608 23.3884 16.7584C24.0372 15.256 24.371 13.6365 24.3691 12C24.3691 5.3723 18.9968 0 12.3691 0C5.74145 0 0.369149 5.3723 0.369149 12C0.367255 13.6365 0.701046 15.256 1.34989 16.7584C1.99873 18.2608 2.94883 19.6141 4.14145 20.7348C6.36537 22.8356 9.30987 24.0041 12.3691 24C15.4284 24.0041 18.3729 22.8356 20.5968 20.7348ZM5.16299 19.1532C6.02709 18.0722 7.12362 17.1997 8.37116 16.6005C9.6187 16.0013 10.9852 15.6909 12.3691 15.6923C13.7531 15.6909 15.1196 16.0013 16.3671 16.6005C17.6147 17.1997 18.7112 18.0722 19.5753 19.1532C18.6326 20.1055 17.5101 20.861 16.2731 21.3761C15.0361 21.8912 13.7091 22.1556 12.3691 22.1538C11.0292 22.1556 9.70219 21.8912 8.46518 21.3761C7.22816 20.861 6.10573 20.1055 5.16299 19.1532ZM16.9845 8.30769C16.9845 9.53176 16.4983 10.7057 15.6327 11.5713C14.7672 12.4368 13.5932 12.9231 12.3691 12.9231C11.1451 12.9231 9.97113 12.4368 9.10557 11.5713C8.24002 10.7057 7.75376 9.53176 7.75376 8.30769C7.75376 7.08361 8.24002 5.90967 9.10557 5.04412C9.97113 4.17857 11.1451 3.69231 12.3691 3.69231C13.5932 3.69231 14.7672 4.17857 15.6327 5.04412C16.4983 5.90967 16.9845 7.08361 16.9845 8.30769Z" fill="#D1D5DB" />
                                                    </svg>

                                                </div>
                                                <span className='pt-1'>{pair.question}</span>
                                            </div>
                                            <div className='flex overflow-auto bg-[#F7F7F8] rounded-md px-2 py-4 font-[Inter] font-medium text-sm w-full'>
                                                <div className='mx-[1rem] sm:mx-3'>
                                                    {/* <Avatar src={avatar.length <= 30 ? '/assets/image/robot.png' : avatar} sx={{ "--Avatar-size": '24px' }} /> */}
                                                    <img
                                                        src={avatar.length <= 30 ? '/assets/image/robot.png' : avatar}
                                                        alt="avatar"
                                                        className="max-w-[30px] max-h-[30px] rounded object-cover"
                                                    />
                                                </div>
                                                <div className='my-2 flex-1'>{ renderCode(pair.answer) }</div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            }
                        </MobileHistoryDetail>
                        }
                        {!isMobile && <div className="md:col-span-3 sm:col-span-4 p-3 relative">
                            {chatHistory.length > 0 &&
                                <div>
                                    <div className='flex lg:absolute xl:absolute 2xl:absolute md:absolute right-0 top-0 items-center'>
                                        <p className='font-bold text-sm font-[Inter] me-3 mb-0'>Delete conversation</p>
                                        <button
                                            className='w-[38px] h-[38px] hover:bg-[#C81212]/[.1] bg-white hover:stroke-[#C81212] hover:border-[#C81212]/[.5] border-[#E7E7E7] stroke-[#251A15] border-[1px] rounded-[4px] flex items-center justify-center'
                                            onClick={() => handleDeleteHistory(chatHistory[selected].id)}
                                        >
                                            <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M9.05933 5.88426L8.8056 12.3961M5.2944 12.3961L5.04067 5.88426M12.3505 3.5617C12.6013 3.59933 12.8507 3.63912 13.1 3.68181M12.3505 3.5617L11.5673 13.6066C11.5354 14.0155 11.3481 14.3975 11.043 14.6762C10.7379 14.9548 10.3375 15.1095 9.92173 15.1094H4.17827C3.76254 15.1095 3.36208 14.9548 3.05698 14.6762C2.75189 14.3975 2.56463 14.0155 2.53267 13.6066L1.74947 3.5617M12.3505 3.5617C11.5042 3.43546 10.6534 3.33965 9.8 3.27446M1.74947 3.5617C1.49867 3.5986 1.24933 3.6384 1 3.68109M1.74947 3.5617C2.59583 3.43546 3.44657 3.33965 4.3 3.27446M9.8 3.27446V2.6117C9.8 1.75792 9.13267 1.04596 8.26733 1.01919C7.45598 0.993604 6.64402 0.993604 5.83267 1.01919C4.96733 1.04596 4.3 1.75865 4.3 2.6117V3.27446M9.8 3.27446C7.9694 3.13487 6.1306 3.13487 4.3 3.27446" stroke="inherit" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                        </button>
                                    </div>
                                    {chatHistory[selected].chats.map((pair: any, index: number) => (
                                        <div key={index}>
                                            <div className='flex px-2 py-4 font-[Inter] font-medium text-sm'>
                                                <div className='mx-3'>
                                                    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path fillRule="evenodd" clipRule="evenodd" d="M20.5968 20.7348C21.7895 19.6141 22.7396 18.2608 23.3884 16.7584C24.0372 15.256 24.371 13.6365 24.3691 12C24.3691 5.3723 18.9968 0 12.3691 0C5.74145 0 0.369149 5.3723 0.369149 12C0.367255 13.6365 0.701046 15.256 1.34989 16.7584C1.99873 18.2608 2.94883 19.6141 4.14145 20.7348C6.36537 22.8356 9.30987 24.0041 12.3691 24C15.4284 24.0041 18.3729 22.8356 20.5968 20.7348ZM5.16299 19.1532C6.02709 18.0722 7.12362 17.1997 8.37116 16.6005C9.6187 16.0013 10.9852 15.6909 12.3691 15.6923C13.7531 15.6909 15.1196 16.0013 16.3671 16.6005C17.6147 17.1997 18.7112 18.0722 19.5753 19.1532C18.6326 20.1055 17.5101 20.861 16.2731 21.3761C15.0361 21.8912 13.7091 22.1556 12.3691 22.1538C11.0292 22.1556 9.70219 21.8912 8.46518 21.3761C7.22816 20.861 6.10573 20.1055 5.16299 19.1532ZM16.9845 8.30769C16.9845 9.53176 16.4983 10.7057 15.6327 11.5713C14.7672 12.4368 13.5932 12.9231 12.3691 12.9231C11.1451 12.9231 9.97113 12.4368 9.10557 11.5713C8.24002 10.7057 7.75376 9.53176 7.75376 8.30769C7.75376 7.08361 8.24002 5.90967 9.10557 5.04412C9.97113 4.17857 11.1451 3.69231 12.3691 3.69231C13.5932 3.69231 14.7672 4.17857 15.6327 5.04412C16.4983 5.90967 16.9845 7.08361 16.9845 8.30769Z" fill="#D1D5DB" />
                                                    </svg>

                                                </div>
                                                <span className='pt-1'>{pair.question}</span>
                                            </div>
                                            <div className='flex bg-[#F7F7F8] rounded-md px-2 py-4 font-[Inter] font-medium text-sm'>
                                                <div className='px-3'>
                                                    {/* <Avatar src={avatar.length <= 30 ? '/assets/image/robot.png' : avatar} sx={{ "--Avatar-size": '24px' }} /> */}
                                                    <img
                                                        src={avatar.length <= 30 ? '/assets/image/robot.png' : avatar}
                                                        alt="avatar"
                                                        className="max-w-[33px] max-h-[33px] rounded object-cover"
                                                    />
                                                </div>
                                                <div className='my-2 overflow-auto'>{ renderCode(pair.answer) }</div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            }
                        </div>
                        }
                    </div>
                </Loader>
            </div>
        </>
    );
};

export default ShowHistory;