import React, { useState } from 'react';
import Box from '@mui/joy/Box/Box';
import Button from '@mui/joy/Button/Button'
import Divider from '@mui/joy/Divider/Divider'
import Modal from '@mui/joy/Modal/Modal'
import Typography from "@mui/joy/Typography/Typography";
import ModalDialog from "@mui/joy/ModalDialog/ModalDialog";
import { BellAlertIcon } from "@heroicons/react/24/outline";

interface SaveAlertModalProps {
    openModal: boolean,
    onOk: () => void,
    onCancel: () => void,
}


const SaveAlertModal: React.FC<SaveAlertModalProps> = ({ openModal, onOk, onCancel }) => {
    return (
        <Modal open={openModal} onClose={() => onCancel()}>
            <ModalDialog
                variant="outlined"
                role="alertdialog"
                aria-labelledby="alert-dialog-modal-title"
                aria-describedby="alert-dialog-modal-description"
            >
                <Typography
                    id="alert-dialog-modal-title"
                    level="h2"
                    startDecorator={<BellAlertIcon />}
                >
                    Confirmation
                </Typography>
                <Divider />
                <Typography id="alert-dialog-modal-description" textColor="text.tertiary">
                    Are you sure you want to discard all of your changes?
                </Typography>
                <Box sx={{ display: 'flex', gap: 1, justifyContent: 'flex-end', pt: 2 }}>
                    <Button variant="plain" color="neutral" onClick={() => {
                        onCancel();
                    }}>
                        Cancel
                    </Button>
                    <Button variant="solid" color="danger" onClick={() => {
                        onOk();
                    }}>
                        Discard changes
                    </Button>
                </Box>
            </ModalDialog>
        </Modal>
    );
};

export default SaveAlertModal;