import React from "react";

interface CharacterLimitComponentProps {
  text: string;
  limit: number;
}

const CharacterLimitComponent: React.FC<CharacterLimitComponentProps> = ({
  text,
  limit,
}) => {
  if (text.length <= limit) {
    return <span>{text}</span>;
  }

  const truncatedText = text.slice(0, limit) + "...";

  return <span className="break-all">{truncatedText}</span>;
};

export default CharacterLimitComponent;
