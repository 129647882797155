import { useEffect, useState } from "react";
import axios from 'axios';
import { RadioGroup } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/20/solid";
import { loadStripe } from '@stripe/stripe-js';
import Modal from "@mui/joy/Modal";
import ModalDialog from "@mui/joy/ModalDialog";
import Loader from "../components/Loader";

import Stripe from "stripe";

const publicKey = process.env.REACT_APP_STRIPE_PUBLISH_KEY;
const secretKey = process.env.REACT_APP_STRIPE_SECRET_KEY;
const stripeCheckoutSession = new Stripe(secretKey as string, { apiVersion: '2022-11-15' });

type Frequency = {
  value: "monthly" | "annually";
  label: string;
  priceSuffix: string;
};

type Tier = {
  name: string;
  id: string;
  href: string;
  price: { monthly: string; annually: string };
  priceApi: { monthly: string; annually: string };
  features: string[];
  mostPopular: boolean;
  savings?: string;
  type: string;
};

const frequencies: Frequency[] = [
  { value: "monthly", label: "Monthly", priceSuffix: "/month" },
  { value: "annually", label: "Annually", priceSuffix: "/year" },
];

const planOrder = [{
  order: 1,
  name: 'free'
}, {
  order: 2,
  name: 'hobby'
}, {
  order: 3,
  name: 'standard'
}, {
  order: 4,
  name: 'pro',
}];

const tiers: Array<Tier> = [
  {
    name: "Free",
    id: "tier-free",
    href: "#",
    price: { monthly: "$0", annually: "$0" },
    priceApi: {
      monthly: 'none',
      annually: 'none',
    },
    features: [
      "1 chatbot",
      "2 web pages",
      // "Fine tune with raw text content (100 character limit)",
      "Chat with single PDF file",
      "Embed on unlimited websites",
      "50 messages / month",
    ],
    mostPopular: false,
    type: 'free',
  },
  {
    name: "Hobby",
    id: "tier-hobby",
    href: "#",
    price: { monthly: "$19", annually: "$190" },
    priceApi: {
      monthly: process.env.REACT_APP_STRIPE_PRICE19 || '',
      annually: process.env.REACT_APP_STRIPE_PRICE190 || '',
    },
    features: [
      "2 chatbots",
      "25 web pages / chatbot",
      "Fine tune with raw text content (1,000 character limit)",
      "Chat with multiple PDFs",
      "Embed on unlimited websites",
      "View Conversation History",
      "2000 messages / month per account",
    ],
    mostPopular: false,
    type: 'hobby',
  },
  {
    name: "Standard",
    id: "tier-standard",
    href: "#",
    price: { monthly: "$49", annually: "$490" },
    priceApi: {
      monthly: process.env.REACT_APP_STRIPE_PRICE49 || '',
      annually: process.env.REACT_APP_STRIPE_PRICE490 || '',
    },
    savings: "A plan that scales with your rapidly growing business.",
    features: [
      "5 chatbots",
      "100 web pages / chatbot",
      "Fine tune with raw text content (5,000 character limit)",
      "Chat with multiple PDFs",
      "Embed on unlimited websites",
      "View conversation history",
      "5,000 messages / month per account",
    ],
    mostPopular: true,
    type: 'standard',
  },
  {
    name: "Pro",
    id: "tier-pro",
    href: "#",
    price: { monthly: "$99", annually: "$990" },
    priceApi: {
      monthly: process.env.REACT_APP_STRIPE_PRICE99 || '',
      annually: process.env.REACT_APP_STRIPE_PRICE990 || '',
    },
    features: [
      "10 chatbots",
      "500 web pages / chatbot",
      "Fine tune with raw text content (10,000 character limit)",
      "Chat with multiple PDFs",
      "Embed on unlimited websites",
      "View conversation history",
      "Remove ChatSmith.ai branding from bots",
      "White Label",
      "10,000 messages / month per account",
    ],
    mostPopular: false,
    type: 'pro',
  },
  // {
  //   name: "Enterprise",
  //   id: "tier-enterprise",
  //   href: "#",
  //   price: { monthly: "$499", annually: "$4,990" },
  //   features: [
  //     "Unlimited chatbots",
  //     "10,000 web pages / account",
  //     "Fine tune with raw text content (50,000 character limit)",
  //     "Chat with multiple PDFs",
  //     "Embed on unlimited websites",
  //     "View conversation history",
  //     "Remove ChatSmith.ai branding from bots",
  //     "White Label",
  //     "40,000 messages / month per account",
  //   ],
  //   mostPopular: false,
  // },
];

function classNames(...classes: (string | boolean | undefined)[]): string {
  return classes.filter(Boolean).join(" ");
}

const Pricing = () => {
  const [frequency, setFrequency] = useState<Frequency>(frequencies[0]);
  const [loading, setLoading] = useState(false);
  const [currentPlan, setCurrentPlan] = useState('free');
  const [currentPeriod, setCurrentPeriod] = useState('monthly');
  const [subscriptionId, setSubscriptionId] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState<any>();

  useEffect(() => {
    setLoading(true);
    const path = process.env.REACT_APP_BASE_URL + "/api/getSubscription";
    const user_email = localStorage.getItem('email');
    const request = {
      email: user_email
    };

    setLoading(true);
    axios.post(path, request, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    }).then((res) => {
      setCurrentPlan(res.data.type);
      setSubscriptionId(res.data.subscriptionId);
      setCurrentPeriod(res.data.period);
    }).catch(err => console.log('error:', err)).finally(() => setLoading(false));
  }, []);

  const handleSubscribe = async (type: string, period: string, priceApi: string) => {
    if (priceApi === 'none') {
      return;
    }

    else {
      const email = localStorage.getItem('email');
      let customer;
      // check if customer already exists
      const existingCustomers = await stripeCheckoutSession.customers.list({
        email: email as string
      });

      if (existingCustomers.data.length > 0) {
        // customer already exists
        customer = existingCustomers.data[0];
      } else {
        // create a new customer
        customer = await stripeCheckoutSession.customers.create({
          email: email as string,
          // add any other customer details here as necessary
        });
      }

      if (!subscriptionId || subscriptionId.length <= 3) {
        // Create a checkout session
        const checkoutSession = await stripeCheckoutSession.checkout.sessions.create({
          customer: customer.id,
          mode: 'subscription',
          line_items: [{
            price: priceApi,
            quantity: 1,
          }],
          success_url: 'https://app.chatsmith.ai/#',
          cancel_url: 'https://app.chatsmith.ai/#',
          automatic_tax: { enabled: true },
          customer_update: { address: 'auto' }
        });

        const stripePromise = loadStripe(publicKey || "");
        const stripe = await stripePromise;

        await stripe?.redirectToCheckout({
          sessionId: checkoutSession.id
        });
      } else {
        setSelectedPlan({
          priceApi,
          type,
          period,
        });

        const plannedOrder = planOrder.find(plan => plan.name === type)?.order;
        const currentOrder = planOrder.find(plan => plan.name === currentPlan)?.order;

        if (!currentOrder || !plannedOrder || plannedOrder > currentOrder) {

          // cancel old subscription
          // await stripeCheckoutSession.subscriptions.cancel(subscriptionId);

          const checkoutSession = await stripeCheckoutSession.checkout.sessions.create({
            customer: customer.id,
            mode: 'subscription',
            line_items: [{
              price: priceApi,
              quantity: 1,
            }],
            success_url: 'https://app.chatsmith.ai/#',
            cancel_url: 'https://app.chatsmith.ai/#',
          });

          const stripePromise = loadStripe(publicKey || "");
          const stripe = await stripePromise;

          await stripe?.redirectToCheckout({
            sessionId: checkoutSession.id
          });
        } else {
          setOpenModal(true);
        }
      }
    }
  };

  const handleConfirmGrade = async () => {
    if (!selectedPlan || !selectedPlan.priceApi || !selectedPlan.period || !selectedPlan.type) {
      setOpenModal(false);
      return;
    }

    const email = localStorage.getItem('email');
    let customer;
    // check if customer already exists
    const existingCustomers = await stripeCheckoutSession.customers.list({
      email: email as string
    });

    if (existingCustomers.data.length > 0) {
      // customer already exists
      customer = existingCustomers.data[0];
    } else {
      // create a new customer
      customer = await stripeCheckoutSession.customers.create({
        email: email as string,
        // add any other customer details here as necessary
      });
    }

    const subscription = await stripeCheckoutSession.subscriptions.list({
      customer: customer.id,
    });

    setLoading(true);
    new Promise(async (resolve, reject) => {
      const update = await stripeCheckoutSession.subscriptionItems.update(subscription.data[0].items.data[0].id, {
        price: selectedPlan.priceApi,
      });
      if (update) resolve(true);
      reject(false);
    }).then(() => {
      setCurrentPlan(selectedPlan.type);
      setCurrentPeriod(selectedPlan.period);
    }).catch(err => {
      console.log('error:', err);
    }).finally(() => {
      setLoading(false);
      setOpenModal(false);
    });
  };

  return (
    <div className="bg-white py-3">
      <Loader loading={loading}>
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-4xl text-center">
            <h2 className="text-base font-semibold leading-7 text-[#18a2ab]">
              ChatSmith.ai
            </h2>
            <p className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
              Pricing Plans
            </p>
          </div>
          <p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-600">
            Choose an affordable plan that’s packed with the best features for
            your needs.
          </p>
          <div className="mt-16 flex justify-center">
            <RadioGroup
              value={frequency}
              onChange={setFrequency}
              className="grid grid-cols-2 gap-x-1 rounded-full p-1 text-center text-xs font-semibold leading-5 ring-1 ring-inset ring-gray-200"
            >
              <RadioGroup.Label className="sr-only">
                Payment frequency
              </RadioGroup.Label>
              {frequencies.map((option) => (
                <RadioGroup.Option
                  key={option.value}
                  value={option}
                  className={({ checked }) =>
                    classNames(
                      checked ? "bg-[#18a2ab] text-white" : "text-gray-500",
                      "cursor-pointer rounded-full px-2.5 py-1"
                    )
                  }
                >
                  <span>{option.label}</span>
                </RadioGroup.Option>
              ))}
            </RadioGroup>
          </div>
          <p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-600">
            <strong>Get 2 months FREE</strong> with annual plans!
          </p>
          <div className="isolate mx-auto mt-10 grid max-w-md grid-cols-1 gap-8 md:max-w-2xl md:grid-cols-2 lg:max-w-4xl xl:mx-0 xl:max-w-none xl:grid-cols-4">
            {tiers.map((tier) => (
              <div
                key={tier.id}
                className={classNames(
                  tier.mostPopular
                    ? "ring-2 ring-[#18a2ab]"
                    : "ring-1 ring-gray-200",
                  "rounded-3xl p-8 relative"
                )}
              >
                {
                  currentPlan === tier.type && currentPeriod === frequency['value'] &&
                  <p className="absolute rounded-r-md bg-[#FED35E] text-[#C81212] px-2.5 py-1 text-xs font-semibold leading-5 left-0 top-[65px]">Current Plan</p>
                }
                <div className="flex items-center justify-between gap-x-4">
                  <h3
                    id={tier.id}
                    className={classNames(
                      tier.mostPopular ? "text-[#18a2ab]" : "text-gray-900",
                      "text-lg font-semibold leading-8"
                    )}
                  >
                    {tier.name}
                  </h3>

                  {tier.mostPopular ? (
                    <p className="rounded-full bg-[#eff8f9] text-[#18a2ab] px-2.5 py-1 text-xs font-semibold leading-5">
                      Most popular
                    </p>
                  ) : null}
                </div>

                <p className="mt-6 flex items-baseline gap-x-1">
                  <span className="text-4xl font-bold tracking-tight text-gray-900">
                    {tier.price[frequency.value]}
                  </span>
                  <span className="text-sm font-semibold leading-6 text-gray-600">
                    {frequency.priceSuffix}{" "}
                  </span>
                </p>
                {frequency.value === "annually" &&
                  tier.price[frequency.value] !== "$0" && (
                    <div
                      style={{ maxWidth: "120px" }}
                      className="mt-0 text-[12px] rounded-full text-white bg-[#212121] px-2.5 py-1  text-center font-semibold leading-5"
                    >
                      2 months FREE
                    </div>
                  )}

                <button
                  // href={tier.href}
                  disabled={currentPlan === tier.type && currentPeriod === frequency.value}
                  aria-describedby={tier.id}
                  className={classNames(
                    tier.mostPopular
                      ? "bg-[#18a2ab] text-white shadow-sm hover:bg-[#18a2ab]"
                      : "text-[#18a2ab] ring-1 ring-inset ring-[#67a8ac] hover:ring-[#18a2ab] hover:text-[#18a2ab] hover:bg-[#eff8f9]",
                    "mt-6 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#18a2ab] disabled:bg-[#D9D9D9] disabled:outline-none disabled:ring-0 disabled:text-black"
                  )}
                  onClick={() => handleSubscribe(tier.type, frequency['value'], tier.priceApi[frequency.value])}
                >
                  {currentPlan === tier.type && currentPeriod === frequency.value ? 'Subscribed' : 'Subscribe'}
                </button>
                <ul
                  className="mt-8 space-y-3 text-sm leading-6 text-gray-600 pl-0"
                >
                  {tier.features.map((feature) => (
                    <li key={feature} className="flex gap-x-3">
                      <CheckIcon
                        className="h-6 w-5 flex-none text-[#18a2ab]"
                        aria-hidden="true"
                      />
                      {feature}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>

        <div className="mx-auto mt-14 max-w-4xl text-center">
          <h2 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
            Need a larger plan?
          </h2>
          <p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-600">
            No problem! Get in touch with us to discuss your needs.
          </p>
          <a
            style={{ maxWidth: "320px" }}
            className="bg-[#18a2ab] text-white mx-auto my-6 shadow-sm hover:bg-[#18a2ab] mt-6 block rounded-md py-2 px-3 text-center text-lg font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#18a2ab]"
            href="mailto:admin@chatsmith.ai?subject=Custom%20Plan%20Request"
          >
            Contact us
          </a>
        </div>
      </Loader>
      <Modal open={openModal}>
        <ModalDialog>
          <h4 className="my-3 font-bold text-base">Do you really want to Downgrade?</h4>
          <div className="flex items-center justify-center">
            <button className="px-4 py-2 bg-[#FFE294] border-[1px] rounded border-[#FED35E] me-1 text-sm" onClick={handleConfirmGrade}>Confirm</button>
            <button className="px-4 py-2 bg-[#D9D9D9] rounded ms-1 text-sm" onClick={() => setOpenModal(false)}>Cancel</button>
          </div>
        </ModalDialog>
      </Modal>
    </div>
  );
}

export default Pricing;