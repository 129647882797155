import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { ExclamationTriangleIcon } from "@heroicons/react/20/solid";
import { Link } from 'react-router-dom';
import Loader from '../../components/Loader';

interface typeEditPdf {
    botId: number,
    totalCount: number,
};

const EditPdf = ({ botId, totalCount }: typeEditPdf) => {

    const fileInputRef = useRef<HTMLInputElement | null>(null);
    const [fileSize, setFileSize] = useState(0);
    const [totalSize, setTotalSize] = useState("0");
    const [existPdfFiles, setExistPdfFiles] = useState<Array<String>>([]);
    const [removePdfFiles, setRemovePdfFiles] = useState<Array<String>>([]);
    const [fileList, setFileList] = useState<Array<File>>([]);
    const [submitable, setSubmitable] = useState<boolean>(false);
    const [loading, setLoading] = useState(false);
    const [showWarning, setShowWarning] = useState(false);

    useEffect(() => {
        if (botId !== 0) {
            setLoading(true);
            Promise.all([
                getTotalSize(),
                getExistPdfFiles(),
            ]).catch(err => {
                console.log('err:', err);
            }).finally(() => setLoading(false));
        }
    }, [botId]);

    useEffect(() => {
        if (removePdfFiles.length > 0 || fileList.length > 0) {
            setSubmitable(true);
        } else {
            setSubmitable(false);
        }

        if (Number(Number(totalSize) + Number(fileSize)) > 10) {
            setSubmitable(false);
        }
    }, [fileList, removePdfFiles, totalSize, fileSize]);


    useEffect(() => {
        let sum = 0;
        for (const item of fileList) {
            sum += item.size / 1024 / 1024;
        }
        setFileSize(sum);
    }, [fileList]);

    const getTotalSize = () => {
        return new Promise((resolve, reject) => {
            const path = process.env.REACT_APP_BASE_URL + "/api/get_folder_size";
            const user_email = localStorage.getItem('email')
            const request = {
                email: user_email
            }
            axios.post(path, request, {
                headers: {
                    Authorization: localStorage.getItem("token"),
                },
            }).then((res) => {
                setTotalSize(parseFloat(String(res.data.size)).toFixed(2));
                resolve(true);
            }).catch((err) => {
                reject();
            });
        });

    };

    const getExistPdfFiles = () => {
        return new Promise((resolve, reject) => {
            const path = process.env.REACT_APP_BASE_URL + "/api/get_pdf_files_name";
            const user_email = localStorage.getItem('email')
            const request = {
                email: user_email,
                bot_id: botId
            }
            axios.post(path, request, {
                headers: {
                    Authorization: localStorage.getItem("token"),
                },
            }).then((res) => {
                setExistPdfFiles(res.data.names);
                resolve(true);
            }).catch((err) => {
                console.log(err);
                reject();
            })
        });
    };

    //Upload the pdf files
    const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
    };

    const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        const files = Array.from(event.dataTransfer.files);
        const pdfFiles = files.filter(file => file.type === 'application/pdf');
        setFileList(prevFiles => [...prevFiles, ...pdfFiles]);
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFiles = event.target.files;

        if (selectedFiles) {
            const newFiles = Array.from(selectedFiles)

            const pdfFiles = newFiles.filter(file => file.type === 'application/pdf');

            setFileList(prevFiles => [...prevFiles, ...pdfFiles]);
            localStorage.setItem('bot-editing', 'true');
        }
    };

    const handleSubmit = () => {
        if (Number(totalCount) < fileList.length + existPdfFiles.length - removePdfFiles.length) {
            setShowWarning(true);
            return;
        }

        const formData = new FormData();

        fileList.forEach((file) => {
            formData.append("files", file);
        });
        const user_email = localStorage.getItem('email')

        formData.append("email", String(user_email));
        formData.append("bot_id", String(botId));
        removePdfFiles.forEach((filename) => {
            formData.append('remove_files', String(filename));
        });

        const path = process.env.REACT_APP_BASE_URL + "/api/updateChat";
        setLoading(true);
        axios.post(path, formData, {
            headers: {
                Authorization: localStorage.getItem("token"),
                "Content-Type": "multipart/form-data",
            },
        }).then(() => {
            window.location.reload()
            localStorage.setItem('bot-editing', 'false');
        }).catch((err) => {
            console.log(err)
        }).finally(() => {
            setLoading(false);
        });
    };

    return (
        <>
            {/*PDF Files */}
            <div className="col-span-full p-2">
                <Loader loading={loading}>
                    <div className='flex justify-between items-center'>
                        <div>
                            <div className="text-base font-bold leading-6 text-black text-left">
                                PDF's <span className={`text-xs font-semibold ${Number(Number(totalSize) + Number(fileSize)) > 10 ? 'text-[red]' : 'text-[#18A2AB]'}`}>({Number(Number(totalSize) + Number(fileSize)).toFixed(2)}/10MB)</span>
                            </div>
                            <p className='my-2 text-[#8F98A1] font-[Inter] font-normal text-xs'>Your chat bot is trained on these PDF's</p>
                        </div>
                        <button
                            disabled={!submitable}
                            className='font-bold text-xs px-4 py-2 rounded-[4px] disabled:bg-[#D9D9D9] enabled:bg-[#FFE294] enabled:border-[#FED35E] enabled:border-[1px] enabled:hover:bg-[#fed35e]'
                            onClick={handleSubmit}
                        >
                            Publish
                        </button>
                    </div>
                    <div className="grid xl:grid-cols-6 lg:grid-cols-6 md:grid-cols-4 sm:grid-cols-2 xs: grid-cols-2 my-2">
                        {
                            existPdfFiles.map((item, index) => (
                                <div className="flex justify-center mx-3 my-1 items-center bg-[#D9D9D9] hover:bg-gray-400 rounded-full px-3 py-1 cursor-pointer" key={index} onClick={() => {
                                    const removedFile = existPdfFiles[index];
                                    setExistPdfFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
                                    setRemovePdfFiles((prevFiles) => [...prevFiles, removedFile]);
                                }}>
                                    <svg width="16" height="16" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" className='me-2'>
                                        <path d="M3.5 10.5L10.5 3.5M3.5 3.5L10.5 10.5" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>

                                    <span className="font-bold font-[Inter] text-sm truncate"> {item.length > 10 ? `${item.slice(0, 4)}...${item.slice(-4)}` : item}</span>
                                </div>
                            ))
                        }

                        {fileList.length > 0 &&
                            fileList.map((file, index) => (
                                <div
                                    className="flex justify-center mx-3 my-1 items-center bg-[#D9D9D9] hover:bg-gray-400 rounded-full px-3 py-1 cursor-pointer"
                                    key={index}
                                    onClick={() => {
                                        setFileList(fileList.filter(item => item.name !== file.name));
                                    }}
                                >
                                    <svg width="16" height="16" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M3.5 10.5L10.5 3.5M3.5 3.5L10.5 10.5" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                    <span className="font-bold font-[Inter] text-sm truncate ms-2">{file.name}</span>
                                </div>
                            ))
                        }
                    </div>
                    <div
                        onDragOver={handleDragOver}
                        onDrop={handleDrop}
                        className={`w-full h-32 px-4 mb-2 transition bg-white border-2 border-dashed rounded-md appearance-none cursor-pointer hover:border-gray-400 focus:outline-none relative ${Number(Number(totalSize) + Number(fileSize)) > 10 ? 'border-[red]' : 'border-gray-300'}`}>
                        <div className="flex justify-center h-32">
                            <span className="flex items-center">
                                <div
                                    onClick={() => fileInputRef.current?.click()}>
                                    <><div className="flex justify-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 text-gray-400" fill="none" viewBox="0 0 24 24"
                                            stroke="currentColor" strokeWidth="2">
                                            <path strokeLinejoin="round" strokeLinecap="round"
                                                d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
                                        </svg>
                                    </div>
                                        <h1 className="font-medium text-gray-400 text-center text-[12px]">
                                            <span className="text-[#18A2AB] text-center">Upload a file</span> or drag and drop<br /> PDFs up to 10MB total
                                        </h1></>
                                </div>
                            </span>
                        </div>
                    </div>
                    {
                        Number(Number(totalSize) + Number(fileSize)) > 10 &&
                        <p className="mt-1 mb-5 text-xs leading-2 text-[red]">
                            Exceeded file size limit. Please upload a smaller file
                        </p>
                    }
                    {
                        showWarning &&
                        <div className="fixed bottom-[100px] left-[0] z-30 w-full flex items-center justify-center">
                            <div className="border-l-4 border-yellow-400 bg-yellow-50 p-4 w-auto">
                                <div className="flex">
                                    <div className="flex-shrink-0">
                                        <ExclamationTriangleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                                    </div>
                                    <div className="ml-3">
                                        <p className="text-sm text-yellow-700 mb-[0]">
                                            You have exceeded the number of PDFs.{' '}
                                            <Link to="/pricing" className="font-medium text-yellow-700 underline hover:text-yellow-600">
                                                Upgrade plan
                                            </Link>
                                        </p>
                                    </div>
                                    <div className="ms-[50px] cursor-pointer" onClick={() => setShowWarning(false)}>
                                        <svg width="20" height="20" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M3.5 10.5L10.5 3.5M3.5 3.5L10.5 10.5" stroke="#FED35E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </Loader>
            </div>
            <input type="file" name="file_upload" className="hidden" ref={fileInputRef} onChange={handleFileChange} multiple />
        </>
    );
};

export default EditPdf;