import { FC } from "react";
import { useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";

const Verify: FC = () => {

    const { token } = useParams()
    const navigate = useNavigate();

    useEffect(() => {
        handleVerify()
    }, [])

    const handleVerify = async () => {
        const path = process.env.REACT_APP_BASE_URL + "/api/verify/" + token;

        const request = {
        }
        await axios.post(path, request).then((res) => {
            if (res.data) {
                localStorage.setItem('token', res.data.token)
                localStorage.setItem('email', res.data.email)
            }
        }).catch((err) => {
            console.log(err)
        })
        navigate('/')
    }

    return (
        <div className="w-full">
            <h1 className="text-white text-center">Verifying</h1>
        </div>
    )

}

export default Verify