import React, { useState, useEffect, useRef } from "react";
import axios from 'axios'
import { v4 as uuidv4 } from "uuid";
import Avatar from '@mui/joy/Avatar/Avatar';
import highlight from 'highlight.js';
import javascript from 'highlight.js/lib/languages/javascript';
import { useParams } from "react-router";
import { useNavigate, useLocation } from 'react-router-dom';
import { Link } from "react-router-dom";
import Modal from '@mui/joy/Modal';
import { toast } from "sonner";
import ModalDialog from '@mui/joy/ModalDialog';
import { ExclamationTriangleIcon } from "@heroicons/react/20/solid";
import "../App.css";
import Loader from "../components/Loader";
import socket from "../socket";

highlight.registerLanguage('javascript', javascript)

interface Message {
  id: string;
  content: string;
  sender: string;
}

interface ChatMessageProps {
  message: Message;
  botName: string;
  avatar: string;
  generating: boolean;
}

interface typeChatInfo {
  bot_id: number;
  instance_name: string;
  bot_name: string;
  chat_name: string;
  chats: Array<any>;
  created: string;
  avatar: string;
}

const LoadingIcon = () => (
  <svg
    style={{
      margin: "0",
      display: "block",
      shapeRendering: "auto",
      padding: "7px",
      width: "60px",
    }}
    width="44px"
    height="44px"
    viewBox="0 0 100 100"
    preserveAspectRatio="xMidYMid"
  >
    <circle cx="23" cy="57.5" r="9" fill="#85a2b6">
      <animate
        attributeName="cy"
        calcMode="spline"
        keySplines="0 0.5 0.5 1;0.5 0 1 0.5;0.5 0.5 0.5 0.5"
        repeatCount="indefinite"
        values="57.5;42.5;57.5;57.5"
        keyTimes="0;0.25;0.5;1"
        dur="0.8695652173913042s"
        begin="-0.43478260869565216s"
      ></animate>
    </circle>{" "}
    <circle cx="50" cy="57.5" r="9" fill="#bbcedd">
      <animate
        attributeName="cy"
        calcMode="spline"
        keySplines="0 0.5 0.5 1;0.5 0 1 0.5;0.5 0.5 0.5 0.5"
        repeatCount="indefinite"
        values="57.5;42.5;57.5;57.5"
        keyTimes="0;0.25;0.5;1"
        dur="0.8695652173913042s"
        begin="-0.2898550724637681s"
      ></animate>
    </circle>{" "}
    <circle cx="77" cy="57.5" r="9" fill="#dce4eb">
      <animate
        attributeName="cy"
        calcMode="spline"
        keySplines="0 0.5 0.5 1;0.5 0 1 0.5;0.5 0.5 0.5 0.5"
        repeatCount="indefinite"
        values="57.5;42.5;57.5;57.5"
        keyTimes="0;0.25;0.5;1"
        dur="0.8695652173913042s"
        begin="-0.14492753623188406s"
      ></animate>
    </circle>
  </svg>
);



const ChatMessage: React.FC<ChatMessageProps> = ({ message, botName, avatar, generating }) => {
  const [buttonText, setButtonText] = useState('Copy Code');

  const renderCode: any = (code: string) => {
    if (code.indexOf('```') === -1) {
      return (<div dangerouslySetInnerHTML={{ __html: code }} />);
    }

    const bracketIndex = code.indexOf('```');
    return (
      <>
        <div dangerouslySetInnerHTML={{ __html: code.substring(0, bracketIndex) }} />
        <div className="bg-[#343641] rounded-[8px] mt-2">
          <div className="flex items-center justify-between px-3 py-2">
            <p className="m-0 text-[#f2f2f2]">Code</p>
            <button className="flex justify-center text-[#f2f2f2] items-center hover:opacity-75 cursor-pointer" onClick={() => {
              navigator.clipboard.writeText(code.substring(code.indexOf('\n', bracketIndex + 1), code.indexOf('```', bracketIndex + 1)))
                .then(() => {
                  setButtonText('Copied!');
                  setTimeout(() => {
                    setButtonText('Copy Code');
                  }, 2000); // Reverts back to 'Submit' after 2 seconds 
                })
                .catch((error) => {
                  console.error("Failed to copy value:", error);
                });
            }}>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 cursor-pointer rounded-full">
                <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 7.5V6.108c0-1.135.845-2.098 1.976-2.192.373-.03.748-.057 1.123-.08M15.75 18H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08M15.75 18.75v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5A3.375 3.375 0 006.375 7.5H5.25m11.9-3.664A2.251 2.251 0 0015 2.25h-1.5a2.251 2.251 0 00-2.15 1.586m5.8 0c.065.21.1.433.1.664v.75h-6V4.5c0-.231.035-.454.1-.664M6.75 7.5H4.875c-.621 0-1.125.504-1.125 1.125v12c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V16.5a9 9 0 00-9-9z" />
              </svg>
              <h4 className="text-[12px] font-bold my-auto">{buttonText}</h4>
            </button>
          </div>
          <pre className="bg-[#202020] px-3 text-white">
            <code>
              {
                code.indexOf('```', bracketIndex + 1) > -1 ?
                  <div dangerouslySetInnerHTML={{ __html: highlight.highlight(code.substring(code.indexOf('\n', bracketIndex + 1), code.indexOf('```', bracketIndex + 1)), { language: 'xml' }).value }} /> :
                  <div dangerouslySetInnerHTML={{ __html: highlight.highlight((code + "```").substring((code + "```").indexOf('\n', (code + '```').indexOf('```') + 1), (code + "```").indexOf('```', (code + "```").indexOf('```') + 1)), { language: 'xml' }).value }} />
              }
            </code>
          </pre>
        </div>
        {
          code.indexOf('```', bracketIndex + 3) !== -1 ?
            renderCode(code.substring(code.indexOf('```', code.indexOf('```') + 3) + 3)) : (<></>)
        }
      </>
    );
  };

  return (
    <div className={`chat-message ${message.sender}`}
      style={{
        backgroundColor: message.content.indexOf('Moderation error: Your') > -1
          || message.content.indexOf('please reload the conversation and submit something shorter.') > -1
          || message.content.indexOf('Rate limit reached for gpt-4') > -1 ? '#f5d3d6' : message.sender === 'bot' ? '#F7F7F8' : 'initial',
      }}
    >
      {message.sender === "bot" ? (
        <div className="avatar">
          <img
            src={avatar}
            alt="avatar"
            className="object-cover"
          />
        </div>
      ) : (
        <Avatar sx={{ "--Avatar-size": '24px', margin: '15px' }} />
      )}
      {
        // generating ? 
        // <div className="message-content">{code}</div> : 
        message.sender === 'bot' && message.content.indexOf('```') > -1 ?
          <div className="message-content whitespace-pre-wrap">
            {
              renderCode(message.content)
            }
          </div> : message.sender === 'bot' ?
            <div
              className="message-content whitespace-pre-wrap"
              style={{
                color: message.content.indexOf('Moderation error: Your') > -1
                  || message.content.indexOf('please reload the conversation and submit something shorter.') > -1
                  || message.content.indexOf('Rate limit reached for gpt-4') > -1 ? '#ff0000' : 'rgba(0, 0, 0, 0.7)'
              }}
              dangerouslySetInnerHTML={{ __html: message.content }}
            /> : <div className="message-content whitespace-pre-wrap font-[Inter] mb-0 font-semibold">{message.content}</div>
      }
    </div>
  );
};

interface ChatInputProps {
  onSubmit: (message: string) => void;
  pendingState: Boolean,
  primaryColor: string,
  disabled: boolean,
  currentPlan: string,
  label: any,
}

const ChatInput: React.FC<ChatInputProps> = ({ onSubmit, pendingState, primaryColor, disabled, currentPlan, label }) => {
  const [message, setMessage] = useState("");
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);

  useEffect(() => {
    const handleInput = () => {
      if (textareaRef.current) {
        textareaRef.current.style.height = 'auto';
        textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
      }
    };

    if (textareaRef.current) {
      textareaRef.current.addEventListener('input', handleInput);

      // Cleanup listener on unmount
      return () => {
        if (textareaRef.current) {
          textareaRef.current.removeEventListener('input', handleInput);
        }
      };
    }
  }, []);

  const handleSubmit = () => {
    if (message.trim()) {
      if (message.trim().length <= 0) return;
      onSubmit(message.trim());
      setMessage("");
      if (textareaRef.current) {
        textareaRef.current.style.height = 'auto';  // Reset the height
      }
    }
  };



  const handleProcessKey = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && e.shiftKey) {
      e.preventDefault();
      setMessage(message + '\n');
      setTimeout(() => {
        if (textareaRef.current) {
          textareaRef.current.style.height = 'auto';
          textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
        }
      }, 0);  // Use setTimeout to delay the height adjustment
      return;
    }

    if (e.key === 'Enter' && !pendingState) {
      e.preventDefault();
      handleSubmit();
    }
  };

  return (
    <div className="chat-input-wrapper">
      <div className="chat-input">
        <div className="flex items-end w-full border-[#D1D5D8]/[.5] border-[1px] rounded px-3 py-2">
          <textarea
            ref={textareaRef}
            id="text"
            // type="text"
            rows={1}
            disabled={disabled}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            onKeyDown={handleProcessKey}
            placeholder="Send a message..."
            className="bg-white p-0"
          />
          <button onClick={() => handleSubmit()} disabled={!!pendingState} className="hover:opacity-80">
            <svg width="21" height="19" viewBox="0 0 21 19" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M3.73092 9.1979L1 1C7.61513 2.77495 13.7588 5.57348 19.2167 9.19766C13.7588 12.8219 7.6152 15.6205 1.00009 17.3955L3.73092 9.1979ZM3.73092 9.1979L11.2312 9.19791" stroke={primaryColor} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </button>
        </div>
      </div>
      <div className="py-3">
        {label.enable &&
          <div className="font-[Inter] w-full font-medium text-[11px] text-center text-[black]">
            <a className="font-bold text-[black]" href={label.link} target="_blank" rel="noreferrer">{label.text}</a>
          </div>
        }
      </div>
    </div>
  );
};

interface typeBot {
  primaryColor?: string;
  welcome?: any;
  label?: any;
};

export default function ExampleBot({ primaryColor: color, welcome: prevWelcome, label: prevLabel }: typeBot) {
  const location = useLocation();

  const [embedOpenModal, setEmbedOpenModal] = useState(false);
  const [embedUrl, setEmbedUrl] = useState<string>("");
  const [messages, setMessages] = useState<Message[]>([]);
  const [actionLoading, setActionLoading] = useState(false);
  const [pendingState, setPendingState] = useState<Boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [hasMounted, setHasMounted] = useState<boolean>(false);
  const [primaryColor, setPrimaryColor] = useState<string>(color ? color : '#0b0333');
  const [newMessage, setNewMessage] = useState('');
  const [currentMessageId, setCurrentMessageId] = useState('0');
  const [generating, setGenerating] = useState(false);
  const [bubblePlace, setBubblePlace] = useState('right');
  const [userInfo, setUserInfo] = useState<typeChatInfo>({
    bot_id: 0,
    instance_name: '',
    bot_name: '',
    chat_name: '',
    chats: [],
    created: '',
    avatar: '',
  });
  const [showWarning, setShowWarning] = useState<boolean>(false);
  const [inputDisable, setInputDisable] = useState<boolean>(false);
  const [label, setLabel] = useState(prevLabel ? prevLabel : {
    enable: true,
    text: 'Powered by ChatSmith.ai',
    link: 'https://chatsmith.ai'
  });
  const [welcome, setWelcome] = useState(prevWelcome ? prevWelcome : {
    enable: true,
    welcome: 'Welcome to our site! Ask me anything about our website!'
  });

  const [currentPlan, setCurrentPlan] = useState<string>('free');
  const navigate = useNavigate();
  const messagesEndRef = useRef<HTMLDivElement>(null);


  const { id } = useParams(); // here id means bot_name

  useEffect(() => { setHasMounted(true) }, []);

  useEffect(() => {
    if (id) {
      setActionLoading(true);
      Promise.all([
        getChatInfos(id),
        getEmbedScriptToken(id),
        // getEmbedJavascript(id),
        getSubscription(),
      ]).catch(err => {
        console.log('err:', err);
      }).finally(() => {
        setActionLoading(false);
      });
    }
  }, [id]);

  useEffect(() => {
    if (prevLabel) {
      setLabel(prevLabel);
    }
  }, [prevLabel]);

  useEffect(() => {
    if (color && color.length > 6) setPrimaryColor(color);
  }, [color]);

  useEffect(() => {
    setMessages(prevMessages => {
      const mesData = [...prevMessages];
      const current = mesData.find(item => item.id === currentMessageId);
      if (current) {
        const id = mesData.indexOf(current);
        if (~id) {
          mesData[id] = {
            ...current,
            content: newMessage,
          };
        }
      } else if (newMessage !== '') {
        mesData.push({
          id: currentMessageId,
          content: newMessage,
          sender: 'bot',
        });
      }
      return mesData;
    });
  }, [newMessage, currentMessageId]);

  const onNewToken = (token: string) => {
    setNewMessage((prev) => prev + token);
    setLoading(false);
  };

  const onConnect = () => {
    socket.emit('join', {
      username: localStorage.getItem('email'),
      room: uuidv4(),
    });
    setGenerating(true);
  };

  const onDisconnect = () => {
    setGenerating(false);
  };

  useEffect(() => {
    socket.on('connect', onConnect);
    socket.on('stream_new_token', onNewToken);
    socket.on('disconnect', onDisconnect);

    return () => {
      socket.off('stream_new_token', onNewToken);
      socket.off('connect', onConnect);
      socket.off('disconnect', onDisconnect);
    };
  }, []);

  useEffect(() => {
    if (hasMounted) {
      if (messagesEndRef.current) {
        messagesEndRef.current.scrollTop = messagesEndRef.current?.scrollHeight
      }
    }
  }, [loading]);

  useEffect(() => {
    if (hasMounted) {
      if (messagesEndRef.current) {
        messagesEndRef.current.scrollTop = messagesEndRef.current?.scrollHeight
      }
    }
  }, [messages]);

  useEffect(() => {
    setMessages(prevMessages => {
      let mes = [...prevMessages];
      if (welcome.enable) {
        const index = mes.findIndex((mes: any) => mes.id === '0');
        if (index === -1) {
          mes.unshift({
            id: '0',
            content: welcome.welcome,
            sender: 'bot',
          });
        } else {
          mes[index] = {
            id: '0',
            content: welcome.welcome,
            sender: 'bot'
          };

        }

        return mes;
      } else {
        const index = mes.findIndex((mes: any) => mes.id === '0');
        if (index === -1) {
        } else {
          mes.shift();
        }
        return mes;
      }
    });
  }, [welcome]);

  useEffect(() => {
    if (prevWelcome) {
      setWelcome(prevWelcome);
    }
  }, [prevWelcome]);


  // const getEmbedJavascript = (id: string) => {
  //   return new Promise((resolve, reject) => {
  //     const path = process.env.REACT_APP_BASE_URL + "/api/createEmbedJavaScript";
  //     const user_email = localStorage.getItem('email')
  //     const request = {
  //       email: user_email,
  //       bot_name: id,
  //     }
  //     axios.post(path, request, {
  //       headers: {
  //         Authorization: localStorage.getItem("token"),
  //       },
  //     }).then((res) => {
  //       console.log('javascript data:', res.data);
  //       setEmbedScript(res.data);
  //       resolve(true);
  //     }).catch(err => {
  //       console.log(err.data);
  //       reject();
  //     });
  //   });
  // };

  useEffect(() => {
    setEmbedScript(`<script type="text/javascript">(function(){d=document;s=d.createElement("script");s.src="https://chatsmith-backend.onrender.com/api/createEmbedJavaScript?email=${localStorage.getItem('email')}&name=${id}&place=${bubblePlace}";s.async=1;d.getElementsByTagName("head")[0].appendChild(s);})();</script>`);
  }, [bubblePlace]);

  const [embedScript, setEmbedScript] = useState(`<script type="text/javascript">(function(){d=document;s=d.createElement("script");s.src="https://chatsmith-backend.onrender.com/api/createEmbedJavaScript?email=${localStorage.getItem('email')}&name=${id}";s.async=1;d.getElementsByTagName("head")[0].appendChild(s);})();</script>`);

  const getEmbedScriptToken = (id: string) => {
    return new Promise((resolve, reject) => {
      const path = process.env.REACT_APP_BASE_URL + "/api/createEmbedScriptToken";
      const user_email = localStorage.getItem('email')
      const request = {
        email: user_email,
        bot_name: id,
      }
      axios.post(path, request, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }).then((res) => {
        const _embedurl = `<iframe src="https://app.chatsmith.ai/#/embed?pb=${res.data.token}" width="600" height="450" style="border:0; background:transparent" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>`
        setEmbedUrl(_embedurl);
        resolve(true);
      }).catch(err => {
        console.log(err.data);
        reject();
      });
    });
  };

  const getSubscription = () => {
    return new Promise((resolve, reject) => {
      const path = process.env.REACT_APP_BASE_URL + "/api/getSubscription";
      const user_email = localStorage.getItem('email')
      const request = {
        email: user_email
      };
      axios.post(path, request, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }).then((res) => {
        setCurrentPlan(res.data.type);
        resolve(true);
      }).catch((err) => {
        console.log(err)
        setCurrentPlan('free');
        reject();
      });
    });
  };

  const getChatInfos = (id: string) => {
    return new Promise((resolve, reject) => {
      const path = process.env.REACT_APP_BASE_URL + "/api/getChatInfos";
      const user_email = localStorage.getItem('email')
      const request = {
        email: user_email
      };

      axios.post(path, request, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }).then((res) => {
        let data = []
        let _chatInfos = res.data.chats;
        _chatInfos.sort((a: typeChatInfo, b: typeChatInfo) => a.bot_id - b.bot_id);
        const chatData = _chatInfos.filter((chat: any) => chat.bot_name === id)[0];
        setUserInfo(chatData)
        if (chatData.label) {
          setLabel(chatData.label);
        }

        if (chatData.welcome_message) {
          setWelcome(chatData.welcome_message);
        }

        if (chatData.pdf_file.length > 4) setPrimaryColor(chatData.pdf_file);
        if (chatData.chats.length > 1)
          for (var i = 1; i < chatData.chats.length; i++) {
            data.push({
              id: `${i}-user`,
              content: chatData.chats[i].question,
              sender: 'user'
            })
            data.push({
              id: `${i}-bot`,
              content: chatData.chats[i].answer,
              sender: 'bot'
            })
          }

        setMessages(data);
        resolve(true);
      }).catch((err) => {
        console.log(err)
        navigate('/');
        reject();
      })
    });
  }

  const addMessage = (content: string) => {
    setPendingState(true)
    setLoading(true);
    let responseMessage = "";

    // Simulate a response
    const path = process.env.REACT_APP_BASE_URL + "/api/chat";
    const user_email = localStorage.getItem('email')
    const request = {
      email: user_email,
      bot_id: userInfo.bot_id,
      message_text: content
    };

    setNewMessage('');
    const id = uuidv4();
    setCurrentMessageId(id);
    setMessages(prevMessages => [
      ...prevMessages,
      { id: uuidv4(), content, sender: 'user' },
      // { id, content: '', sender: "bot" },
    ]);
    socket.connect();
    axios.post(path, request, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    }).then((res) => {
      responseMessage = res.data.message;
      setMessages(prevMessages => {
        const mesData = [...prevMessages];
        const lastData = mesData[mesData.length - 1];
        mesData[mesData.length - 1] = {
          ...lastData,
          content: responseMessage,
        };
        return mesData;
      });
    }).catch((err) => {
      if (err.response.data.type === 'low_connect') {
        setShowWarning(true);
        setInputDisable(true);
        setMessages(prevMessages => {
          const mesData = [...prevMessages];
          mesData.push({
            id: uuidv4(),
            content: "You're out of message credits for this month! Need More? <a href='https://app.chatsmith.ai/#/pricing' target='_blank' style='color: #0000FF'>Upgrade Now!</a>",
            sender: 'bot',
          });
          return mesData;
        });
      }
    }).finally(() => {
      setLoading(false);
      setPendingState(false);
      socket.disconnect();
    });
  };

  const deleteChats = () => {
    setMessages([]);
    const path = process.env.REACT_APP_BASE_URL + "/api/chatsDelete";
    const user_email = localStorage.getItem('email')
    const request = {
      email: user_email,
      bot_id: userInfo.bot_id,
    }

    setActionLoading(true);
    axios.post(path, request, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    }).finally(() => setActionLoading(false));
  }

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <div className="flex justify-center py-3 sm:py-6 w-full">
      <div className="w-full mx-auto max-w-7xl">
        <Loader loading={actionLoading}>
          {location.pathname.includes('chatbot') && <div className='flex justify-between mb-3 px-2 sm:px-4 items-center'>
            {/* <h1 className="text-[20px] font-bold leading-tight tracking-tight text-white text-left">
              {userInfo.instance_name}
            </h1> */}

            <button className='flex text-[black] font-bold text-xs font-[Inter] items-center justify-center' onClick={handleBack}>
              <svg width="7" height="11" viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg" className='me-3'>
                <path d="M5.84375 10.1875L1.15625 5.5L5.84375 0.8125" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
              Back
            </button>

            <div className='flex gap-[24px]'>
              <Link to={`/edit-bot/${id}`} className='border px-[12px] py-[10px] rounded-[4px] flex gap-[3px] bg-slate-50 hover:bg-slate-200 font-bold text-decoration-none text-black'>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-[18px] h-[18px]">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                </svg>
                <span className="text-[12px]">Edit</span>
              </Link>
              <button className='px-[12px] py-[10px] flex gap-[3px] rounded-[4px] bg-[#FFE294] border-[1px] border-[#FED35E] hover:bg-[#fed35e] font-bold' onClick={() => { setEmbedOpenModal(true) }}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-[18px] h-[18px]">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M17.25 6.75L22.5 12l-5.25 5.25m-10.5 0L1.5 12l5.25-5.25m7.5-3l-4.5 16.5" />
                </svg>
                <span className="text-[12px]">Embed</span>
              </button>
            </div>
          </div>}
          <div className="chat-container border-none">
            <div className="chat-header-and-messages">
              <div className={`chat-header px-2`} style={{ backgroundColor: primaryColor }}>
                <div className="chat-header-inner">
                  <img
                    src={userInfo.avatar ? `data:image/png;base64,${userInfo.avatar}` : "/assets/image/robot.png"}
                    alt="avatar"
                    className="w-[33px] h-[33px] rounded ml-[20px] object-cover"
                  />
                  <div className="ai-name">
                    <h1 className="text-[18px] text-white mb-0 font-bold">
                      {userInfo.instance_name}
                    </h1>
                    <div className="text-[Montserrat] font-semibold text-white/[.5] text-xs ai-status">
                      online
                      <span className="ai-status-circle"></span>
                    </div>
                  </div>
                </div>
                <button onClick={deleteChats} className="absolute right-4 flex text-[#6d6e7e] hover:text-white transition cursor-pointer">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="white" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                  </svg>
                </button>
              </div>
              <div className="chat-inner bg-white">
                <div className="chat-messages w-full" style={{ overflowAnchor: 'none' }} ref={messagesEndRef}>
                  {messages.map((message, index) =>
                    <ChatMessage
                      key={index}
                      message={message}
                      avatar={userInfo.avatar ? `data:image/png;base64,${userInfo.avatar}` : "/assets/image/robot.png"} botName={userInfo.instance_name}
                      generating={generating && index === messages.length - 1}
                    />
                  )}
                  {loading && (
                    <div className="chat-message bot">
                      <div className="avatar">
                        <img
                          src={userInfo.avatar ? `data:image/png;base64,${userInfo.avatar}` : "/assets/image/robot.png"}
                          alt="avatar"
                        />
                      </div>
                      <div className="my-[5px]">
                        <LoadingIcon />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <ChatInput onSubmit={addMessage} pendingState={pendingState} primaryColor={primaryColor} disabled={inputDisable} currentPlan={currentPlan} label={label} />
          </div>
        </Loader>
      </div>
      <Modal aria-labelledby="modal-title"
        aria-describedby="modal-desc"
        open={embedOpenModal}
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <ModalDialog>
          <div className="relative">
            <div className='flex justify-end'>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 cursor-pointer bg-gray-100 hover:bg-gray-300 rounded-full" onClick={() => { setEmbedOpenModal(false) }}>
                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </div>
            <div className="full-interface">
              <div className='flex justify-between my-[10px] items-end'>
                <h1 className="text-[14px] font-semibold text-[#251a15] mb-0">Embed Full-Width Chat Interface</h1>
                <div className="flex justify-center items-center hover:opacity-75 cursor-pointer" onClick={() => {
                  navigator.clipboard.writeText(embedUrl)
                    .then(() => {
                      toast.success("Copied successfully")
                    })
                    .catch((error) => {
                      console.error("Failed to copy value:", error);
                    });
                }}>
                  <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.0968 2.754C11.0023 2.41988 10.8013 2.12574 10.5244 1.91631C10.2475 1.70688 9.90971 1.59362 9.5625 1.59375H7.4375C6.70792 1.59375 6.09167 2.08463 5.90325 2.754M11.0968 2.754C11.1357 2.89142 11.1562 3.03733 11.1562 3.1875C11.1562 3.3284 11.1003 3.46352 11.0006 3.56315C10.901 3.66278 10.7659 3.71875 10.625 3.71875H6.375C6.2341 3.71875 6.09898 3.66278 5.99935 3.56315C5.89972 3.46352 5.84375 3.3284 5.84375 3.1875C5.84375 3.03733 5.865 2.89142 5.90325 2.754M11.0968 2.754C11.5543 2.78871 12.0091 2.83192 12.4617 2.88433C13.2409 2.975 13.8125 3.64721 13.8125 4.43204V13.8125C13.8125 14.2352 13.6446 14.6406 13.3457 14.9395C13.0468 15.2383 12.6414 15.4062 12.2187 15.4062H4.78125C4.35856 15.4062 3.95318 15.2383 3.6543 14.9395C3.35541 14.6406 3.1875 14.2352 3.1875 13.8125V4.43204C3.1875 3.64721 3.75842 2.975 4.53829 2.88433C4.99236 2.83176 5.44744 2.7883 5.90325 2.754" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M6.375 7.79167H10.625" stroke="black" strokeWidth="1.5" strokeLinecap="round" />
                    <path d="M6.375 11.3333H10.625" stroke="black" strokeWidth="1.5" strokeLinecap="round" />
                  </svg>

                  <h1 className="text-[12px] font-bold my-auto">Copy</h1>
                </div>
              </div>
              <div className={'xl:w-[700px] lg:w-[500px] md:w-[400px] w-[300px] relative ' + (embedUrl === "" && 'border-[2px] rounded-[8px] px-[2px]')}>
                {embedUrl ? <textarea
                  className="flex text-sm leading-6 text-gray-900 sm:col-span-2 sm:mt-0 text-left border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm w-full"
                  value={embedUrl}
                  rows={7}
                  onChange={() => { }}
                  disabled
                /> :
                  <textarea
                    className="flex text-sm leading-6 text-gray-900 sm:col-span-2 sm:mt-0 text-left border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm w-full blur-sm"
                    value={embedUrl}
                    rows={7}
                    placeholder={'<iframe src="https://app.chatsmith.ai/#/embed?pb=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6InlldmhlbjA2MDhAZ21haWwuY29tIiwiYm90X2lkIjowLCJjdXN0b21lcl9pZCI6ImN1c19PSW1TeGw0SnVFSkgySiIsInN1YnNjcmlwdGlvbl9Q.W6jTHVvjuOX8Tu1vGiNfepHB-EKBNNyICr12LMHTc7I" style="border:0;" allowfullscreen="" loading="lazy" allowtransparency="true" referrerpolicy="no-referrer-when-downgrade"></iframe>'}
                    onChange={() => { }}
                    disabled
                  />
                }
              </div>
              <p className="text-black/[.46] text-xs font-[Inter] font-normal mt-2">Add a full width chat widget directly onto any page of your website, such as your FAQ or contact page</p>
            </div>
            <div className="bubble-interface">
              <div className='flex justify-between my-[10px] items-end'>
                <div>
                  <h1 className="text-[14px] font-semibold text-[#251a15]">Embed Chat Bubble</h1>
                  <select className="px-3 py-2 font-[Inter] text-sm border-gray-300 text-[#251a15] font-normal w-[200px] lg:min-w-[300px] rounded-[5px] focus:ring-0 focus:border-gray-300" value={bubblePlace} onChange={e => setBubblePlace(e.target.value)}>
                    <option value="right">Bottom Right</option>
                    <option value="left">Bottom Left</option>
                  </select>
                </div>
                <div className="flex justify-center items-center hover:opacity-75 cursor-pointer" onClick={() => {
                  navigator.clipboard.writeText(embedScript)
                    .then(() => {
                      toast.success("Copied successfully")
                    })
                    .catch((error) => {
                      console.error("Failed to copy value:", error);
                    });
                }}>
                  <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.0968 2.754C11.0023 2.41988 10.8013 2.12574 10.5244 1.91631C10.2475 1.70688 9.90971 1.59362 9.5625 1.59375H7.4375C6.70792 1.59375 6.09167 2.08463 5.90325 2.754M11.0968 2.754C11.1357 2.89142 11.1562 3.03733 11.1562 3.1875C11.1562 3.3284 11.1003 3.46352 11.0006 3.56315C10.901 3.66278 10.7659 3.71875 10.625 3.71875H6.375C6.2341 3.71875 6.09898 3.66278 5.99935 3.56315C5.89972 3.46352 5.84375 3.3284 5.84375 3.1875C5.84375 3.03733 5.865 2.89142 5.90325 2.754M11.0968 2.754C11.5543 2.78871 12.0091 2.83192 12.4617 2.88433C13.2409 2.975 13.8125 3.64721 13.8125 4.43204V13.8125C13.8125 14.2352 13.6446 14.6406 13.3457 14.9395C13.0468 15.2383 12.6414 15.4062 12.2187 15.4062H4.78125C4.35856 15.4062 3.95318 15.2383 3.6543 14.9395C3.35541 14.6406 3.1875 14.2352 3.1875 13.8125V4.43204C3.1875 3.64721 3.75842 2.975 4.53829 2.88433C4.99236 2.83176 5.44744 2.7883 5.90325 2.754" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M6.375 7.79167H10.625" stroke="black" strokeWidth="1.5" strokeLinecap="round" />
                    <path d="M6.375 11.3333H10.625" stroke="black" strokeWidth="1.5" strokeLinecap="round" />
                  </svg>

                  <h1 className="text-[12px] font-bold my-auto">Copy</h1>
                </div>
              </div>
              <div className={'xl:w-[700px] lg:w-[500px] md:w-[400px] w-[300px] relative ' + (embedScript === "" && 'border-[2px] rounded-[8px] px-[2px]')} >
                {embedScript ? <textarea
                  className="flex text-sm leading-6 text-gray-900 sm:col-span-2 sm:mt-0 text-left border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm w-full"
                  value={embedScript}
                  rows={7}
                  onChange={() => { }}
                  disabled
                /> :
                  <textarea
                    className="flex text-sm leading-6 text-gray-900 sm:col-span-2 sm:mt-0 text-left border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm w-full blur-sm"
                    value={embedScript}
                    rows={7}
                    placeholder={'<iframe src="https://app.chatsmith.ai/#/embed?pb=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6InlldmhlbjA2MDhAZ21haWwuY29tIiwiYm90X2lkIjowLCJjdXN0b21lcl9pZCI6ImN1c19PSW1TeGw0SnVFSkgySiIsInN1YnNjcmlwdGlvbl9Q.W6jTHVvjuOX8Tu1vGiNfepHB-EKBNNyICr12LMHTc7I" style="border:0;" allowfullscreen="" loading="lazy" allowtransparency="true" referrerpolicy="no-referrer-when-downgrade"></iframe>'}
                    onChange={() => { }}
                    disabled
                  />
                }
              </div>
              <p className="xl:w-[700px] lg:w-[500px] md:w-[400px] w-[300px] text-black/[.46] text-xs font-[Inter] font-normal mt-2">Use this embed script to add a persistent chat bubble to the bottom right of every page on your site. It ensures that users always have immediate access to messaging, no matter where they are on your site.</p>
            </div>
            {
              embedUrl.length <= 0 &&
              <div className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 flex items-center justify-center w-full h-full flex-col bg-white/[.8]'>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 mx-auto">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z" />
                </svg>
                <h1 className='text-[12px] text-center text-gray-500 mt-2' >Premium Feature <Link to="/pricing" className='text-[#18A2AB] text-decoration-none cursor-pointer'>Upgrade now</Link></h1>
              </div>
            }
          </div>
        </ModalDialog>
      </Modal>
      {
        showWarning &&
        <div className="fixed bottom-[100px] left-[0] z-30 w-full flex items-center justify-center">
          <div className="border-l-4 border-yellow-400 bg-yellow-50 p-4 w-auto">
            <div className="flex">
              <div className="flex-shrink-0">
                <ExclamationTriangleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
              </div>
              <div className="ml-3">
                <p className="text-sm text-yellow-700 mb-[0]">
                  You are out of messages! Need more?{' '}
                  <Link to="/plan-upgrade" className="font-medium text-yellow-700 underline hover:text-yellow-600">
                    Upgrade plan
                  </Link>
                </p>
              </div>
              <div className="ms-[50px] cursor-pointer" onClick={() => setShowWarning(false)}>
                <svg width="20" height="20" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M3.5 10.5L10.5 3.5M3.5 3.5L10.5 10.5" stroke="#FED35E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  );
}
