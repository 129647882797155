import { FC, useEffect } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import PublicRoute from './PublicRoute';
import Signin from '../pages/Signin'
import Verify from '../pages/Verify';
import EmbedBot from '../pages/Embed';

const PublicRoutes: FC = () => {

  return (
    <Routes>
      <Route path="/sign-in" element={
        <PublicRoute>
          <Signin />
        </PublicRoute>} />
      <Route path="/verify/:token" element={
        <PublicRoute>
          <Verify />
        </PublicRoute>} />
      <Route path="/embed" element={<EmbedBot />} />
    </Routes>
  );
};

export default PublicRoutes;
